import Vehicle from '../models/vehicle';

const getEmptyVehicle = () => {
    return new Vehicle(
        '', // id
        '', // brand
        '', // brandCode
        '', // range
        '', // rangeCode
        '', // model
        '', // modelCode
        '', // year
        '', // introductionDate
        '', // discontinuedDate
        '', // galpic
        '', // image url
        '', // diskDriveRefernce
        '', // classType
        '', // description
        '', // capacity
        '', // transmissionType
        '', // fuelType
        '', // driveType
        '', // vehicleSummary
    );
};

export const vehicleHelper = { getEmptyVehicle };
