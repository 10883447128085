import { register } from 'register-service-worker';

//if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
        console.log(
            'App is being served from cache by a service worker.\n'
            + 'For more details, visit https://goo.gl/AFskqB',
        );
    },
    registered() {
        caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
                caches.delete(cacheName);
            });
        });
        console.log('Service worker has been registered.');
    },
    cached() {
        console.log('Content has been cached for offline use.');
    },
    updatefound() {
        caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
                caches.delete(cacheName);
            });
        });
        console.log('New content is downloading.');
    },
    updated(registration) {
        console.log('New content is available; please refresh.');

        document.dispatchEvent(
            new CustomEvent('serviceWorkerUpdated', { detail: registration }),
        );
    },
    offline() {
        console.log(
            'No internet connection found. App is running in offline mode.',
        );
    },
    error(error) {
        console.error('Error during service worker registration:', error);
    },
    fetch() {
        console.log('Fetch handler.');
    },
});
//}
