import { addressHelper } from '../helpers';
import { _ } from 'core-js';

/* global google */

const getAutocompleteSessionToken = () => {
    return new google.maps.places.AutocompleteSessionToken();
};

const getSuggestions = async (searchText, autocompleteSessionToken) => {
    let result;

    try {
        const rawResult = await searchLocation(
            searchText,
            autocompleteSessionToken,
        );
        result = rawResult.map(res => {
            return {
                id: res.place_id,
                value: res.description,
            };
        });
    }
    catch {
        result = null;
    }
    return result;
};

const getPlace = async placeId => {
    let result;

    try {
        const rawResult = await getPlaceDetails(placeId);

        const rs = _.head(rawResult);
        let address = addressHelper.getEmptyAddress();
        let components_by_type = {};

        for (let i = 0; i < rs.address_components.length; i++) {
            let c = rs.address_components[i];
            components_by_type[c.types[0]] = c;
        }

        address.streetNumber = components_by_type['street_number']
            && components_by_type['street_number'].long_name;
        address.streetName = components_by_type['route'] && components_by_type['route'].long_name;
        address.suburb = (components_by_type['political']
            && components_by_type['political'].long_name)
        || (components_by_type['sublocality_level_2']
            && components_by_type['sublocality_level_2'].long_name)
        || (components_by_type['sublocality_level_1']
            && components_by_type['sublocality_level_1'].long_name);
        address.town = (components_by_type['locality']
            && components_by_type['locality'].long_name)
        || (components_by_type['administrative_area_level_2']
            && components_by_type['administrative_area_level_2'].long_name);
        address.city = (components_by_type['locality']
            && components_by_type['locality'].long_name)
        || (components_by_type['administrative_area_level_2']
            && components_by_type['administrative_area_level_2'].long_name);
        address.province = components_by_type['administrative_area_level_1']
            && components_by_type['administrative_area_level_1'].long_name;
        address.country = components_by_type['country'] && components_by_type['country'].long_name;
        address.postalCode = components_by_type['postal_code']
            && components_by_type['postal_code'].long_name;

        address.lat = typeof rs.geometry.location.lat === 'function' ? rs.geometry.location.lat() : rs.geometry.location.lat;
        address.lng = typeof rs.geometry.location.lng === 'function' ? rs.geometry.location.lng() : rs.geometry.location.lng;

        result = {
            address: address,
            geometry: rs.geometry,
        };
    }
    catch {
        result = null;
    }
    return result;
};

// Auxiliary functions
// wrap google api's callback to an async function
const searchLocation = async (val, token) => {
    let promise = await new Promise((resolve, reject) => {
        let displaySuggestionsCallback = (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                reject(status);
            }
            resolve(predictions);
        };

        let service = new google.maps.places.AutocompleteService();
        //var componentRestriction = new google.maps.places.ComponentRestrictions();

        service.getPlacePredictions(
            {
                input: val,
                sessionToken: token,
                componentRestrictions: { country: 'za' },
                types: ['geocode'],
            },
            displaySuggestionsCallback,
        );
    }).catch(function (err) {
        throw err;
    });

    return promise;
};

// wrap google api's callback to an async function
const getPlaceDetails = async val => {
    let promise = await new Promise((resolve, reject) => {
        let placeDetailsCallback = (placeResult, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
                reject(status);
            }
            resolve(placeResult);
        };

        let service = new google.maps.Geocoder();
        service.geocode(
            {
                placeId: val,
            },
            placeDetailsCallback,
        );
    }).catch(function (err) {
        throw err;
    });

    return promise;
};

const geocodeAdddress = async address => {
    let promise = await new Promise((resolve, reject) => {
        let addressDetailsCallback = (addressResult, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
                reject(status);
            }
            resolve(addressResult);
        };

        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(
            {
                address,
            },
            addressDetailsCallback,
        );
    }).catch(function (err) {
        throw err;
    });

    return promise;
};

const calculateDistance = (address1, address2) => {
    const lat1 = address1.lat;
    const lon1 = address1.lng;
    const lat2 = address2.lat;
    const lon2 = address2.lng;

    const earthRadius = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180; // Latitude difference in radians
    const dLon = (lon2 - lon1) * Math.PI / 180; // Longitude difference in radians

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180)
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // Distance in kilometers
    return distance;
};

export const placeService = {
    getAutocompleteSessionToken,
    getSuggestions,
    getPlace,
    geocodeAdddress,
    calculateDistance,
};
