<template>
    <div class="my-auto">
        <v-dropdown :shown.sync="isOpen" :distance="6" container="#smc-app" placement="bottom-end">
            <!-- This will be the popover reference (for the events and position) -->
            <v-btn class="my-auto mx-2 text-none d-none d-md-flex pr-2" color="primary" rounded>
                <span> Inbox </span>
                <v-chip class="ml-2 px-1 cursor-pointer" color="black" outlined small>
                    {{ messagesCount }}
                    <span v-if="messagesCount !== 0">{{ !unreadProps.scrollDisabled ? "+" : "" }}</span>
                </v-chip>
            </v-btn>
            <v-btn class="my-auto text-none d-flex d-md-none events-btn" small fab color="primary" rounded>
                <v-icon small dark> mdi-inbox-full </v-icon>
            </v-btn>

            <!-- This will be the content of the popover -->
            <template #popper>
                <v-card class="messages-inbox-card smc-card-dialog">
                    <v-card-title class="smc-card-dialog-title-inset primary white--text">
                        <span class="mx-auto"> Message Inbox </span>
                    </v-card-title>

                    <v-card-text class="px-0">
                        <v-tabs v-model="tab" grow :vertical="$vuetify.breakpoint.smAndUp ? false : true">
                            <v-tab :key="'Unread'">
                                Unread
                                <v-chip v-if="unreadProps.count > 0" class="ml-2">
                                    {{ unreadProps.count }}{{
                                        !unreadProps.scrollDisabled ? "+" : "" }}
                                </v-chip>
                            </v-tab>
                            <v-tab :key="'Red'">
                                Read
                                <v-chip v-if="readProps.count > 0" class="ml-2">
                                    {{ readProps.count }}{{
                                        !readProps.scrollDisabled ? "+" : "" }}
                                </v-chip>
                            </v-tab>
                            <v-tab :key="'Sent'">
                                Sent
                                <v-chip v-if="sentProps.count > 0" class="ml-2">
                                    {{ sentProps.count }}{{
                                        !sentProps.scrollDisabled ? "+" : "" }}
                                </v-chip>
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item eager class="messages-inbox-tab">
                                <messages-list
                                    ref="unread-messages-list"
                                    :isOpenProp="false"
                                    :isSentProp="false"
                                    :themeColorProp="'red'"
                                    @openAction="openAction"
                                    @messagesLoaded="unreadProps = $event; messagesCount = unreadProps.count;"
                                    @resetMessages="resetMessages"
                                />
                            </v-tab-item>
                            <v-tab-item eager class="messages-inbox-tab">
                                <v-card flat>
                                    <messages-list
                                        ref="read-messages-list"
                                        :isOpenProp="true"
                                        :isSentProp="false"
                                        :themeColorProp="'green'"
                                        @openAction="openAction"
                                        @messagesLoaded="readProps = $event"
                                        @resetMessages="resetMessages"
                                    />
                                </v-card>
                            </v-tab-item>
                            <v-tab-item eager class="messages-inbox-tab">
                                <v-card flat>
                                    <messages-list
                                        ref="sent-messages-list"
                                        :isOpenProp="false"
                                        :isSentProp="true"
                                        :themeColorProp="'orange'"
                                        @openAction="openAction"
                                        @messagesLoaded="sentProps = $event"
                                        @resetMessages="resetMessages"
                                    />
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </template>
        </v-dropdown>
    </div>
</template>

<script>
import messagesList from './messages-list';
import { userService } from '@/services';

export default {
    name: 'messages-inbox',
    components: {
        messagesList,
    },
    data: () => ({
        tab: null,
        isOpen: false,

        messagesCount: 0,

        unreadProps: {
            count: 0,
            scrollDisabled: false,
        },
        readProps: {
            count: 0,
            scrollDisabled: false,
        },
        sentProps: {
            count: 0,
            scrollDisabled: false,
        },
    }),
    computed: {
        unreadCount() {
            let countMessage = '';
            if (this.messagesCount !== 0) {
                countMessage = this.messagesCount;
                if (!this.unreadProps.scrollDisabled) {
                    countMessage += '+';
                }
            }
            return countMessage;
        },
    },
    watch: {
        isOpen(val) {
            if (val) {
                this.resetMessages();
            }
        },
        $route() {
            this.unreadProps = {
                count: 0,
                scrollDisabled: false,
            };
            this.messagesCount = 0;
            this.queryMessagesCount();
        },
    },
    async mounted() {
        await this.queryMessagesCount();
    },
    methods: {
        async queryMessagesCount() {
            let result = await userService.queryUserRecentMessages(
                1,
                false,
                false,
            );
            if (result.status === 200) {
                this.messagesCount = result.data.length;
            }
        },
        openAction(item) {
            this.$router.push(`/quotes/${item.navigationId}`);
            this.isOpen = false;
        },
        resetMessages() {
            let unReadMessagesListComponent = this.$refs['unread-messages-list'];
            if (unReadMessagesListComponent) {
                unReadMessagesListComponent.resetMessages();
                unReadMessagesListComponent.loadMoreMessages();
            }

            let readMessagesListComponent = this.$refs['read-messages-list'];
            if (readMessagesListComponent) {
                readMessagesListComponent.resetMessages();
                readMessagesListComponent.loadMoreMessages();
            }

            let sentMessagesListComponent = this.$refs['sent-messages-list'];
            if (sentMessagesListComponent) {
                sentMessagesListComponent.resetMessages();
                sentMessagesListComponent.loadMoreMessages();
            }
        },
    },
};
</script>

<style lang="scss">
.v-popper--theme-dropdown .v-popper__inner {
    border-radius: 30px !important;
    border-top-right-radius: 15px;
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.events-btn {
    left: 0;
}

.messages-inbox-tab {
    max-width: 600px;
}

.messages-inbox-card {
    max-height: 90vh;
    margin-right: 0;
    overflow-y: scroll;

    @media screen and (max-width: 550px) {
        max-width: 100vw;
        margin-right: 0;
        max-height: 100%;
    }
}

.smc-card-dialog.v-card>.v-card__title.smc-card-dialog-title-inset {
    width: fit-content;
    min-width: 250px;
    padding: 10px 20px;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 32px !important;

    @media screen and (max-width: 600px) {
        width: 100%;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 30px !important;
    }
}

.smc-card-dialog.v-card>.v-card__subtitle.smc-card-dialog-subtitle-inset {
    @media screen and (max-width: 600px) {
        width: 100%;
        position: relative;
        border-bottom-right-radius: 32px;
    }
}
</style>
