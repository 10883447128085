import _ from 'lodash';
import api from './api';
import Address from '@/models/address';
import Workshop from '@/models/workshop';
import WorkshopReview from '@/models/workshop-review';

const getWorkshop = async id => {
    try {
        let workshop = [];

        let result = await api.get(`/api/PublicWorkshop/GetWorkshop/${id}`);
        workshop = mapWorkshop(result.data);

        return workshop;
    }
    catch (error) {
        return error;
    }
};

const getWorkshops = async () => {
    try {
        let workshops = [];

        let result = await api.get('/api/PublicWorkshop/GetWorkshops');
        _.forEach(result.data, function (item) {
            workshops.push(mapWorkshop(item));
        });

        return workshops;
    }
    catch (error) {
        return error;
    }
};

const getWorkshopAddresses = async quoteType => {
    try {
        let addresses = [];

        let result = await api.get(`/api/PublicWorkshop/GetWorkshopAddresses?quoteType=${quoteType}`);
        _.forEach(result.data, function (item) {
            addresses.push({
                workshopId: item.workshopId,
                name: item.name,
                regionIds: item.regionIds,
                suburb: item.suburb,
                lat: item.lat,
                lng: item.lng,
                referenceNumber: item.referenceNumber,
                infoIsOpen: false,
                isOrganization: item.isOrganization,
                workshopLogoUrl: item.workshopLogoUrl,
            });
        });

        return addresses;
    }
    catch (error) {
        return error;
    }
};

const mapWorkshop = item => {
    return new Workshop(
        item.id,
        item.name,
        item.isPosh,
        item.rating,
        '', // taxNumber
        '', // registrationNumber
        new Address(
            item.address.id,
            item.address.name,
            item.address.isActive,
            item.address.streetNumber,
            item.address.streetName,
            item.address.suburb,
            item.address.city,
            item.address.province,
            item.address.country,
            item.address.postalCode,
            item.address.lat,
            item.address.lng,
            item.address.dateCreated,
            false, // isOrganization
        ),
        item.logoUrl,
        false, // has payfast account
        item.paystackSubaccountId,
        item.dateCreated,
        item.dateCreatedUtc,
        {}, // distance matrix,
        _.map(item.workshopReviews, function (review) {
            return new WorkshopReview(
                review.id,
                review.workshopId,
                review.rating,
                review.review,
            );
        }),
        item.isVatRegistered,
        item.workshopContact,
        item.workshopCertifications,
        item.bankAccount,
    );
};

export const workshopService = { 
    getWorkshop,
    getWorkshops, 
    getWorkshopAddresses };
