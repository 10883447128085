export default class User {
    constructor(
        id,
        firstName,
        lastName,
        email,
        phone,
        phoneWithoutPrefix,
        businessName,
        businessRegistrationNumber,
        businessTaxNumber,
        businessAddress,
        organization,
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.phoneWithoutPrefix = phoneWithoutPrefix;
        this.businessName = businessName;
        this.businessRegistrationNumber = businessRegistrationNumber;
        this.businessTaxNumber = businessTaxNumber;
        this.businessAddress = businessAddress;
        this.organization = organization;
    }
}
