<template>
    <v-stepper-content step="4">
        <button type="button" ref="blankBtn" style="display: none"></button>
        <validation-observer ref="step4formValidationObserver">
            <v-form v-model="step4Valid" lazy-validation ref="step4form">
                <v-card flat>
                    <v-row v-if="isAuthenticated">
                        <v-col class="text-right">
                            <v-btn small rounded color="primary" to="/account">
                                Change user details
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div class="pt-1 mt-1"></div>
                    <validation-provider
                        v-slot="{ errors }"
                        name="First name"
                        :rules="!this.isAuthenticated ? 'required' : ''"
                    >
                        <v-text-field
                            id="createQuote-firstName"
                            v-model="quoteRequest.applicationUser.firstName"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-account-edit"
                            label="First name"
                            outlined
                            rounded
                            :readonly="
                                isAuthenticated
                                    && storeUser
                                    && storeUser.firstName
                                    && storeUser.firstName.length > 0
                            "
                        />
                    </validation-provider>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Surname"
                        :rules="!this.isAuthenticated ? 'required' : ''"
                    >
                        <v-text-field
                            id="createQuote-lastName"
                            v-model="quoteRequest.applicationUser.lastName"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-account-edit"
                            label="Surname"
                            outlined
                            rounded
                            :readonly="
                                isAuthenticated
                                    && storeUser
                                    && storeUser.lastName
                                    && storeUser.lastName.length > 0
                            "
                        />
                    </validation-provider>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Email address"
                        rules="required|email"
                    >
                        <v-text-field
                            id="createQuote-email"
                            v-model="quoteRequest.applicationUser.email"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-email"
                            label="Email address"
                            outlined
                            rounded
                            :readonly="isAuthenticated"
                        />
                    </validation-provider>
                    <validation-provider
                        v-if="!isAuthenticated"
                        v-slot="{ errors }"
                        name="Confirm Email address"
                        :rules="`required|email|force_value:${quoteRequest.applicationUser.email}`"
                    >
                        <v-text-field
                            id="createQuote-confirm-email"
                            v-model="quoteRequest.applicationUser.confirmEmail"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-email"
                            label="Confirm email address"
                            outlined
                            rounded
                        />
                    </validation-provider>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Phone number"
                        rules="required|phone"
                    >
                        <v-text-field
                            id="createQuote-phone"
                            v-model="quoteRequest.applicationUser.phoneWithoutPrefix"
                            :error-messages="errors"
                            label="Phone number"
                            prepend-inner-icon="mdi-cellphone"
                            outlined
                            rounded
                            v-mask="'### ### ####'"
                            :readonly="
                                isAuthenticated
                                    && storeUser
                                    && storeUser.phone
                                    && storeUser.phone.length > 0
                            "
                            @keyup.enter="$event.target.blur()"
                        />
                    </validation-provider>
                    <div v-if="isOrganization">
                        <v-card
                            class="smc-card-dialog mb-5"
                            :class="$vuetify.breakpoint.xsOnly ? 'mx-1' : 'mx-3'"
                            elevation="5"
                        >
                            <v-card-title
                                class="smc-card-dialog-title-inset primary white--text pa-1"
                            >
                                <span class="mx-auto">Organization details</span>
                            </v-card-title>
                            <v-card-text class="pt-6">
                                <v-text-field
                                    :value="organization.name"
                                    prepend-inner-icon="mdi-domain"
                                    label="Name"
                                    outlined
                                    readonly
                                    rounded
                                />
                                <v-text-field
                                    v-if="organization.registrationNumber"
                                    :value="organization.registrationNumber"
                                    prepend-inner-icon="mdi-domain"
                                    label="Registration Number"
                                    outlined
                                    readonly
                                    rounded
                                />
                                <v-text-field
                                    v-if="organization.taxNumber"
                                    :value="organization.taxNumber"
                                    prepend-inner-icon="mdi-domain"
                                    label="Tax Number"
                                    outlined
                                    readonly
                                    rounded
                                />

                                <v-card
                                    v-for="branch in organization.organizationBranches"
                                    :key="branch.id"
                                    class="smc-card-dialog mb-5"
                                    :class="$vuetify.breakpoint.xsOnly ? 'mx-1' : 'mx-3'"
                                    elevation="5"
                                >
                                    <v-card-title
                                        class="smc-card-dialog-title-inset primary white--text pa-1"
                                    >
                                        <span class="mx-auto">Branch details</span>
                                    </v-card-title>
                                    <v-card-text class="pt-6">
                                        <v-text-field
                                            :value="branch.branchName"
                                            prepend-inner-icon="mdi-domain"
                                            label="Branch Name"
                                            outlined
                                            readonly
                                            rounded
                                        />

                                        <v-textarea
                                            v-if="branch.address"
                                            label="Branch Address"
                                            prepend-inner-icon="mdi-google-maps"
                                            readonly
                                            rows="7"
                                            no-resize
                                            outlined
                                            rounded
                                            hide-details
                                            :value="addressHelper.paragraphyAddress(branch.address)"
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-else>
                        <v-row class="px-4">
                            <v-col cols="12" md="5" class="py-0 pl-6">
                                <v-switch
                                    class="mt-0"
                                    label="Capture business details"
                                    v-model="isBusiness"
                                />
                            </v-col>
                            <v-col cols="12" md="7" class="py-0">
                                <p>
                                    Capturing business details is optional. These details will
                                    appear on all applicable quotes and invoices.
                                </p>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <v-card
                                v-if="isBusiness"
                                class="smc-card-dialog mb-5"
                                :class="$vuetify.breakpoint.xsOnly ? 'mx-1' : 'mx-3'"
                                elevation="4"
                            >
                                <v-card-title
                                    class="smc-card-dialog-title-inset primary white--text pa-1"
                                >
                                    <span class="mx-auto">Business details</span>
                                </v-card-title>
                                <v-card-text class="pt-6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Business name"
                                        rules="required"
                                    >
                                        <v-text-field
                                            id="createQuote-businessName"
                                            v-model="quoteRequest.applicationUser.businessName"
                                            :error-messages="errors"
                                            prepend-inner-icon="mdi-domain"
                                            label="Business name"
                                            outlined
                                            rounded
                                            :readonly="userHasBusiness"
                                        />
                                    </validation-provider>
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Business registration number"
                                    >
                                        <v-text-field
                                            id="createQuote-businessRegistrationNumber"
                                            v-model="quoteRequest.applicationUser.businessRegistrationNumber"
                                            :error-messages="errors"
                                            prepend-inner-icon="mdi-domain"
                                            label="Business registration number"
                                            outlined
                                            rounded
                                            :readonly="userHasBusiness"
                                        />
                                    </validation-provider>
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Business VAT number"
                                    >
                                        <v-text-field
                                            id="createQuote-businessTaxNumber"
                                            v-model="quoteRequest.applicationUser.businessTaxNumber"
                                            :error-messages="errors"
                                            prepend-inner-icon="mdi-domain"
                                            label="Business VAT number"
                                            outlined
                                            rounded
                                            :readonly="userHasBusiness"
                                        />
                                    </validation-provider>

                                    <v-textarea
                                        id="createQuote-businessAddress"
                                        :value="
                                            addressHelper.paragraphyAddress(
                                                quoteRequest.applicationUser.businessAddress
                                            )
                                        "
                                        label="Business address"
                                        v-if="userHasBusinessAddress"
                                        rows="6"
                                        no-resize
                                        outlined
                                        rounded
                                        readonly
                                        hide-details
                                    />
                                    <address-capture
                                        v-else
                                        :addressProp="quoteRequest.applicationUser.businessAddress"
                                        :allowManualCaptureProp="true"
                                        :completeAddressRequiredProp="true"
                                        @addressChange="updateBusinessAddress($event)"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-expand-transition>
                    </div>

                    <v-card outlined class="my-4">
                        <v-switch v-model="quoteRequest.serviceReminder" v-if="!isOrganization" class="ml-4">
                            <template #label>
                                <div>
                                    I would like to be notified of
                                    <v-tooltip top>
                                        <template #activator="{ on }">
                                            <span v-on="on" style="text-decoration: underline dashed">
                                                my next service.
                                            </span>
                                        </template>
                                        We will send you a reminder in 6 months<br />for
                                        consideration of your next service
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-switch>

                        <v-switch v-model="terms" class="ml-4">
                            <template #label>
                                <div>
                                    I agree to the
                                    <v-tooltip top>
                                        <template #activator="{ on }">
                                            <a target="_blank" href="/terms" @click.stop v-on="on">
                                                terms and conditions.
                                            </a>
                                        </template>
                                        Opens in a new window
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-switch>
                    </v-card>

                    <v-list
                        v-if="
                            !duplicateValidationProp
                                && validationMessagesProp
                                && validationMessagesProp.length > 0
                        "
                        outlined
                        class="mb-4"
                    >
                        <v-list-item
                            v-for="(item, index) in validationMessagesProp"
                            :key="index"
                        >
                            <v-list-item-content class="font-weight-bold red--text">
                                {{ item }}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-btn
                        id="createQuote-step4Back"
                        rounded
                        color="secondary"
                        class="smc-text-button smc-medium-button mx-1 my-1"
                        @click="goStep(3)"
                    >
                        Back
                    </v-btn>
                    <v-btn
                        id="createQuote-step4Submit"
                        rounded
                        color="primary"
                        class="smc-text-button smc-medium-button mx-1 my-1"
                        @click="checkRecapcha()"
                        :disabled="!step4Valid || !terms"
                        :loading="submittingProp"
                    >
                        Submit
                    </v-btn>
                </v-card>
            </v-form>
        </validation-observer>
    </v-stepper-content>
</template>

<script>
import addressCapture from '@/components/address-capture.vue';
import quoteRequestCreate from '@/mixins/quote-request-create';
import validate from '@/mixins/validator';

export default {
    name: 'create-step-4',
    components: {
        addressCapture,
    },
    mixins: [quoteRequestCreate, validate],
    props: {
        submittingProp: Boolean,
        duplicateValidationProp: Boolean,
        validationMessagesProp: Array,
    },
    data: () => ({
        terms: false,
    }),
    computed: {
        userHasBusiness() {
            if (!this.isAuthenticated) {
                return false;
            }
            return this.$store.getters['user/hasBusinessDetails'];
        },
        userHasBusinessAddress() {
            if (!this.isAuthenticated) {
                return false;
            }
            return this.$store.getters['user/hasBusinessAddress'];
        },
    },
};
</script>
