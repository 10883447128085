<template lang="html">
    <div class="loader" v-show="!loaderDisableProp">
        <slot>
            <svg viewBox="25 25 50 50" class="loader__svg" :style="size">
                <circle
                    cx="50"
                    cy="50"
                    r="20"
                    class="loader__circle"
                    :style="color"
                />
            </svg>
        </slot>
    </div>
</template>
  
<script>
import 'intersection-observer';
export default {
    name: 'scroll-loader',
    props: {
        loaderMethodProp: {
            type: Function,
            required: true,
        },
        loaderDisableProp: {
            type: Boolean,
            default: false,
        },
        loaderDistanceProp: {
            type: Number,
            default: 0,
        },
        loaderColorProp: {
            type: String,
            default: '#CCCCCC',
        },
        loaderSizeProp: {
            type: Number,
            default: 50,
        },
        loaderViewportProp: {
            type: Element,
            default: null,
        },
    },
    computed: {
        size() {
            return {
                width: `${this.loaderSizeProp}px`,
            };
        },
        color() {
            return {
                stroke: this.loaderColorProp,
            };
        },
        options() {
            return {
                root: this.loaderViewportProp,
                rootMargin: `0px 0px ${this.loaderDistanceProp}px 0px`,
                threshold: [0.25, 0.5, 0.75, 1],
            };
        },
        observer() {
            return new IntersectionObserver(([entry]) => {
                entry.isIntersecting
                && !this.loaderDisableProp
                && this.loaderMethodProp();
            }, this.options);
        },
    },
    mounted() {
        this.observer.observe(this.$el);
    },
    activated() {
        this.observer.observe(this.$el);
    },
    deactivated() {
        this.observer.unobserve(this.$el);
    },
    beforeDestroy() {
        this.observer.unobserve(this.$el);
    },
    methods: {
        unObserve() {
            this.observer.unobserve(this.$el);
        },
        reObserve() {
            this.observer.observe(this.$el);
        },
    },
};
</script>
  
  <style lang="scss" scoped>
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    &__svg {
      transform-origin: center;
      animation: rotate 2s linear infinite;
    }
    &__circle {
      fill: none;
      stroke-width: 3;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -125px;
    }
  }
  </style>
