function validate_vin(vin) {
    function isnumeric(mixed_var) {
        return (
            (typeof mixed_var === 'number' || typeof mixed_var === 'string')
            && mixed_var !== ''
            && !isNaN(mixed_var)
        );
    }

    let pattern = /^[^\Wioq]{17}$/;
    let weights = Array(8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2);
    let transliterations = {
        a: 1,
        b: 2,
        c: 3,
        d: 4,
        e: 5,
        f: 6,
        g: 7,
        h: 8,
        j: 1,
        k: 2,
        l: 3,
        m: 4,
        n: 5,
        p: 7,
        r: 9,
        s: 2,
        t: 3,
        u: 4,
        v: 5,
        w: 6,
        x: 7,
        y: 8,
        z: 9,
    };

    vin = vin.toLowerCase();
    if (!vin.match(pattern)) {
        return false;
    }

    let sum = 0;
    for (let i = 0; i < vin.length; i++) {
        if (!isnumeric(vin.charAt(i))) {
            sum += transliterations[vin.charAt(i)] * weights[i];
        }
        else {
            sum += parseInt(vin.charAt(i)) * weights[i];
        }
    }

    let checkdigit = sum % 11;

    if (checkdigit === 10) {
        checkdigit = 'x';
    }

    return checkdigit === vin.charAt(8);
}

const validation_rules = {
    anyRequired: (value, other, message) => {
        if (value || other) {
            return true;
        }
        if (message) {
            return message;
        }
        else return 'Required.';
    },
    arrayRequired: value => {
        if (value.length === 0) {
            return 'Please select a value.';
        }
        return true;
    },
    email: value => {
        if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Email must be a valid email address.';
        }
        return true;
    },
    phone: value => {
        if (value) {
            const pattern1 = /0\d{2} \d{3} \d{4}/;
            const pattern2 = /0\d{9}/;
            return pattern1.test(value) || pattern2.test(value) || 'Please enter a valid phone number (012 123 1234).';
        }
        return true;
    },
    password: value => {
        if (value) {
            let pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])');
            return pattern.test(value) || 'The password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and one special character.';
        }
        return true;
    },
    forceValue: (value, compare) => {
        if (value) {
            return value === compare;
        }
        return 'Invalid';
    },
    vin: value => {
        if (value) {
            return validate_vin(value) || 'The VIN must be a valid number.';
        }
        return true;
    },
};

export const validation = {
    validate_vin,
    validation_rules,
};
