<template>
    <v-card
        id="smc-vehicle-search"
        class="smc-card-transparent smc-card-borderless pt-4"
    >
        <v-card-text class="pb-2">
            <div class="smc-search-back"></div>
            <div v-if="!noSearch">
                <v-expand-transition>
                    <div v-if="!searchInitialized">
                        <p
                            class="text-center title white--text font-weight-medium mx-auto"
                            style="max-width: 565px"
                        >
                            <span
                                class="font-weight-black title"
                                style="font-size: 1.375rem !important"
                            >
                                Get & Compare quotes
                            </span>
                            for your vehicle
                            <span class="primary--text font-weight-black headline">
                                service
                            </span>
                            or
                            <span class="primary--text font-weight-black headline">
                                repair
                            </span>
                            from workshops in your area!
                        </p>

                        <v-btn
                            rounded
                            class="mt-3"
                            color="primary"
                            @click="updateSearchInitialized(true)"
                            :loading="searchLoading"
                        >
                            Click to start
                        </v-btn>
                        <div id="flip-container" @click="flip()">
                            <v-expand-transition>
                                <div id="flip-container-front" v-if="flipped">
                                    <p
                                        class="text-center title white--text font-weight-medium mx-auto mt-6 mb-0"
                                    >
                                        <roller
                                            class="primary--text font-weight-black headline"
                                            :text="rollerCounter"
                                            :isNumberFormat="true"
                                            :transition="2"
                                        />
                                        happy customers since
                                        <br v-if="$vuetify.breakpoint.xsOnly" />
                                        1 September 2020
                                    </p>
                                </div>
                            </v-expand-transition>
                            <v-expand-transition>
                                <div id="flip-container-back" v-if="!flipped">
                                    <p
                                        class="text-center title white--text font-weight-medium mx-auto mt-6 mb-0"
                                    >
                                        Our customers have saved up to
                                        <br v-if="$vuetify.breakpoint.xsOnly" />
                                        <roller
                                            class="primary--text font-weight-black headline"
                                            :text="'36.5'"
                                            :isNumberFormat="true"
                                            :transition="2"
                                        />
                                        <span class="primary--text font-weight-black headline">
                                            %
                                        </span>
                                        on their vehicle services and repairs
                                    </p>
                                </div>
                            </v-expand-transition>
                        </div>
                    </div>
                </v-expand-transition>
                <v-expand-transition>
                    <div v-if="searchInitialized">
                        <v-row>
                            <v-col v-if="showGarage">
                                <p class="text-center title white--text font-weight-medium">
                                    Select a vehicle in your garage
                                </p>
                                <v-combobox
                                    id="searchVehicle-my-garage"
                                    class="smc-textfield-tall"
                                    item-text="name"
                                    item-value="id"
                                    v-model="selectedGarageVehicle"
                                    @change="selectedGarageVehicleChange"
                                    ref="searchVehicleMyGarage"
                                    placeholder="My Garage"
                                    :items="myGarageVehicles"
                                    :menu-props="{
                                        allowOverflow: true,
                                        closeOnClick: false,
                                        closeOnContentClick: false,
                                        disableKeys: true,
                                        openOnClick: false,
                                        maxHeight: 500,
                                    }"
                                    chips
                                    hide-details
                                    return-object
                                    no-filter
                                    :clearable="$vuetify.breakpoint.smAndUp"
                                    outlined
                                    rounded
                                >
                                    <template
                                        #prepend-inner
                                        v-if="$vuetify.breakpoint.smAndUp"
                                    >
                                        <v-icon color="primary">mdi-home</v-icon>
                                    </template>
                                    
                                    <!-- TODO fix lint error -->
                                    <!-- eslint-disable vue/no-useless-template-attributes -->
                                    <template
                                        #item="data"
                                        v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
                                    >
                                        <img
                                            v-if="$vuetify.breakpoint.smAndUp"
                                            class="smc-search-image"
                                            :src="data.item.vehicle.imageUrl"
                                            style="
                        width: 180px;
                        margin-right: 15px;
                        border-radius: 15px;
                      "
                                        />
                                        <v-card class="smc-my-garage-card">
                                            <v-card-title>
                                                {{ data.item.name }}
                                                <v-chip
                                                    v-if="data.item.status"
                                                    color="secondary"
                                                    class="ml-auto"
                                                    small
                                                    label
                                                >
                                                    {{ data.item.status }}
                                                </v-chip>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col>
                                                        <v-list-item-content>
                                                            <v-list-item-subtitle style="text-align: left">
                                                                {{ data.item.vehicle.year }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-title style="text-align: left">
                                                                {{ data.item.vehicle.brand }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle style="text-align: left">
                                                                {{ data.item.vehicle.range }}
                                                                <br />
                                                                {{ data.item.vehicle.model }}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-col>
                                                    <v-col
                                                        v-if="
                                                            data.item.innovationPolicy
                                                                && data.item.innovationPolicy.policyStatus
                                                                    !== innovationPolicyStatuses.expired.value
                                                                && data.item.innovationPolicy.policyStatus
                                                                    !== innovationPolicyStatuses.cancelled.value
                                                        "
                                                        class="text-right"
                                                    >
                                                        <v-list-item-content>
                                                            <v-list-item-subtitle style="text-align: right">
                                                                {{ data.item.innovationPolicy.policyCode }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle style="text-align: right">
                                                                {{ data.item.innovationPolicy.vinNumber }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle style="text-align: right">
                                                                <strong>Start date:</strong>
                                                                {{
                                                                    dateHelper.formatDateShort(
                                                                        data.item.innovationPolicy.startDate
                                                                    )
                                                                }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle style="text-align: right">
                                                                <strong>End date:</strong>
                                                                {{
                                                                    dateHelper.formatDateShort(
                                                                        data.item.innovationPolicy.endDate
                                                                    )
                                                                }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle style="text-align: right">
                                                                <strong>End distance:</strong>
                                                                {{ data.item.innovationPolicy.endDistance }}
                                                                km
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-img
                                                            class="innovation-logo mb-2 float-right"
                                                            width="160"
                                                            contain
                                                            :src="require('@/assets/images/ig-logo.png')"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </v-combobox>
                                <p
                                    v-if="!showSearch"
                                    class="mt-3 mb-0 text-center white--text subtitle-1"
                                >
                                    Add a new vehicle
                                    <v-btn
                                        color="primary"
                                        class="text-none subtitle-1 px-2"
                                        rounded
                                        @click="showSearch = true"
                                    >
                                        Click here
                                    </v-btn>
                                </p>
                            </v-col>
                            <v-expand-transition>
                                <v-col v-if="showSearch">
                                    <p class="text-center title white--text font-weight-medium">
                                        Let's start by searching for your vehicle
                                    </p>
                                    <v-combobox
                                        id="searchVehicle-search"
                                        class="smc-textfield-tall"
                                        item-text="vehicleSummary"
                                        item-value="uniqueId"
                                        v-model="selectedVehicle"
                                        append-icon=""
                                        ref="searchVehicleSearch"
                                        :items="vehicles"
                                        :search-input.sync="searchTerm"
                                        :menu-props="{
                                            allowOverflow: true,
                                            closeOnClick: false,
                                            closeOnContentClick: false,
                                            disableKeys: true,
                                            openOnClick: false,
                                            maxHeight: 500,
                                        }"
                                        chips
                                        hide-details
                                        return-object
                                        no-filter
                                        :clearable="$vuetify.breakpoint.smAndUp"
                                        outlined
                                        rounded
                                        @change="
                                            selectedGarageVehicle = undefined;
                                            isPolicyQuote = false;
                                            selectedVehicleChange();
                                        "
                                    >
                                        <template
                                            #prepend-inner
                                            v-if="$vuetify.breakpoint.smAndUp"
                                        >
                                            <v-icon color="primary">mdi-magnify</v-icon>
                                        </template>

                                        <template #append v-if="$vuetify.breakpoint.smAndUp">
                                            <v-progress-circular
                                                v-if="searchIsLoading"
                                                :size="20"
                                                :width="2"
                                                color="primary"
                                                indeterminate
                                            />
                                        </template>

                                        <template #no-data>
                                            <v-list-item
                                                v-if="
                                                    _.size(vehicles) === 0
                                                        && searchIsLoading === false
                                                        && _.size(searchTerm) >= 2
                                                        && !manualVehicleEntry
                                                "
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        Can't find your vehicle?
                                                        <br />
                                                        <br />
                                                        <v-btn
                                                            small
                                                            rounded
                                                            color="primary"
                                                            @click="startManualVehicleEntry()"
                                                        >
                                                            Manually enter details
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        
                                        <!-- TODO fix lint error -->
                                        <!-- eslint-disable vue/no-useless-template-attributes -->
                                        <template
                                            #item="data"
                                            v-bar="{
                                                preventParentScroll: true,
                                                scrollThrottle: 30,
                                            }"
                                        >
                                            <v-list-item-avatar
                                                size="100"
                                                width="170"
                                                tile
                                                v-if="$vuetify.breakpoint.smAndUp"
                                            >
                                                <img
                                                    class="smc-search-image"
                                                    :src="data.item.imageUrl"
                                                />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-subtitle style="text-align: left">
                                                    {{ data.item.year }}
                                                </v-list-item-subtitle>
                                                <v-list-item-title style="text-align: left">
                                                    {{ data.item.brand }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle style="text-align: left">
                                                    {{ data.item.range }}
                                                    <br />
                                                    {{ data.item.model }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-combobox>
                                    <p class="mt-3 mb-0 text-center white--text subtitle-1">
                                        Can't find your vehicle?
                                        <v-btn
                                            color="primary"
                                            class="text-none subtitle-1 pb-1 px-2"
                                            rounded
                                            text
                                            @click="startManualVehicleEntry()"
                                        >
                                            Click here
                                        </v-btn>
                                    </p>
                                </v-col>
                            </v-expand-transition>
                        </v-row>
                    </div>
                </v-expand-transition>
            </div>
        </v-card-text>

        <v-expand-transition>
            <div
                v-if="
                    selectedVehicleShow
                        || manualVehicleEntry
                        || loadedVehicle
                        || (searchIsLoading && selectedVehicle)
                "
            >
                <v-card id="vehicle-card" ref="vehicleCard">
                    <div v-if="searchIsLoading" class="pa-4">
                        <vehicle-search-details-skeleton-loader />
                    </div>
                    <div
                        v-if="manualVehicleEntry"
                        class="flex-no-wrap justify-space-between"
                    >
                        <ValidationObserver ref="validationObserver">
                            <v-form
                                v-model="manualVehicleValid"
                                lazy-validation
                                ref="manualVehicleForm"
                            >
                                <div class="pt-1 mt-1"></div>
                                <v-container class="pa-4">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Vehicle manufacturer"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    id="manualVehicle-brand"
                                                    v-model="loadedVehicle.brand"
                                                    @keyup="sanitizeInput('brand')"
                                                    :error-messages="errors"
                                                    prepend-inner-icon="mdi-car"
                                                    label="Vehicle manufacturer"
                                                    hint="e.g.: Audi"
                                                    clearable
                                                    outlined
                                                    rounded
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Engine capacity"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    id="manualVehicle-capacity"
                                                    v-model="loadedVehicle.capacity"
                                                    @keyup="sanitizeInput('capacity')"
                                                    :error-messages="errors"
                                                    prepend-inner-icon="mdi-car"
                                                    label="Engine capacity"
                                                    clearable
                                                    outlined
                                                    rounded
                                                />
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Vehicle range"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    id="manualVehicle-range"
                                                    v-model="loadedVehicle.range"
                                                    @keyup="sanitizeInput('range')"
                                                    :error-messages="errors"
                                                    prepend-inner-icon="mdi-car-back"
                                                    label="Vehicle range"
                                                    hint="e.g.: A4"
                                                    clearable
                                                    outlined
                                                    rounded
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Vehicle transmission type"
                                                rules="required"
                                            >
                                                <v-select
                                                    id="manualVehicle-transmissionType"
                                                    v-model="loadedVehicle.transmissionType"
                                                    :error-messages="errors"
                                                    :items="['Manual', 'Automatic']"
                                                    prepend-inner-icon="mdi-car-shift-pattern"
                                                    label="Vehicle transmission type"
                                                    outlined
                                                    rounded
                                                />
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Vehicle model"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    id="manualVehicle-model"
                                                    v-model="loadedVehicle.model"
                                                    @keyup="sanitizeInput('model')"
                                                    :error-messages="errors"
                                                    prepend-inner-icon="mdi-car-back"
                                                    label="Vehicle model"
                                                    hint="e.g.: 2.0 TFSI"
                                                    clearable
                                                    outlined
                                                    rounded
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Vehicle fuel type"
                                                rules="required"
                                            >
                                                <v-select
                                                    id="manualVehicle-fuelType"
                                                    v-model="loadedVehicle.fuelType"
                                                    :error-messages="errors"
                                                    :items="['Petrol', 'Diesel', 'Hybrid', 'Electric']"
                                                    prepend-inner-icon="mdi-gas-station"
                                                    label="Vehicle fuel type"
                                                    outlined
                                                    rounded
                                                />
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Vehicle year"
                                                :rules="`required|numeric|min_value:1960|max_value:${currentYear}`"
                                            >
                                                <v-combobox
                                                    id="manualVehicle-year"
                                                    v-model="loadedVehicle.year"
                                                    :error-messages="errors"
                                                    :items="manualVehicleYearList"
                                                    prepend-inner-icon="mdi-car-2-plus"
                                                    label="Vehicle year"
                                                    hint="e.g.: 2015"
                                                    clearable
                                                    outlined
                                                    rounded
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Vehicle drive type"
                                                rules="required"
                                            >
                                                <v-select
                                                    id="manualVehicle-driveType"
                                                    v-model="loadedVehicle.driveType"
                                                    :error-messages="errors"
                                                    :items="[
                                                        'Front wheel drive',
                                                        'Rear wheel drive',
                                                        'All wheel drive',
                                                        `I'm not sure`,
                                                    ]"
                                                    prepend-inner-icon="mdi-car-side"
                                                    label="Vehicle drive type"
                                                    outlined
                                                    rounded
                                                />
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </ValidationObserver>
                    </div>
                    <div v-else class="d-flex flex-no-wrap justify-space-between">
                        <v-row>
                            <v-col
                                cols="12"
                                v-if="
                                    this.$vuetify.breakpoint.smAndDown && loadedVehicle.imageUrl
                                "
                            >
                                <v-avatar
                                    v-if="!searchIsLoading"
                                    class="ma-3"
                                    size="125"
                                    width="90%"
                                    tile
                                >
                                    <img
                                        :src="loadedVehicle.imageUrl"
                                        class="smc-search-result-image"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col>
                                <v-chip
                                    v-if="selectedGarageVehicle && selectedGarageVehicle.status"
                                    class="float-right mt-4"
                                    color="primary"
                                    small
                                    label
                                >
                                    {{ selectedGarageVehicle.status }}
                                </v-chip>
                                <div v-if="!searchIsLoading">
                                    <v-card-title
                                        class="headline vehicle-card-title"
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : ''"
                                    >
                                        {{ loadedVehicle.year }}: {{ loadedVehicle.brand }} -
                                        {{ loadedVehicle.range }}
                                    </v-card-title>
                                    <v-card-subtitle style="text-align: left">
                                        <strong>{{ loadedVehicle.model }}</strong>
                                        <br />

                                        <span v-if="loadedVehicle.introductionDate">
                                            <strong>Introduced in:</strong>
                                            {{ introDate(loadedVehicle.introductionDate) }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.discontinuedDate">
                                            <strong>Discontinued in:</strong>
                                            {{ outroDate(loadedVehicle.discontinuedDate) }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.transmissionType">
                                            <strong>Transmission:</strong>
                                            {{ loadedVehicle.transmissionType }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.transmissionType">
                                            <strong>Gearbox:</strong>
                                            {{ loadedVehicle.gearboxType }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.fuelType">
                                            <strong>Fuel type:</strong>
                                            {{ loadedVehicle.fuelType }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.capacity">
                                            <strong>Capacity:</strong>
                                            {{ loadedVehicle.capacity }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.aspirationType">
                                            <strong>Aspiration</strong>
                                            {{ loadedVehicle.aspirationType }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.bodyType">
                                            <strong>Body type:</strong>
                                            {{ loadedVehicle.bodyType }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.driveType">
                                            <strong>Drive:</strong>
                                            {{ loadedVehicle.driveType }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.warranty">
                                            <strong>Manufacturer warranty:</strong>
                                            {{ loadedVehicle.warranty }}
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.serviceInterval1">
                                            <strong>Service Interval 1:</strong>
                                            {{ loadedVehicle.serviceInterval1 | numeral("0,0") }} KM
                                            <br />
                                        </span>

                                        <span v-if="loadedVehicle.serviceInterval2">
                                            <strong>Service Interval 2:</strong>
                                            {{ loadedVehicle.serviceInterval2 | numeral("0,0") }} KM
                                            <br />
                                        </span>
                                    </v-card-subtitle>
                                    <v-card-actions v-if="!loadedVehicle.diskDriveReference">
                                        <v-btn
                                            x-small
                                            outlined
                                            rounded
                                            right
                                            absolute
                                            color="midGrey"
                                            @click="
                                                manualVehicleEntry = true;
                                                showQuote = false;
                                            "
                                        >
                                            Edit
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                            </v-col>
                            <v-col v-if="this.$vuetify.breakpoint.mdAndUp" class="text-right">
                                <img
                                    :src="loadedVehicle.imageUrl"
                                    class="smc-search-result-image mt-3"
                                    style="width: 300px; margin-right: 15px; border-radius: 8px"
                                />
                                <br />
                                <v-card
                                    class="smc-my-garage-card float-right mr-4"
                                    width="300"
                                    v-if="
                                        selectedGarageVehicle
                                            && selectedGarageVehicle.innovationPolicy
                                            && selectedGarageVehicle.innovationPolicy.policyStatus
                                                !== innovationPolicyStatuses.expired.value
                                            && selectedGarageVehicle.innovationPolicy.policyStatus
                                                !== innovationPolicyStatuses.cancelled.value
                                    "
                                >
                                    <v-card-title>
                                        {{ selectedGarageVehicle.name }}
                                    </v-card-title>
                                    <v-card-text class="px-3 pb-3">
                                        <v-list-item-content>
                                            <v-list-item-subtitle style="text-align: left">
                                                {{ selectedGarageVehicle.innovationPolicy.policyCode }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="text-align: left">
                                                {{ selectedGarageVehicle.innovationPolicy.vinNumber }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="text-align: left">
                                                <strong>Start date:</strong>
                                                {{
                                                    dateHelper.formatDateShort(
                                                        selectedGarageVehicle.innovationPolicy.startDate
                                                    )
                                                }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="text-align: left">
                                                <strong>End date:</strong>
                                                {{
                                                    dateHelper.formatDateShort(
                                                        selectedGarageVehicle.innovationPolicy.endDate
                                                    )
                                                }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="text-align: left">
                                                <strong>End distance:</strong>
                                                {{
                                                    selectedGarageVehicle.innovationPolicy.endDistance
                                                        | numeral("0,0")
                                                }}
                                                km
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <router-link to="#">
                                            <v-img
                                                class="innovation-logo"
                                                width="160"
                                                contain
                                                :src="require('@/assets/images/ig-logo.png')"
                                                v-ripple
                                            />
                                        </router-link>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row v-if="dekraVehicleAttachment()">
                        <v-col class="py-0">
                            <pdf-viewer
                                ref="dekra-pdf-viewer"
                                :pdfUrlProp="attachmentUrl(dekraVehicleAttachment())"
                                :pdfFilenameProp="
                                    attachmentFileName(
                                        dekraVehicleAttachment().attachmentFileName
                                    )
                                "
                                :pdfAllPagesProp="true"
                            />
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-row
                            v-if="
                                (selectedGarageVehicle
                                    && selectedGarageVehicle.serviceSchedule
                                    && selectedGarageVehicle.serviceSchedule.pastServices.length
                                        > 0)
                                    || (selectedVehicle && isOrganization)
                            "
                        >
                            <v-col class="text-right">
                                <v-btn
                                    small
                                    rounded
                                    outlined
                                    right
                                    color="info"
                                    class="mb-2 mr-4"
                                    @click="showServiceSchedule = !showServiceSchedule"
                                >
                                    {{ showServiceSchedule ? "Hide" : "Show" }}
                                    Service Schedule
                                </v-btn>
                                <v-expand-transition>
                                    <v-simple-table
                                        v-if="!isOrganization && showServiceSchedule"
                                        fixed-header
                                        height="300px"
                                    >
                                        <thead>
                                            <tr>
                                                <th class="grey darken-3 white--text text-left">
                                                    Date
                                                </th>
                                                <th class="grey darken-3 white--text text-left">
                                                    Workshop
                                                </th>
                                                <th class="grey darken-3 white--text text-left">
                                                    Type
                                                </th>
                                                <th class="grey darken-3 white--text text-left">
                                                    Mileage
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(item, index) in selectedGarageVehicle
                                                    .serviceSchedule.pastServices"
                                                :key="index"
                                                class="text-left"
                                                :class="`border-${item.color}`"
                                            >
                                                <td>
                                                    {{ dateHelper.formatDateLong(item.serviceDate) }}
                                                </td>
                                                <td>
                                                    {{ item.workshopName }}
                                                </td>
                                                <td>
                                                    {{ item.type }}
                                                    <span>
                                                        <br />
                                                        <v-chip
                                                            v-if="item.trigger"
                                                            :color="item.color"
                                                            class="mb-1 ml-n3 pr-6"
                                                            small
                                                        >
                                                            {{ item.trigger }}
                                                        </v-chip>
                                                    </span>
                                                </td>
                                                <td>{{ item.vehicleMilleage }} KM</td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                    <vehicle-details
                                        v-if="isOrganization && showServiceSchedule"
                                        :vehicleMappingProp="{ vehicle: selectedVehicle }"
                                        :hideImageProp="true"
                                    />
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-card-text
                            v-if="!showQuote && !searchIsLoading && warrantyWarningBrand()"
                        >
                            <div>
                                <v-row>
                                    <v-divider />
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-alert
                                            border="left"
                                            colored-border
                                            type="error"
                                            elevation="2"
                                            class="mb-0 body-2 text--secondary"
                                        >
                                            <div>
                                                Disclaimer - The onus is on you to confirm that repairs
                                                are carried out by an accredited repairer in order to
                                                prevent any conflict with the terms as set out by your
                                                vehicle manufacturer
                                            </div>
                                            <v-divider class="my-4 danger" />
                                            <v-row no-gutters>
                                                <v-col class="text-center">
                                                    <v-checkbox
                                                        v-model="acceptWarranty"
                                                        class="ma-0 d-inline-flex"
                                                        label="I Understand"
                                                        hide-details
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-card-text
                            v-if="vehicleHistory && vehicleHistory.length > 0 && !showQuote"
                        >
                            <v-card>
                                <v-card-title> Vehicle History </v-card-title>
                                <v-card-text>
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th>Request Type</th>
                                                <th>Date Submitted</th>
                                                <th>Quote Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="hist in vehicleHistory" :key="hist.id">
                                                <td class="text-left">
                                                    <span
                                                        :class="
                                                            hist.quoteRequestStatus
                                                                === quoteRequestStatuses.cancelled.id
                                                                ? 'text--disabled text-decoration-line-through'
                                                                : 'font-weight-medium'
                                                        "
                                                    >
                                                        {{ hist.reference }}
                                                    </span>
                                                    <div
                                                        v-if="
                                                            hist.quoteRequestStatus
                                                                === quoteRequestStatuses.cancelled.id
                                                        "
                                                        class="error--text font-weight-bold"
                                                    >
                                                        Cancelled
                                                    </div>
                                                </td>
                                                <td class="text-left">
                                                    <span
                                                        :class="
                                                            hist.quoteRequestStatus
                                                                === quoteRequestStatuses.cancelled.id
                                                                ? 'text--disabled text-decoration-line-through'
                                                                : 'font-weight-medium'
                                                        "
                                                    >
                                                        {{ hist.quoteType }}
                                                    </span>
                                                </td>
                                                <td class="text-left">
                                                    <span
                                                        :class="
                                                            hist.quoteRequestStatus
                                                                === quoteRequestStatuses.cancelled.id
                                                                ? 'text--disabled text-decoration-line-through'
                                                                : 'font-weight-medium'
                                                        "
                                                    >
                                                        {{ dateHelper.formatDateShort(hist.dateCreated) }}
                                                    </span>
                                                </td>
                                                <td class="text-left">
                                                    <span
                                                        :class="
                                                            hist.quoteRequestStatus
                                                                === quoteRequestStatuses.cancelled.id
                                                                ? 'text--disabled text-decoration-line-through'
                                                                : 'font-weight-medium'
                                                        "
                                                        v-if="hist.quotes && hist.quotes.length > 0"
                                                    >
                                                        {{ hist.quotes[0].quoteStatusText }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-card-text>
                                <v-divider />
                                <v-card-actions>
                                    <v-card outlined color="warning" width="100%">
                                        <v-card-title>
                                            <v-row>
                                                <v-spacer />
                                                <v-col cols="4">
                                                    <v-checkbox
                                                        v-model="financeReconOverride"
                                                        color="white"
                                                        hide-details
                                                        class="mt-0 text-center body-1"
                                                    >
                                                        <template #label>
                                                            <span class="white--text font-weight-bold">
                                                                Include admin fee with this request
                                                            </span>
                                                        </template>
                                                    </v-checkbox>
                                                </v-col>
                                                <v-spacer />
                                            </v-row>
                                        </v-card-title>
                                    </v-card>
                                </v-card-actions>
                            </v-card>
                        </v-card-text>
                    </v-expand-transition>
                    <v-card-text v-if="vehicleHistoryLoading">
                        <v-row>
                            <v-divider />
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-skeleton-loader
                                    elevation="6"
                                    type="card-heading, image"
                                />
                            </v-col>
                            <v-col>
                                <v-skeleton-loader
                                    elevation="6"
                                    type="card-heading, image"
                                />
                            </v-col>
                            <v-col>
                                <v-skeleton-loader
                                    elevation="6"
                                    type="card-heading, image"
                                />
                            </v-col>
                            <v-col>
                                <v-skeleton-loader
                                    elevation="6"
                                    type="card-heading, image"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <div v-else>
                        <v-expand-transition>
                            <v-card-text
                                v-if="
                                    isOrganization
                                        && selectedGarageVehicle
                                        && selectedGarageVehicle.status !== 'Finance Recon'
                                "
                            >
                                <v-row>
                                    <v-divider />
                                </v-row>
                                <v-row>
                                    <v-col
                                        :cols="quoteRequestType === 'Finance Recon 1' ? 12 : 3"
                                        v-if="
                                            quoteRequestType === undefined
                                                || quoteRequestType === 'Finance Recon 1'
                                        "
                                    >
                                        <div class="flex" style="height: 100%">
                                            <v-card
                                                @click="toggleQuoteRequestType('Finance Recon 1')"
                                                class="smc-my-reuqest-type-card"
                                                :class="
                                                    quoteRequestType === 'Finance Recon 1'
                                                        ? 'selected'
                                                        : ''
                                                "
                                                height="100%"
                                            >
                                                <v-card-title>
                                                    Finance Recon
                                                    <v-btn
                                                        v-if="
                                                            quoteRequestType === 'Finance Recon 1'
                                                                && !showQuote
                                                        "
                                                        x-small
                                                        class="ml-auto"
                                                        color="secondary"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-text v-if="quoteRequestType === undefined">
                                                    <v-row>
                                                        <v-col>
                                                            <p class="mt-3 mb-0">Might incur an admin fee</p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mt-0">
                                                        <v-col>
                                                            <v-icon size="80"> mdi-car-cog </v-icon>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col
                                        :cols="quoteRequestType === 'AAA' ? 12 : 3"
                                        v-if="
                                            quoteRequestType === undefined || quoteRequestType === 'AAA'
                                        "
                                    >
                                        <div class="flex" style="height: 100%">
                                            <v-card
                                                @click="toggleQuoteRequestType('AAA')"
                                                class="smc-my-reuqest-type-card"
                                                :class="quoteRequestType === 'AAA' ? 'selected' : ''"
                                                height="100%"
                                            >
                                                <v-card-title>
                                                    AAA
                                                    <v-btn
                                                        v-if="quoteRequestType === 'AAA' && !showQuote"
                                                        x-small
                                                        class="ml-auto"
                                                        color="secondary"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-text v-if="quoteRequestType === undefined">
                                                    <v-row class="mt-2">
                                                        <v-col>
                                                            <v-icon size="80"> mdi-car-cog </v-icon>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col
                                        :cols="quoteRequestType === 'Repairs' ? 12 : 3"
                                        v-if="
                                            quoteRequestType === undefined
                                                || quoteRequestType === 'Repairs'
                                        "
                                    >
                                        <div class="flex" style="height: 100%">
                                            <v-card
                                                @click="toggleQuoteRequestType('Repairs')"
                                                class="smc-my-reuqest-type-card"
                                                :class="quoteRequestType === 'Repairs' ? 'selected' : ''"
                                                height="100%"
                                            >
                                                <v-card-title>
                                                    Repairs
                                                    <v-btn
                                                        v-if="quoteRequestType === 'Repairs' && !showQuote"
                                                        x-small
                                                        class="ml-auto"
                                                        color="secondary"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-text v-if="quoteRequestType === undefined">
                                                    <v-row class="mt-2">
                                                        <v-col>
                                                            <v-icon size="80"> mdi-car-cog </v-icon>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col
                                        :cols="quoteRequestType === 'Aftersales' ? 12 : 3"
                                        v-if="
                                            quoteRequestType === undefined
                                                || quoteRequestType === 'Aftersales'
                                        "
                                    >
                                        <div class="flex" style="height: 100%">
                                            <v-card
                                                @click="toggleQuoteRequestType('Aftersales')"
                                                class="smc-my-reuqest-type-card"
                                                :class="
                                                    quoteRequestType === 'Aftersales' ? 'selected' : ''
                                                "
                                                height="100%"
                                            >
                                                <v-card-title>
                                                    Aftersales
                                                    <v-btn
                                                        v-if="
                                                            quoteRequestType === 'Aftersales' && !showQuote
                                                        "
                                                        x-small
                                                        class="ml-auto"
                                                        color="secondary"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-text v-if="quoteRequestType === undefined">
                                                    <v-row class="mt-2">
                                                        <v-col>
                                                            <v-icon size="80"> mdi-car-cog </v-icon>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-expand-transition>
                        <v-expand-transition>
                            <v-card-text
                                v-if="
                                    !showQuote
                                        && !searchIsLoading
                                        && (!isOrganization || quoteRequestType !== undefined)
                                "
                            >
                                <div>
                                    <v-row>
                                        <v-divider />
                                    </v-row>
                                    <v-row class="quote-type-row">
                                        <v-col cols="6" v-if="canSelectSmcServiceType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="checkServiceType(quoteTypes.smcServiceLabel)"
                                                    :outlined="quoteType === quoteTypes.smcServiceLabel"
                                                    :class="
                                                        quoteTypes.smcServiceLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-checkbox
                                                            v-model="quoteType"
                                                            :value="quoteTypes.smcServiceLabel"
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        >
                                                            <template #label>
                                                                <span class="white--text">
                                                                    I need a service
                                                                </span>
                                                            </template>
                                                        </v-checkbox>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-list dense>
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-alert-circle-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Service due light on
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider />
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-car-info</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Vehicle checkup
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider />
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-clipboard-list-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Annual maintenance service
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider
                                                                v-if="
                                                                    selectedGarageVehicle
                                                                        && selectedGarageVehicle.serviceSchedule
                                                                        && selectedGarageVehicle.serviceSchedule
                                                                            .pastServices.length > 0
                                                                        && !selectedGarageVehicle.serviceSchedule
                                                                            .promptService
                                                                "
                                                            />
                                                            <v-list-item
                                                                v-if="
                                                                    selectedGarageVehicle
                                                                        && selectedGarageVehicle.serviceSchedule
                                                                        && selectedGarageVehicle.serviceSchedule
                                                                            .pastServices.length > 0
                                                                        && !selectedGarageVehicle.serviceSchedule
                                                                            .promptService
                                                                "
                                                            >
                                                                <v-list-item-icon>
                                                                    <v-icon color="red">mdi-alert</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content class="text-left">
                                                                    Please note - your next service might not be
                                                                    due yet.
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" v-if="canSelectSmcRepairType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="checkServiceType(quoteTypes.smcRepairLabel)"
                                                    :outlined="quoteType === quoteTypes.smcRepairLabel"
                                                    :class="
                                                        quoteType === quoteTypes.smcRepairLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-checkbox
                                                            v-model="quoteType"
                                                            :value="quoteTypes.smcRepairLabel"
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        >
                                                            <template #label>
                                                                <span class="white--text">
                                                                    I need a repair
                                                                </span>
                                                            </template>
                                                        </v-checkbox>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-list dense>
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>
                                                                        mdi-clipboard-text-search-outline
                                                                    </v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Detailed job request
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider />
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-car-wrench</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Repairer knowledge
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider />
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-engine-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Parts replacement/repair
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider
                                                                v-if="quoteType === quoteTypes.smcRepairLabel"
                                                            />
                                                            <v-list-item
                                                                v-if="quoteType === quoteTypes.smcRepairLabel"
                                                            >
                                                                <v-list-item-icon>
                                                                    <v-icon color="info">mdi-alert</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content class="text-left">
                                                                    Kindly note that repair requests may take
                                                                    longer than service requests due to parts
                                                                    pricing
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider v-if="isPolicyQuote" />
                                                            <v-list-item v-if="isPolicyQuote">
                                                                <v-list-item-icon>
                                                                    <v-icon color="red">mdi-alert</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content class="text-left">
                                                                    Please note - your policy might not cover all
                                                                    types of repairs
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" v-if="canSelectIgsServiceType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="checkServiceType(quoteTypes.igsServiceLabel)"
                                                    :outlined="quoteType === quoteTypes.igsServiceLabel"
                                                    :class="
                                                        quoteTypes.igsServiceLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-checkbox
                                                            v-model="quoteType"
                                                            :value="quoteTypes.igsServiceLabel"
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        >
                                                            <template #label>
                                                                <span class="white--text">
                                                                    I need a service
                                                                </span>
                                                            </template>
                                                        </v-checkbox>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-list dense>
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-alert-circle-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Service due light on
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider />
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-car-info</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Vehicle checkup
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider />
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-clipboard-list-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Annual maintenance service
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider
                                                                v-if="
                                                                    selectedGarageVehicle
                                                                        && selectedGarageVehicle.serviceSchedule
                                                                        && selectedGarageVehicle.serviceSchedule
                                                                            .pastServices.length > 0
                                                                        && !selectedGarageVehicle.serviceSchedule
                                                                            .promptService
                                                                "
                                                            />
                                                            <v-list-item
                                                                v-if="
                                                                    selectedGarageVehicle
                                                                        && selectedGarageVehicle.serviceSchedule
                                                                        && selectedGarageVehicle.serviceSchedule
                                                                            .pastServices.length > 0
                                                                        && !selectedGarageVehicle.serviceSchedule
                                                                            .promptService
                                                                "
                                                            >
                                                                <v-list-item-icon>
                                                                    <v-icon color="red">mdi-alert</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content class="text-left">
                                                                    Please note - your next service might not be
                                                                    due yet.
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" v-if="canSelectIgsRepairType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="checkServiceType(quoteTypes.igsRepairLabel)"
                                                    :outlined="quoteType === quoteTypes.igsRepairLabel"
                                                    :class="
                                                        quoteType === quoteTypes.igsRepairLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-checkbox
                                                            v-model="quoteType"
                                                            :value="quoteTypes.igsRepairLabel"
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        >
                                                            <template #label>
                                                                <span class="white--text">
                                                                    I need a repair
                                                                </span>
                                                            </template>
                                                        </v-checkbox>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-list dense>
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>
                                                                        mdi-clipboard-text-search-outline
                                                                    </v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Detailed job request
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider />
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-car-wrench</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Repairer knowledge
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider />
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-engine-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    Parts replacement/repair
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider
                                                                v-if="quoteType === quoteTypes.smcRepairLabel"
                                                            />
                                                            <v-list-item
                                                                v-if="quoteType === quoteTypes.smcRepairLabel"
                                                            >
                                                                <v-list-item-icon>
                                                                    <v-icon color="info">mdi-alert</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content class="text-left">
                                                                    Kindly note that repair requests may take
                                                                    longer than service requests due to parts
                                                                    pricing
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider v-if="isPolicyQuote" />
                                                            <v-list-item v-if="isPolicyQuote">
                                                                <v-list-item-icon>
                                                                    <v-icon color="red">mdi-alert</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content class="text-left">
                                                                    Please note - your policy might not cover all
                                                                    types of repairs
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <!--
                      <v-col cols="6" v-if="canSelectIgsPolicyType">
                        <div class="flex" style="height: 100%">
                          <v-card
                            class="smc-my-garage-card"
                            height="100%"
                            @click="checkServiceType('service')"
                            :outlined="quoteType === quoteTypes.igsPolicyLabel"
                            :class="
                              quoteType === quoteTypes.igsPolicyLabel
                                ? 'service-type-checked'
                                : ''
                            "
                          >
                            <v-card-title>
                              <v-checkbox
                                v-model="quoteType"
                                :value="quoteTypes.igsPolicyLabel"
                                color="white"
                                class="my-2"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span class="white--text">
                                    I need a service
                                  </span>
                                </template>
                              </v-checkbox>
                            </v-card-title>
                            <v-card-text>
                              <v-list dense>
                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-icon>mdi-alert-circle-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    Service due light on
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-icon>mdi-car-info</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    Vehicle checkup
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-icon>mdi-clipboard-list-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    Annual maintenance service
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  v-if="
                                    selectedGarageVehicle &&
                                    selectedGarageVehicle.serviceSchedule &&
                                    selectedGarageVehicle.serviceSchedule
                                      .pastServices.length > 0 &&
                                    !selectedGarageVehicle.serviceSchedule
                                      .promptService
                                  "
                                >
                                </v-divider>
                                <v-list-item
                                  v-if="
                                    selectedGarageVehicle &&
                                    selectedGarageVehicle.serviceSchedule &&
                                    selectedGarageVehicle.serviceSchedule
                                      .pastServices.length > 0 &&
                                    !selectedGarageVehicle.serviceSchedule
                                      .promptService
                                  "
                                >
                                  <v-list-item-icon>
                                    <v-icon color="red">mdi-alert</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content class="text-left">
                                    Please note - your next service might not be due
                                    yet.
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-col>
                      -->
                                        <v-col cols="4" v-if="canSelectWbcInspectionType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="
                                                        checkServiceType(quoteTypes.wbcInspectionLabel)
                                                    "
                                                    :outlined="quoteType === quoteTypes.wbcInspectionLabel"
                                                    :class="
                                                        quoteType === quoteTypes.wbcInspectionLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-simple-checkbox
                                                            :value="
                                                                quoteType === quoteTypes.wbcInspectionLabel
                                                            "
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        />
                                                        <span class="white--text">
                                                            Quote After Inspection
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-card-text>
                                                            <v-row class="mt-2">
                                                                <v-col>
                                                                    <v-icon size="80">
                                                                        mdi-clipboard-search
                                                                    </v-icon>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="4" v-if="canSelectWbcServiceType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="checkServiceType(quoteTypes.wbcServiceLabel)"
                                                    :outlined="quoteType === quoteTypes.wbcServiceLabel"
                                                    :class="
                                                        quoteType === quoteTypes.wbcServiceLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-simple-checkbox
                                                            :value="quoteType === quoteTypes.wbcServiceLabel"
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        />
                                                        <span class="white--text">
                                                            Get Multiple Service Quotes
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row class="mt-2">
                                                            <v-col>
                                                                <v-icon size="80">
                                                                    mdi-clipboard-multiple
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="4" v-if="canSelectWbcRepairType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="checkServiceType(quoteTypes.wbcRepairLabel)"
                                                    :outlined="quoteType === quoteTypes.wbcRepairLabel"
                                                    :class="
                                                        quoteType === quoteTypes.wbcRepairLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-simple-checkbox
                                                            :value="quoteType === quoteTypes.wbcRepairLabel"
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        />
                                                        <span class="white--text">
                                                            Get Multiple Repair Quotes
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row class="mt-2">
                                                            <v-col>
                                                                <v-icon size="80">
                                                                    mdi-clipboard-multiple-outline
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="4" v-if="canSelectWbcWheelsAndTyresType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="
                                                        checkServiceType(quoteTypes.wbcWheelsTyresLabel)
                                                    "
                                                    :outlined="
                                                        quoteType === quoteTypes.wbcWheelsTyresLabel
                                                    "
                                                    :class="
                                                        quoteType === quoteTypes.wbcWheelsTyresLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-simple-checkbox
                                                            :value="
                                                                quoteType === quoteTypes.wbcWheelsTyresLabel
                                                            "
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        />
                                                        <span class="white--text">
                                                            Get Wheels and Tyre Quotes
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row class="mt-2">
                                                            <v-col>
                                                                <v-icon size="80"> mdi-tire </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="4" v-if="canSelectWbcWindscreenType">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="
                                                        checkServiceType(quoteTypes.wbcWindscreenLabel)
                                                    "
                                                    :outlined="quoteType === quoteTypes.wbcWindscreenLabel"
                                                    :class="
                                                        quoteType === quoteTypes.wbcWindscreenLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-simple-checkbox
                                                            :value="
                                                                quoteType === quoteTypes.wbcWindscreenLabel
                                                            "
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        />
                                                        <span class="white--text">
                                                            Get Windscreen Quotes
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row class="mt-2">
                                                            <v-col>
                                                                <v-icon size="80"> mdi-car-windshield </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="4" v-if="canSelectWbcBodyExterior">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="
                                                        checkServiceType(quoteTypes.wbcBodyExternalLabel)
                                                    "
                                                    :outlined="
                                                        quoteType === quoteTypes.wbcBodyExternalLabel
                                                    "
                                                    :class="
                                                        quoteType === quoteTypes.wbcBodyExternalLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-simple-checkbox
                                                            :value="
                                                                quoteType === quoteTypes.wbcBodyExternalLabel
                                                            "
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        />
                                                        <span class="white--text">
                                                            Get Body & Exterior Quotes
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row class="mt-2">
                                                            <v-col>
                                                                <v-icon size="80"> mdi-car-door </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="4" v-if="canSelectWbcDekra">
                                            <div class="flex" style="height: 100%">
                                                <v-card
                                                    class="smc-my-garage-card"
                                                    height="100%"
                                                    @click="checkServiceType(quoteTypes.wbcDekraLabel)"
                                                    :outlined="quoteType === quoteTypes.wbcDekraLabel"
                                                    :class="
                                                        quoteType === quoteTypes.wbcDekraLabel
                                                            ? 'service-type-checked'
                                                            : ''
                                                    "
                                                >
                                                    <v-card-title>
                                                        <v-simple-checkbox
                                                            :value="quoteType === quoteTypes.wbcDekraLabel"
                                                            color="white"
                                                            label=""
                                                            class="my-2"
                                                            hide-details
                                                        />
                                                        <span class="white--text">
                                                            Quote as per Dekra
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row class="mt-2">
                                                            <v-col>
                                                                <v-icon size="80"> mdi-clipboard-file </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-divider />
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <p>Please choose an option above.</p>
                                            <v-btn
                                                :disabled="
                                                    quoteType === undefined
                                                        || (warrantyWarningBrand() && !acceptWarranty)
                                                        || (manualVehicleEntry && !manualVehicleValid)
                                                "
                                                color="primary"
                                                rounded
                                                @click="startQuote"
                                            >
                                                Start
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-expand-transition>
                    </div>
                    <v-expand-transition>
                        <v-card-text v-if="showQuote">
                            <v-btn
                                color="primary"
                                small
                                rounded
                                @click="
                                    resetQuoteRequestForm({
                                        resetQuoteRequest: true,
                                        skipConfirmation: false,
                                    })
                                "
                            >
                                Clear
                            </v-btn>
                        </v-card-text>
                    </v-expand-transition>
                </v-card>
            </div>
        </v-expand-transition>

        <v-card-text class="px-0">
            <v-expand-transition>
                <div v-if="showQuote">
                    <quote-request-create
                        :vehicleProp="loadedVehicle"
                        :quoteRequestProp="quoteRequest"
                        :quoteTypeProp="quoteType"
                        :quoteRequestTypeProp="quoteRequestType"
                        @resetForm="resetQuoteRequestForm($event)"
                        @openDekra="openDekra"
                    />
                </div>
            </v-expand-transition>
        </v-card-text>

        <v-dialog
            v-if="selectedGarageVehicle && selectedGarageVehicle.innovationPolicy"
            v-model="innovationPolicyWarningDialogShow"
            max-width="400"
            transition="dialog-top-transition"
        >
            <v-card class="pa-0">
                <v-toolbar color="primary" dark height="48" class="mb-6">
                    {{ innovationPolicyWarning.title }}
                </v-toolbar>
                <v-card-text>
                    <v-card
                        color="orange"
                        style="
              position: absolute;
              left: 0;
              right: 0;
              top: 48px;
              height: 110px;
            "
                    >
                        <v-card-text style="color: white" class="pb-0">
                            <v-icon x-large style="color: white">mdi-alert-circle</v-icon>
                        </v-card-text>
                        <v-card-text style="color: white" class="pt-0">
                            <p style="padding-top: 15px" class="mb-0">Please note</p>
                        </v-card-text>
                    </v-card>
                    <div style="margin-top: 134px">
                        <v-row>
                            <v-col>
                                <p class="mb-0">{{ innovationPolicyWarning.message }}</p>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        rounded
                        class="smc-text-button smc-small-button"
                        color="primary"
                        @click="innovationPolicyWarningDialogShow = false"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="resetFormDialogShow"
            transition="dialog-top-transition"
            max-width="400"
        >
            <template #default="dialog">
                <v-card>
                    <v-toolbar color="primary" dark> Clear Form </v-toolbar>
                    <v-card-text class="pb-0">
                        <div class="text pa-6">
                            Are you sure you want to discard your progress and clear the form?
                        </div>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions class="justify-end">
                        <v-btn
                            @click="dialog.value = false"
                            rounded
                            color="secondary"
                            class="smc-text-button smc-small-button mx-1 my-1"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            @click="resetQuoteRequestFormConfirm"
                            rounded
                            color="primary"
                            class="smc-text-button smc-small-button mx-1 my-1"
                        >
                            Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Typed from 'typed.js';
import roller from 'vue-roller';
import validate from '@/mixins/validator';
import { vehicleService, quoteRequestService, userService } from '@/services';
import {
    quoteTypes,
    vehicleHelper,
    quoteRequestHelper,
    dateHelper,
    innovationPolicyStatuses,
    quoteRequestStatuses,
} from '@/helpers';
import quoteRequestCreate from '@/components/quote-request-create';
import vehicleDetails from './vehicle-details';
import vehicleSearchDetailsSkeletonLoader from '@/components/widgets/vehicle-search-details-skeleton-loader';
import pdfViewer from '@/components/widgets/pdf-viewer';

export default {
    name: 'vehicle-search',
    mixins: [validate],
    props: {
        vehicleMappingIdProp: String,
        vehicleModel: Object,
        garageVehicleProp: Object,
        quoteRequestModel: Object,
        showExpandedProp: Boolean,
        noSearchProp: Boolean,
        isPolicyQuoteProp: Boolean,
    },
    components: {
        quoteRequestCreate,
        vehicleDetails,
        vehicleSearchDetailsSkeletonLoader,
        roller,
        pdfViewer,
    },
    data() {
        return {
            dateHelper,
            quoteTypes,
            innovationPolicyStatuses,
            quoteRequestStatuses,

            searchInitialized: false,
            showSearch: false,
            showGarage: false,
            showServiceSchedule: false,
            resetFormDialogShow: false,

            vehicleHistoryLoading: false,
            vehicleHistory: [],

            quoteType: undefined,
            quoteRequestType: undefined,

            financeReconOverride: false,
            canSelectSmcServiceType: false,
            canSelectSmcRepairType: false,
            canSelectIgsServiceType: false,
            canSelectIgsRepairType: false,
            canSelectIgsPolicyType: false,
            canSelectWbcServiceType: false,
            canSelectWbcRepairType: false,
            canSelectWbcInspectionType: false,
            canSelectWbcWheelsAndTyresType: false,
            canSelectWbcWindscreenType: false,
            canSelectWbcBodyExterior: false,
            canSelectWbcDekra: false,

            //policyChecked: false,
            //serviceChecked: false,
            //repairChecked: false,
            //inspectionChecked: false,

            flipped: true,
            rollerCounter: '0',
            searchTerm: null,
            searchIsLoading: false,
            manualVehicleEntry: false,
            manualVehicleValid: true,
            timeout: 0,
            vehicles: [],
            showQuote: this.quoteRequestModel !== undefined,

            noSearch: this.noSearchProp,
            searchLoading: false,
            isPolicyQuote: this.isPolicyQuoteProp,
            acceptWarranty: false,

            selectedVehicleShow: false,
            selectedVehicle: this.vehicleModel,
            selectedGarageVehicle: this.garageVehicleProp,
            loadedVehicle: this.vehicleModel,
            quoteRequest: this.quoteRequestModel,

            policyRepairQuoteWarningShow: false,
            myGarageVehicles: [],
            innovationPolicyWarningDialogShow: false,
            innovationPolicyWarning: {
                level: '',
                title: '',
                message: '',
            },
        };
    },
    computed: {
        currentYear() {
            return moment().year();
        },
        manualVehicleYearList() {
            let beginningYear = 1960;
            let yearsList = [];
            for (let i = this.currentYear; i >= beginningYear; i--) {
                yearsList.push(i);
            }
            return yearsList;
        },
        isOrganization() {
            return this.$store.getters['user/isOrganization'];
        },
    },
    async created() {
        if (this.showExpandedProp) {
            await this.updateSearchInitialized(true);
        }
        if (this.garageVehicleProp) {
            this.selectedGarageVehicle = _.cloneDeep(this.garageVehicleProp);
            await this.selectedGarageVehicleChange();
        }
        setInterval(() => {
            this.flip();
        }, 6500);

        let quoteRequest = this.$store.getters['quoteRequestCreate/getQuoteRequest'];

        if (quoteRequest !== undefined) {
            this.quoteRequest = quoteRequest;
            this.loadedVehicle = this.$store.getters['quoteRequestCreate/getQuoteRequestVehicle'];
            this.selectedGarageVehicle = this.$store.getters['quoteRequestCreate/getGarageVehicle'];
            let quoteType = this.$store.getters['quoteRequestCreate/getQuoteType'];
            this.quoteType = quoteType;
            this.showQuote = true;
            await this.updateSearchInitialized(true);
            this.noSearch = true;

            if (quoteType.includes(' - ')) {
                let quoteTypeParts = _.split(quoteType, ' - ');
                this.quoteRequestType = quoteTypeParts[0];
                this.quoteType = quoteTypeParts[1];
            }
        }

        if (
            this.selectedGarageVehicle
            && this.selectedGarageVehicle.innovationPolicy
        ) {
            this.isPolicyQuote = true;
            this.canSelectIgsPolicyType = true;
        }
        else {
            this.canSelectSmcServiceType = true;
            this.canSelectSmcRepairType = true;
        }
    },
    async mounted() {
        let cookieValue = this.$cookies.get('customers_count');
        if (cookieValue) {
            this.rollerCounter = cookieValue;
        }
        else {
            let result = await quoteRequestService.getCustomersCount();
            if (result.status === 200) {
                this.rollerCounter = `${result.data}`;
                this.$cookies.set('customers_count', result.data, '1d');
            }
        }
    },
    watch: {
        searchTerm: async function (val) {
            if (val) {
                if (val.length >= 2) {
                    this.searchIsLoading = true;
                    try {
                        this.vehicles = await vehicleService.searchVehicles(val);
                        this.searchIsLoading = false;
                    }
                    catch (error) {
                        if (error.__CANCEL__) {
                            // do essentially nothing
                        }
                    }
                }
                else {
                    this.vehicles = undefined;
                }
            }
        },
        selectedVehicle: async function (val) {
            if (val && typeof val === 'object') {
                this.manualVehicleEntry = false;
                if (process.env.VUE_APP_BYPASS_DISKDRIVE !== 'true') {
                    if (val.diskDriveReference) {
                        try {
                            this.searchIsLoading = true;
                            this.loadedVehicle = await vehicleService.vehicleLookup(
                                val.diskDriveReference,
                            );
                            this.loadedVehicle.year = val.year;
                            this.searchIsLoading = false;
                        }
                        catch (error) {
                            if (error) {
                                // DiskDrive lookup failed. I can at least populate the vehicle with everthing pulled from lucene.
                                this.loadedVehicle = val;
                                this.searchIsLoading = false;
                            }
                        }
                    }
                }
                else {
                    // Bypassing diskdrive means loading the vehicle as returned by lucene.
                    this.loadedVehicle = val;
                }

                this.$emit('vehicleSelected');
            }
            else if (val === undefined) {
                // We end up here if the user clears the search box.
                this.resetQuoteRequestForm({
                    resetQuoteRequest: true,
                    skipConfirmation: true,
                });
                this.$emit('searchCleared');
            }

            this.quoteType = undefined;
            this.quoteRequestType = undefined;
            this.selectedVehicleShow = false;
            //this.policyChecked = false;
            //this.serviceChecked = false;
            //this.repairChecked = false;
            //this.inspectionChecked = false;

            // if (this.loadedVehicle && this.isOrganization) {
            //   this.repairChecked = true;
            //   this.startQuote();
            // }
        },
        selectedGarageVehicle: async function (val) {
            if (val) {
                this.selectedVehicle = val.vehicle;
                this.vehicleHistory = [];
                if (val.innovationPolicy) {
                    this.canSelectSmcServiceType = false;
                    this.canSelectSmcRepairType = false;
                    let status = val.innovationPolicy.policyStatus;
                    switch (status) {
                        case innovationPolicyStatuses.active.value:
                            this.isPolicyQuote = true;
                            this.canSelectIgsServiceType = true;
                            this.canSelectIgsRepairType = true;
                            break;
                        case innovationPolicyStatuses.suspened.value:
                            this.innovationPolicyWarning = innovationPolicyStatuses.suspened;
                            this.innovationPolicyWarningDialogShow = true;
                            this.isPolicyQuote = true;
                            this.canSelectIgsServiceType = true;
                            this.canSelectIgsRepairType = true;
                            break;
                        case innovationPolicyStatuses.inactive.value:
                            this.innovationPolicyWarning = innovationPolicyStatuses.inactive;
                            this.innovationPolicyWarningDialogShow = true;
                            this.isPolicyQuote = true;
                            this.canSelectIgsServiceType = true;
                            this.canSelectIgsRepairType = true;
                            break;
                        case innovationPolicyStatuses.hold.value:
                            this.innovationPolicyWarning = innovationPolicyStatuses.hold;
                            this.innovationPolicyWarningDialogShow = true;
                            this.isPolicyQuote = true;
                            this.canSelectIgsServiceType = true;
                            this.canSelectIgsRepairType = true;
                            break;
                        case innovationPolicyStatuses.cancellationProgress.value:
                            this.innovationPolicyWarning = innovationPolicyStatuses.cancellationProgress;
                            this.innovationPolicyWarningDialogShow = true;
                            this.isPolicyQuote = true;
                            this.canSelectIgsServiceType = true;
                            this.canSelectIgsRepairType = true;
                            break;
                        case innovationPolicyStatuses.cancellationPendingRecovery.value:
                            this.innovationPolicyWarning = innovationPolicyStatuses.cancellationPendingRecovery;
                            this.innovationPolicyWarningDialogShow = true;
                            this.isPolicyQuote = true;
                            this.canSelectIgsServiceType = true;
                            this.canSelectIgsRepairType = true;
                            break;
                        case innovationPolicyStatuses.cancellationPendingCollections.value:
                            this.innovationPolicyWarning = innovationPolicyStatuses.cancellationPendingCollections;
                            this.innovationPolicyWarningDialogShow = true;
                            this.isPolicyQuote = true;
                            this.canSelectIgsServiceType = true;
                            this.canSelectIgsRepairType = true;
                            break;
                        case innovationPolicyStatuses.cancellationRequested.value:
                            this.innovationPolicyWarning = innovationPolicyStatuses.cancellationRequested;
                            this.innovationPolicyWarningDialogShow = true;
                            this.isPolicyQuote = true;
                            this.canSelectIgsServiceType = true;
                            this.canSelectIgsRepairType = true;
                            break;
                        default:
                            this.isPolicyQuote = false;
                            break;
                    }
                }
                else {
                    this.isPolicyQuote = false;
                }

                if (this.isOrganization) {
                    if (val.status === 'Finance Recon') {
                        await this.queryVehicleHistory();
                    }
                }
            }
            else {
                this.innovationPolicyWarning = {
                    level: '',
                    title: '',
                    message: '',
                };
                this.quoteType = undefined;
                this.quoteRequestType = undefined;
                this.selectedVehicleShow = false;
                this.selectedVehicle = undefined;
            }
        },
        showSearch: async function (val) {
            if (val) {
                setTimeout(() => {
                    new Typed('#searchVehicle-search', {
                        strings: process.env.VUE_APP_SEARCH_PLACEHOLDER.split(';'),
                        attr: 'placeholder',
                        typeSpeed: 40,
                        backSpeed: 10,
                        backDelay: 3500,
                        loop: true,
                    });
                }, 500);
            }
        },
        quoteRequestType(val) {
            this.quoteType = undefined;

            this.canSelectSmcServiceType = false;
            this.canSelectSmcRepairType = false;
            this.canSelectIgsServiceType = false;
            this.canSelectIgsRepairType = false;
            this.canSelectIgsPolicyType = false;
            this.canSelectWbcServiceType = false;
            this.canSelectWbcRepairType = false;
            this.canSelectWbcInspectionType = false;
            this.canSelectWbcWheelsAndTyresType = false;
            this.canSelectWbcWindscreenType = false;
            this.canSelectWbcBodyExterior = false;
            this.canSelectWbcDekra = false;

            switch (val) {
                case 'Finance Recon 1':
                    this.canSelectWbcServiceType = true;
                    this.canSelectWbcRepairType = true;
                    this.canSelectWbcInspectionType = true;
                    this.canSelectWbcDekra = false;
                    break;
                case 'Finance Recon 2':
                    this.canSelectWbcRepairType = true;
                    this.canSelectWbcWheelsAndTyresType = true;
                    this.canSelectWbcWindscreenType = true;
                    this.canSelectWbcBodyExterior = true;
                    break;
                case 'AAA':
                case 'Repairs':
                case 'Aftersales':
                    this.canSelectWbcInspectionType = true;
                    this.canSelectWbcServiceType = true;
                    this.canSelectWbcRepairType = true;
                    this.canSelectWbcWheelsAndTyresType = true;
                    this.canSelectWbcWindscreenType = true;
                    this.canSelectWbcBodyExterior = true;
                    this.canSelectWbcDekra = false;
                    break;
            }
        },
        financeReconOverride(val) {
            this.quoteType = undefined;
            if (val) {
                this.quoteRequestType = 'Finance Recon 1';
            }
            else {
                this.quoteRequestType = 'Finance Recon 2';
            }
        },
    },
    methods: {
        flip() {
            this.flipped = !this.flipped;
        },
        warrantyWarningBrand() {
            if (this.manualVehicleEntry) {
                return true;
            }
            if (this.selectedVehicle) {
                let warningBrands = process.env.VUE_APP_WARRANTY_WARNING_BRANDS.split(',');
                let index = warningBrands.indexOf(this.selectedVehicle.brand);
                return index !== -1;
            }
            return false;
        },
        async updateSearchInitialized(val) {
            let vm = this;
            if (!this.searchInitialized) {
                if (this.$store.getters['user/isAuthenticated']) {
                    this.searchLoading = true;
                    let result = await userService.getVehicles({
                        filter: '',
                        page: 1,
                        itemsPerPage: 50,
                    });

                    this.myGarageVehicles = _.orderBy(
                        result.data.items,
                        ['dateCreatedUtc'],
                        ['desc'],
                    );
                    this.myGarageVehicles.forEach(mapping => {
                        if (!mapping.name) {
                            mapping.name = 'VEHICLE';
                        }
                    });

                    if (this.vehicleMappingIdProp) {
                        let selectedMapping = _.find(this.myGarageVehicles, function (v) {
                            return v.id === vm.vehicleMappingIdProp;
                        });
                        if (selectedMapping) {
                            this.selectedGarageVehicle = selectedMapping;
                            this.noSearch = true;
                            await this.selectedGarageVehicleChange();
                        }
                    }

                    if (this.myGarageVehicles.length > 0) {
                        this.showGarage = true;
                        this.showSearch = false;
                    }
                    else {
                        this.showSearch = true;
                    }

                    this.searchLoading = false;
                }
                else {
                    this.showSearch = true;
                }

                this.searchInitialized = val;
            }
        },
        searchChange() {
            // In here is where I'm thinking of doing the search box enter function.
        },
        introDate(date) {
            return moment(date).format('MMMM, YYYY');
        },
        outroDate(date) {
            if (date) {
                return moment(date).format('MMMM, YYYY');
            }
            else {
                return 'Current';
            }
        },
        startManualVehicleEntry() {
            this.selectedGarageVehicle = undefined;
            this.showQuote = false;
            this.isPolicyQuote = false;
            this.$nextTick(function () {
                this.loadedVehicle = vehicleHelper.getEmptyVehicle();
                this.loadedVehicle.imageUrl = require('@/assets/images/no-entry-for-motor-vehicle.png');
                this.manualVehicleEntry = true;
            });
        },
        sanitizeInput(fieldName) {
            // Remove single quote (') character from the input value
            const sanitizedValue = this.loadedVehicle[fieldName].replace(/'/g, '');

            // Assign the sanitized value back to the corresponding field in selectedVehicle
            this.loadedVehicle[fieldName] = sanitizedValue;
        },
        getEmptyQuoteRequest() {
            let quoteRequest = quoteRequestHelper.getEmptyQuoteRequest();

            if (this.selectedGarageVehicle) {
                quoteRequest.existingVehicleMileage = this.selectedGarageVehicle.vehicleMileage;
                quoteRequest.vehicleName = this.selectedGarageVehicle.name;
                quoteRequest.vehicleVinNumber = this.selectedGarageVehicle.vehicleVinNumber;
                quoteRequest.vehicleEngineNumber = this.selectedGarageVehicle.vehicleEngineNumber;
                quoteRequest.vehicleLicensePlate = this.selectedGarageVehicle.vehicleLicensePlate;
                quoteRequest.vehicleColour = this.selectedGarageVehicle.vehicleColour;
            }

            return quoteRequest;
        },
        toggleQuoteRequestType(type) {
            if (!this.showQuote) {
                if (this.quoteRequestType === type) {
                    this.quoteRequestType = undefined;
                }
                else {
                    this.quoteRequestType = type;
                }
            }
        },
        checkServiceType(type) {
            this.quoteType = type;
        },

        dekraVehicleAttachment() {
            let attachment = undefined;
            if (
                this.selectedGarageVehicle
                && this.selectedGarageVehicle.vehicle
                && this.selectedGarageVehicle.vehicle.vehicleAttachments
                && this.selectedGarageVehicle.vehicle.vehicleAttachments.length > 0
            ) {
                attachment = this.selectedGarageVehicle.vehicle.vehicleAttachments.find(
                    att => /.*dekra.*\.pdf$/i.test(att.attachmentFileName),
                );
            }
            return attachment;
        },
        attachmentFileName(fileName) {
            if (fileName.length <= 20) {
                return fileName;
            }
            else {
                const extensionIndex = fileName.lastIndexOf('.');
                if (extensionIndex > 0) {
                    const basename = fileName.substring(0, extensionIndex);
                    const extension = fileName.substring(extensionIndex);
                    const truncatedName = basename.substring(0, 18 - extension.length) + '..';
                    return truncatedName + extension;
                }
                else {
                    // If there's no file extension, simply truncate to 20 characters
                    return fileName.substring(0, 18) + '..';
                }
            }
        },
        attachmentUrl(attachment) {
            if (!attachment) {
                return '';
            }
            let contentUrl = process.env.VUE_APP_SMC_CONTENT_URL;
            let fileName = this.attachmentFileName(attachment.attachmentFileName);
            let filePath = `vehicles/${this.selectedGarageVehicle.vehicleId}/VehicleReport`;
            return `${contentUrl}/${filePath}/${fileName}`;
        },
        openDekra() {
            if (this.$refs['dekra-pdf-viewer']) {
                if (!this.$refs['dekra-pdf-viewer'].getIsInitialized()) {
                    this.$refs['dekra-pdf-viewer'].openPage(1);
                    setTimeout(() => {
                        this.$refs['dekra-pdf-viewer'].openPage(3);
                    }, 1000);
                }
                else {
                    this.$refs['dekra-pdf-viewer'].openPage(3);
                }
            }
        },

        async startQuote() {
            if (await this.valdiateForm()) {
                if (
                    this.$store.getters['quoteRequestCreate/getQuoteRequest'] === undefined
                ) {
                    this.$store.dispatch(
                        'quoteRequestCreate/setQuoteRequest',
                        this.getEmptyQuoteRequest(),
                    );
                    this.$store.dispatch(
                        'quoteRequestCreate/setQuoteRequestVehicle',
                        this.loadedVehicle,
                    );
                    this.$store.dispatch(
                        'quoteRequestCreate/setGarageVehicle',
                        this.selectedGarageVehicle,
                    );
                }

                this.showSearch = false;
                this.showGarage = false;

                this.manualVehicleEntry = false;
                this.showQuote = true;
            }
        },
        async valdiateForm() {
            if (!this.manualVehicleEntry) {
                return true;
            }
            return await this.$refs.validationObserver.validate();
        },
        resetQuoteRequestForm(attr) {
            if (attr.resetQuoteRequest) {
                if (attr.skipConfirmation) {
                    this.resetQuoteRequestFormConfirm();
                }
                else {
                    this.resetFormDialogShow = true;
                }
            }
            else {
                this.showQuote = false;
                this.$vuetify.goTo(0);
                setTimeout(() => {
                    // Slight delay, then scroll to top again, to account for any page amimations
                    this.$vuetify.goTo(0);
                }, 500);

                this.$emit('searchCleared');
            }
        },
        resetQuoteRequestFormConfirm() {
            this.showQuote = false;

            this.$store.dispatch('quoteRequestCreate/resetQuoteRequest');
            this.selectedVehicleShow = false;

            this.loadedVehicle = undefined;
            this.manualVehicleEntry = false;
            this.acceptWarranty = false;
            this.searchTerm = null;
            this.vehicles = [];
            this.selectedVehicle = undefined;
            this.isPolicyQuote = false;

            //this.policyChecked = false;
            //this.serviceChecked = false;
            //this.repairChecked = false;
            //this.inspectionChecked = false;

            this.quoteType = undefined;

            this.noSearch = false;
            this.searchInitialized = true;
            if (this.myGarageVehicles && this.myGarageVehicles.length > 0) {
                this.showGarage = true;
            }
            else {
                this.showSearch = true;
            }

            this.selectedGarageVehicle = undefined;
            this.resetFormDialogShow = false;

            this.$vuetify.goTo(0);
            setTimeout(() => {
                // Slight delay, then scroll to top again, to account for any page amimations
                this.$vuetify.goTo(0);
                this.resetFormDialogShow = false;
            }, 500);

            this.$emit('searchCleared');
        },
        selectedVehicleChange() {
            if (this.selectedVehicle) {
                this.selectedVehicleShow = true;
            }
            else {
                this.selectedVehicleShow = false;
            }
        },
        async selectedGarageVehicleChange() {
            this.showServiceSchedule = false;
            if (this.selectedGarageVehicle) {
                this.selectedVehicleShow = true;
                if (
                    !this.selectedGarageVehicle.serviceSchedule
                    && this.selectedGarageVehicle.customerVehicleMappingMileageHistories
                    && this.selectedGarageVehicle.customerVehicleMappingMileageHistories
                        .length > 0
                        && !this.isOrganization
                ) {
                    let result = await userService.queryVehicleServiceSchedule(
                        this.selectedGarageVehicle.id,
                    );
                    if (result.status === 200) {
                        this.$nextTick(() => {
                            this.selectedGarageVehicle.serviceSchedule = result.data;
                        });
                    }
                }
            }
            else {
                this.selectedVehicleShow = false;
            }
        },

        async queryVehicleHistory() {
            this.vehicleHistoryLoading = true;
            let result = await userService.queryVehicleHistory(
                this.selectedGarageVehicle,
            );
            this.vehicleHistory = result.data;

            if (!this.vehicleHistory || this.vehicleHistory.length === 0) {
                this.quoteRequestType = 'Finance Recon 1';
            }
            else {
                let anyPrevious = _.some(this.vehicleHistory, function (vh) {
                    return vh.quoteRequestStatus !== quoteRequestStatuses.cancelled.id;
                });
                if (!anyPrevious) {
                    this.quoteRequestType = 'Finance Recon 1';
                }
                else {
                    this.quoteRequestType = 'Finance Recon 1';
                    this.financeReconOverride = true;
                }
            }

            this.vehicleHistoryLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/vuetify-elements/card.scss";

#flip-container {
  cursor: pointer;
}

#flip-container .expand-transition-enter-active,
#flip-container .expand-transition-leave-active {
  transition-duration: 1.2s !important;
}

.smc-my-reuqest-type-card .v-card__title {
  background-color: #ed3237;
  color: #fff;
  font-size: 15px;
  padding: 2px 15px;
}

.smc-my-reuqest-type-card.selected .v-card__title {
  font-size: 18px;
  padding: 8px 15px;
}

.smc-my-garage-card {
  width: 100%;
  /*max-width: 660px;*/
  margin-bottom: 15px;
  border-radius: 8px !important;
}

.smc-my-garage-card .v-card__title {
  background-color: #ed3237;
  color: #fff;
  font-size: 15px;
  padding: 2px 15px;
}

.smc-my-garage-card .v-card__text {
  padding-left: 5px;
  padding-bottom: 0;
}

.smc-my-garage-card .v-list-item__title {
  font-size: 15px;
}

.smc-my-garage-card .v-list-item__subtitle {
  font-size: 13px;
}

.smc-my-garage-list-item {
  padding: 0;
  padding-bottom: 8px;
  margin: 12px 0;
  border-left: 1px solid #ed3237;
  border-right: 1px solid #ed3237;
  border-bottom: 1px solid #ed3237;
  border-radius: 8px;
}

.smc-my-garage-list-item > .smc-my-garage-list-item-title {
  background-color: #ed3237;
  color: #fff;
  padding: 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.smc-my-garage-list-item .v-list-item__title,
.smc-my-garage-list-item .v-list-item__subtitle {
  padding: 0 12px;
}

.service-type-checked {
  border: 1px solid #ed3237;
}

.service-type-checked .v-list-item__content {
  font-weight: 500;
}

.quote-type-row .col {
  min-width: 325px;
  max-width: 500px;
}
</style>

<style>
@import "../assets/scss/components/vehicle-search.scss";

.roller {
  display: inline;
}

.roller .rollerBlock {
  padding: 0;
}

tr.border-info td {
  border-top: 1px solid #2196f3 !important;
  border-bottom: 1px solid #2196f3 !important;
  color: #2196f3;
}

tr.border-error td {
  border-top: 1px solid #ed3237 !important;
  border-bottom: 1px solid #ed3237 !important;
  color: #ed3237;
}
</style>
