const defaultCenterPosition = () => {
    return {
        lat: -28.859482,
        lng: 23.937505999999985,
    };
};

const defaultZoomLevel = () => {
    return 5;
};

export const mapHelper = {
    defaultCenterPosition,
    defaultZoomLevel,
};
