import { render, staticRenderFns } from "./vehicle-search-details-skeleton-loader.vue?vue&type=template&id=5c2b4b49&scoped=true&"
import script from "./vehicle-search-details-skeleton-loader.vue?vue&type=script&lang=js&"
export * from "./vehicle-search-details-skeleton-loader.vue?vue&type=script&lang=js&"
import style0 from "./vehicle-search-details-skeleton-loader.vue?vue&type=style&index=0&id=5c2b4b49&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2b4b49",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VRow,VSheet,VSkeletonLoader})
