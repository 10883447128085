import Address from '../models/address';
import _ from 'lodash';

const getEmptyAddress = () => {
    return new Address(
        0, // id
        '', // name
        '', // is active
        '', // street number
        '', // street name
        '', // suburb
        '', // city
        '', // province
        '', // country
        '', // postal code
        '', // lat
        '', // lng
        '', // date created
        false, // isOrganization
    );
};

const stringifyAddress = address => {
    return _.join([
        address.streetNumber,
        address.streetName,
        address.suburb,
        address.city,
        address.province,
        address.country,
        address.postalCode,
    ], ' ');
};

const paragraphyAddress = address => {
    return _.join([
        `${address.streetNumber} ${address.streetName}`,
        address.suburb,
        address.city,
        address.province,
        address.country,
        address.postalCode,
    ], '\n');
};

const objectifyAddress = addressString => {
    let addressParts = _.split(addressString, '~');
    return new Address(
        '', // id
        '', // name
        '', // is active
        addressParts[0], // street number
        addressParts[1], // street name
        addressParts[2], // suburb
        addressParts[3], // city
        addressParts[4], // province
        addressParts[5], // country
        addressParts[6], // postal code
        '', // lat
        '', // lng
        '', // date created
        false, // isOrganization
    );
};

const arrayiphyAddress = address => {
    return [
        `${address.streetNumber} ${address.streetName}`,
        address.suburb,
        address.city,
        address.province,
        address.country,
        address.postalCode,
    ];
};

const arrayiphyAddressBrief = address => {
    return [
        `${address.streetNumber ?? ''} ${address.streetName ?? ''}`,
        address.suburb ?? '',
        address.city ?? '',
        address.postalCode ?? '',
    ];
};

export const addressHelper = {
    getEmptyAddress,
    stringifyAddress,
    paragraphyAddress,
    objectifyAddress,
    arrayiphyAddress,
    arrayiphyAddressBrief,
};
