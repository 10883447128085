<template>
    <v-container>
        <v-card elevation="10" width="1185" class="mx-auto" rounded>
            <v-row>
                <v-col v-if="$vuetify.breakpoint.smAndUp" class="pl-0 py-0 grow">
                    <v-card>
                        <v-carousel light hide-delimiters cycle height="auto">
                            <v-carousel-item
                                v-for="(item, index) in reviews"
                                :key="index"
                                :href="url"
                                target="_blank"
                            >
                                <v-card-text>
                                    <p class="break-space mx-12">
                                        {{ item.review }}
                                    </p>
                                </v-card-text>
                                <v-card-subtitle class="pt-0">
                                    <v-rating
                                        color="amber"
                                        half-increments
                                        readonly
                                        dense
                                        :value="item.rating"
                                    />
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <p>{{ item.name }}</p>
                                </v-card-subtitle>
                            </v-carousel-item>
                        </v-carousel>
                    </v-card>
                </v-col>
                <v-col :class="$vuetify.breakpoint.smAndUp ? 'shrink pr-12' : ''">
                    <v-card flat :href="url" target="_blank">
                        <v-rating
                            color="amber"
                            half-increments
                            readonly
                            size="30"
                            dense
                            :value="rating"
                        />
                        <v-img
                            :src="require('@/assets/images/google-review.jpg')"
                            :lazy-src="require('@/assets/images/google-review.jpg')"
                            width="80"
                            class="mx-auto"
                        />
                        <p>{{ rating }} stars on Google</p>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'google-reviews',
    data: () => ({
        rating: 4.8,
        url: 'https://g.page/r/CWCgg6TujRHFEAg/review',
        reviews: [
            {
                name: 'Michael Botha',
                rating: 5,
                review:
          'So convenient and friendly. Everything was organized in a very swift and efficient way. Happy with the car service I received, and I will definitely use this service again',
            },
            {
                name: 'Mikhail Dearham',
                rating: 5,
                review:
          'Very quick and professional. They have top quality workshops as partners and would highly recommend the service',
            },
            {
                name: 'Sally Anne Vermaak',
                rating: 5,
                review:
          'Great friendly service, helped to accommodate my budget in this uncertain time. Will definitely be using them again.',
            },
            {
                name: 'Neil Varty',
                rating: 5,
                review:
          'Used it for the first time this week and it was unreal. Honestly effortless on my part and I received the cheapest service I\'ve ever had which was a major service. Unreal. This business deserves far more credit and recognition than it currently has.',
            },
            {
                name: 'Mologadi Kekana',
                rating: 5,
                review:
          'Service My Car helped me schedule a service and repair for my car. Their prompt response impressed me. My quotations were very competitive thanks to them. Eventually, Ken\'s Auto booked my car for repair and got it done promptly.\n\nAlso, I had the option of accepting or rejecting the repair quotes on the Service My Car platform.\n\nThanks to Service My Car, I was able to save a lot of time and money!',
            },
        ],
    }),
};
</script>

<style scoped>
.break-space {
  white-space: break-spaces;
}
</style>
