<template>
    <div>
        <v-autocomplete
            class="smc-global-search"
            :search-input.sync="searchTerm"
            :items="eventItems"
            hide-no-data
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            label="Search"
            :loading="loading"
            return-object
            hide-details
            no-filter
            outlined
            dense
            item-text="actionDescription"
            clearable
            :menu-props="{
                maxHeight: 1000,
            }"
        >
            <template #item="data">
                <v-card class="global-search-card" rounded elevation="2" outlined @click="openAction(data.item)">
                    <v-card-title>
                        <v-chip-group column>
                            <v-chip
                                v-if="data.item.actionDescription"
                                :color="recentActionSourceParams(data.item.actionSource).color"
                                :class="`${recentActionSourceParams(data.item.actionSource).text}--text`"
                            >
                                {{ data.item.actionDescription }}
                            </v-chip>
                            <v-chip v-if="data.item.vehicleName" outlined color="primary">
                                {{ data.item.vehicleName }}
                            </v-chip>
                            <v-chip outlined color="secondary">
                                {{ dateHelper.formatDateLongHours(data.item.actionDate) }}
                            </v-chip>
                        </v-chip-group>
                    </v-card-title>
                    <v-card-text class="text-left">
                        <v-row>
                            <v-col>
                                <p>
                                    {{ data.item.vehicleSummary }}
                                </p>
                                <h3>
                                    <strong>{{ data.item.workshopName }}</strong>
                                </h3>
                            </v-col>
                            <v-col class="shrink pr-1">
                                <img
                                    class="smc-search-image mr-0"
                                    :src="data.item.vehicleImageUrl"
                                    style="
                                width: 180px;
                                margin-right: 15px;
                                border-radius: 15px;
                            "
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions class="text-right">
                        <v-row>
                            <v-col>
                                <v-chip
                                    :color="recentActionSourceParams(data.item.actionSource).color"
                                    :class="`${recentActionSourceParams(data.item.actionSource).text
                                    }--text`"
                                >
                                    {{ recentActionSourceParams(data.item.actionSource).label }}
                                </v-chip>
                                <v-chip class="ml-2" color="black" outlined>
                                    {{ recentActionTypes(data.item.actionType) }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import debounce from 'debounce';
import { userService } from '@/services';
import { dateHelper, recentActionSources, recentActionTypes } from '@/helpers';

export default {
    name: 'global-search',
    data: () => ({
        dateHelper,
        loading: false,
        scrollDisabled: false,
        searchTerm: '',
        eventItems: [],
    }),
    watch: {
        searchTerm: async function (newVal) {
            if (newVal && newVal.length >= 3) {
                await this.searchEventItems();
            }
            else {
                this.eventItems = [];
            }
        },
    },
    created() {
        this.searchEventItems = debounce(this.searchEventItems, 800);
    },
    methods: {
        async searchEventItems() {
            this.loading = true;
            let result = await userService.queryUserRecentActions(1, this.searchTerm);
            if (result.data && result.data.length > 0) {
                this.eventItems.push(...result.data);
            }
            else {
                this.scrollDisabled = true;
            }
            this.loading = false;
        },
        openAction(item) {
            if (item.actionSource === 0) {
                let path = '/new-quote';
                if (this.$route.name === path) {
                    path = `${path}?t=123`;
                }

                this.$router.push({
                    name: 'new-quote',
                    params: { vehicleMappingIdProp: item.navigationId },
                });
            }
            else {
                this.$router.push(`/quotes/${item.navigationId}`);
            }
            this.isOpen = false;
        },
        recentActionSourceParams(index) {
            return recentActionSources[index];
        },
        recentActionTypes(index) {
            return recentActionTypes[index];
        },
    },
};
</script>

<style>
.global-search-card {
    width: 100%;
    margin-bottom: 15px;
}
</style>
