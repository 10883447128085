export default class RegisterInvite {
    constructor(
        firstName,
        lastName,
        email,
        emailReadonly,
        phoneNumber,
        phoneNumberReadonly,
        invalidInvite,
        invalidInviteError,
        requiresLogin,
        hasPasswordLogin,
        hasGoogleLogin,
        requiresConsent,
        policy,
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.emailReadonly = emailReadonly;
        this.phoneNumber = phoneNumber;
        this.phoneNumberReadonly = phoneNumberReadonly;
        this.invalidInvite = invalidInvite;
        this.invalidInviteError = invalidInviteError;
        this.requiresLogin = requiresLogin;
        this.hasPasswordLogin = hasPasswordLogin;
        this.hasGoogleLogin = hasGoogleLogin;
        this.requiresConsent = requiresConsent;
        this.policy = policy;
    }
}
