<template>
    <v-stepper-content step="3" class="step-3-content">
        <validation-observer ref="step3formValidationObserver">
            <v-form v-model="step3Valid" lazy-validation ref="step3form">
                <v-card flat>
                    <div class="pt-1 mt-1"></div>
                    <v-btn
                        v-if="isOrganization && quoteType !== quoteTypes.wbcInspectionLabel"
                        rounded
                        color="red"
                        class="white--text"
                        @click="showWorkshopList = !showWorkshopList"
                    >
                        {{ showWorkshopList ? 'Switch to map' : 'Switch to workshop list' }}
                    </v-btn>
                    <v-card-title v-if="!showWorkshopList" class="smc-card-reduced-heading primary white--text mb-4 mt-4">
                        What's your starting address?
                    </v-card-title>
                    <v-card-subtitle class="pt-3" v-if="!showWorkshopList">
                        This address will be your start location - all workshops selected
                        will be closest to this point.
                    </v-card-subtitle>
                    <v-card-text :class="this.$vuetify.breakpoint.smAndDown ? 'px-0' : ''">
                        <v-row v-if="!showWorkshopList">
                            <v-col>
                                <validation-provider v-slot="{ errors }" name="Search address" rules="required">
                                    <v-autocomplete
                                        id="createQuote-searchAddress"
                                        v-model="autocompleteLocationModel"
                                        :error-messages="errors"
                                        label="Search address"
                                        class="smc-address-search"
                                        ref="createQuoteSearchAddress"
                                        @change.self="setPlace()"
                                        :items="locationFoundItems"
                                        :search-input.sync="locationSearchText"
                                        hint="Start typing to search for an address"
                                        outlined
                                        :loading="mapIsLoading"
                                        prepend-inner-icon="mdi-google-maps"
                                        append-icon="mdi-magnify"
                                        item-text="value"
                                        item-value="id"
                                        no-filter
                                        clearable
                                        hide-no-data
                                        return-object
                                    >
                                        <template #item="data">
                                            <v-list-item-avatar>
                                                <v-icon>mdi-map-marker</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title style="text-align: left">
                                                    {{ data.item.value }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-card raised elevation="6" :class="showWorkshopList ? 'd-none' : ''">
                            <v-card-title> Address </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-scroll-x-transition>
                                        <v-col cols="12" md="4" v-if="place !== null">
                                            <v-text-field
                                                id="createQuote-addressStreetNumber"
                                                v-model="quoteRequest.address.streetNumber"
                                                label="Street number"
                                                outlined
                                                rounded
                                                prepend-inner-icon="mdi-home-floor-1"
                                                readonly
                                                :hint="quoteRequest.regionIds.length === 0
                                                    ? 'Please search for an address above'
                                                    : ''
                                                "
                                            />
                                            <v-text-field
                                                id="createQuote-addressStreetName"
                                                v-model="quoteRequest.address.streetName"
                                                label="Street name"
                                                outlined
                                                rounded
                                                prepend-inner-icon="mdi-home"
                                                readonly
                                                :hint="quoteRequest.regionIds.length === 0
                                                    ? 'Please search for an address above'
                                                    : ''
                                                "
                                            />
                                            <v-text-field
                                                id="createQuote-addressSuburb"
                                                v-model="quoteRequest.address.suburb"
                                                label="Suburb"
                                                outlined
                                                rounded
                                                prepend-inner-icon="mdi-home-group"
                                                readonly
                                                :hint="quoteRequest.regionIds.length === 0
                                                    ? 'Please search for an address above'
                                                    : ''
                                                "
                                            />
                                            <v-text-field
                                                id="createQuote-addressCity"
                                                v-model="quoteRequest.address.city"
                                                label="City"
                                                outlined
                                                rounded
                                                prepend-inner-icon="mdi-city"
                                                readonly
                                                :hint="quoteRequest.regionIds.length === 0
                                                    ? 'Please search for an address above'
                                                    : ''
                                                "
                                            />
                                            <v-text-field
                                                id="createQuote-addressProvince"
                                                v-model="quoteRequest.address.province"
                                                label="Province"
                                                outlined
                                                rounded
                                                prepend-inner-icon="mdi-map-search"
                                                readonly
                                                :hint="quoteRequest.regionIds.length === 0
                                                    ? 'Please search for an address above'
                                                    : ''
                                                "
                                            />
                                            <v-text-field
                                                id="createQuote-addressCountry"
                                                v-model="quoteRequest.address.country"
                                                label="Country"
                                                outlined
                                                rounded
                                                prepend-inner-icon="mdi-flag"
                                                readonly
                                                :hint="quoteRequest.regionIds.length === 0
                                                    ? 'Please search for an address above'
                                                    : ''
                                                "
                                            />
                                            <v-text-field
                                                id="createQuote-addressPostalCode"
                                                v-model="quoteRequest.address.postalCode"
                                                label="Postal code"
                                                outlined
                                                rounded
                                                prepend-inner-icon="mdi-numeric-1-box-multiple-outline"
                                                readonly
                                                :hint="quoteRequest.regionIds.length === 0
                                                    ? 'Please search for an address above'
                                                    : ''
                                                "
                                            />

                                            <!--
                      <validation-provider
                        v-slot="{ errors }"
                        name="Workshops count"
                        rules="required|min_value:3"
                      >
                        <v-text-field
                          id="createQuote-addressWorkshopsCount"
                          v-model="quoteRequestWorkshopMappingsCount"
                          :error-messages="errors"
                          label="Workshops count"
                          outlined
                          rounded
                          prepend-inner-icon="mdi-store-marker"
                          readonly
                        />
                      </validation-provider>
                      -->
                                        </v-col>
                                    </v-scroll-x-transition>
                                    <v-col :class="$vuetify.breakpoint.smAndUp ? 'pt-0' : ''">
                                        <validation-provider v-if="place !== null" v-slot="{ errors }" name="Travel distance" rules="required">
                                            <v-slider
                                                id="createQuote-addressDistance"
                                                :class="$vuetify.breakpoint.smAndUp ? 'mb-n2' : ''"
                                                v-model="quoteRequest.travelDistance"
                                                @change.self="
                                                    generateBounds();
                                                    calculateWorkshopMappings();
                                                "
                                                :error-messages="errors"
                                                thumb-label="always"
                                                :label="$vuetify.breakpoint.smAndUp ? 'How far are you willing to travel?' : ''"
                                                :hint="$vuetify.breakpoint.smAndUp ? '' : 'How far are you willing to travel?'"
                                                persistent-hint
                                                :thumb-size="42"
                                                thumb-color="secondary"
                                                step="5"
                                                :min="5"
                                                :max="50"
                                                dense
                                            >
                                                <template #thumb-label="{ value }">
                                                    {{ `${value} KM` }}
                                                </template>
                                            </v-slider>
                                        </validation-provider>
                                        <gmap-map
                                            style="width: 100%"
                                            :style="$vuetify.breakpoint.smAndUp
                                                ? 'height: 575px'
                                                : 'height: 250px'
                                            "
                                            ref="gmap"
                                            :zoom="zoom"
                                            :center="centerPosition"
                                            @zoom_changed="mapZoomChange"
                                        >
                                            <gmap-marker
                                                v-if="this.place"
                                                :animation="4"
                                                :position="{
                                                    lat: getValue(this.place.address.lat),
                                                    lng: getValue(this.place.address.lng),
                                                }"
                                            />
                                            <gmap-marker
                                                v-for="(workshopAddress, index) in workshopAddresses"
                                                :key="index"
                                                :animation="4"
                                                :position="workshopAddress"
                                                :clickable="true"
                                                :draggable="false"
                                                :icon="getWorkshopMarkerIcon(workshopAddress)"
                                                @click="toggleExtraWorkshopAddress(workshopAddress)"
                                                @mouseover="toggleWorkshopMarkerHover(workshopAddress);"
                                                @mouseout="workshopAddress.infoIsOpen = false;"
                                            >
                                                <gmap-info-window :opened="workshopAddress.infoIsOpen">
                                                    <div>
                                                        <workshop-address-info
                                                            :loadProp="workshopAddress.infoIsOpen"
                                                            :workshopIdProp="workshopAddress.workshopId"
                                                        />
                                                    </div>
                                                </gmap-info-window>
                                            </gmap-marker>
                                            <!-- inserting an empty polygon, since the geometry api is not loaded on the page if there is none -->
                                            <gmap-polygon :editable="false" :visible="false" />
                                            <gmap-circle
                                                v-if="place && quoteRequest.travelDistance"
                                                :radius="quoteRequest.travelDistance * 1000"
                                                :center="{
                                                    lat: getValue(this.place.address.lat),
                                                    lng: getValue(this.place.address.lng),
                                                }"
                                            />
                                        </gmap-map>
                                        <h3 class="mt-3">
                                            Number of selected workshops: 
                                            <v-chip color="#6dae57" class="white--text">
                                                {{ quoteRequestWorkshopMappingsCount }}
                                            </v-chip>
                                        </h3>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-overlay absolute opacity="0.8" :value="mapIsLoading">
                                <v-progress-circular indeterminate color="primary" size="64" width="6" />
                            </v-overlay>
                        </v-card>
            
                        <v-card v-if="showWorkshopList" class="messages-inbox-card smc-card-dialog">
                            <v-card-title class="smc-card-dialog-title-inset primary white--text px-5" style="width: 100%">
                                <span v-if="organizationBranch">Select workshops for <strong
                                    class="nowrap"
                                    style="word-break: keep-all;"
                                >{{ organizationBranch.branchName }}</strong> </span>
                                <span v-else-if="organization">Select workshops for <strong
                                    class="nowrap"
                                    style="word-break: keep-all;"
                                >{{ organization.name }}</strong></span>
                                <span v-else> Workshops </span>
                                <div class="ml-auto">
                                    <v-img
                                        :src="require('@/assets/images/wbc-logo.png')"
                                        :lazy-src="require('@/assets/images/wbc-logo.png')"
                                        contain
                                        height="50"
                                        width="50"
                                    />
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field
                                    v-model="workshopFilter"
                                    class="my-3"
                                    prepend-inner-icon="mdi-magnify"
                                    placeholder="Search workshop"
                                    hide-details
                                    outlined
                                    dense
                                />

                                <v-list v-if="filteredOrganizationWorkshopList">
                                    <v-list-item-group 
                                        v-if="$vuetify.breakpoint.mdAndUp" 
                                        v-model="workshopsSelected" 
                                        :multiple="!isInspectionType()"
                                        active-class="red--text"
                                    >
                                        <template v-for="(workshopAddress, index) in filteredOrganizationWorkshopList">
                                            <v-list-item :key="`d_${index}`">
                                                <template #default="{ active }">
                                                    <v-list-item-avatar horizontal size="80" tile class="ml-n1">
                                                        <v-img 
                                                            :src="workshopAddress.workshopLogoUrl" 
                                                            :lazy-src="workshopAddress.workshopLogoUrl" 
                                                            contain
                                                        />
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="text-left">
                                                        <v-list-item-title class="font-weight-bold">
                                                            <text-highlight
                                                                :queries="workshopFilter"
                                                                highlightClass="search-highlight"
                                                            >
                                                                {{ workshopAddress.name }}
                                                            </text-highlight>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle class="text--primary">
                                                            <v-badge v-if="workshopAddress.distance" inline :content="`${workshopAddress.distance.toFixed(1)} KM`" color="info">
                                                                {{ workshopAddress.suburb }}
                                                            </v-badge>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-list-item-action-text>
                                                            <v-badge inline :content="workshopAddress.referenceNumber">Jobs in Progress</v-badge>
                                                        </v-list-item-action-text>
                                                        <v-icon v-if="!active" color="grey lighten-1">
                                                            mdi-checkbox-blank-outline
                                                        </v-icon>
                                                        <v-icon v-else color="green darken-3">
                                                            mdi-checkbox-marked
                                                        </v-icon>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                            <v-divider
                                                v-if="index < filteredOrganizationWorkshopList.length - 1"
                                                :key="`line_${index}`"
                                            />
                                        </template>
                                    </v-list-item-group>
                                    <v-list-item-group
                                        v-model="workshopsSelected"
                                        active-class="red--text"
                                        v-if="$vuetify.breakpoint.smAndDown"
                                        multiple
                                    >
                                        <template v-for="(workshopAddress) in filteredOrganizationWorkshopList">
                                            <v-list-item :key="`m_${workshopAddress.workshopId}`" class="pa-0">
                                                <template #default="{ active }">
                                                    <v-card elevation="1" width="100%" class="ma-2">
                                                        <v-icon v-if="!active" color="grey lighten-1" style="position: absolute; right: 15px; top: 15px;">
                                                            mdi-checkbox-blank-outline
                                                        </v-icon>
                                                        <v-icon v-else color="green darken-3" style="position: absolute; right: 15px; top: 15px;">
                                                            mdi-checkbox-marked
                                                        </v-icon>

                                                        <v-list-item-avatar horizontal size="80" tile>
                                                            <v-img
                                                                :src="workshopAddress.workshopLogoUrl"
                                                                :lazy-src="workshopAddress.workshopLogoUrl"
                                                                contain
                                                            />
                                                        </v-list-item-avatar>

                                                        <v-list-item-content>
                                                            <v-list-item-title class="font-weight-bold">
                                                                {{ workshopAddress.name }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle class="text--primary">
                                                                <v-badge inline :content="`${workshopAddress.distance.toFixed(1)} KM`" color="info">
                                                                    {{ workshopAddress.suburb }}
                                                                </v-badge>
                                                            </v-list-item-subtitle>
                                                            <v-badge inline :content="workshopAddress.referenceNumber">Jobs in Progress</v-badge>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-list-item-action-text />
                                                        </v-list-item-action>
                                                    </v-card>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                                </v-list>

                                <v-btn
                                    color="primary"
                                    rounded
                                    v-if="filteredOrganizationWorkshopList.length < organizationWorkshopList.length"
                                    @click="organizationWorkshopPage++;"
                                >
                                    Load More
                                </v-btn>
                            </v-card-text>
                        </v-card>

                        <v-card-title
                            class="
                smc-card-reduced-heading
                white
                darkGrey--text
                my-4
                text-left
              "
                            style="display: block"
                        >
                            On which days are you available?
                            <v-tooltip max-width="500" bottom>
                                <template #activator="{ on }">
                                    <v-hover v-slot="{ hover }">
                                        <v-icon v-on="on" :color="hover ? 'primary' : 'secondary'" class="ml-2">
                                            mdi-information
                                        </v-icon>
                                    </v-hover>
                                </template>
                                <span>
                                    You can select multiple dates within a 2 week period that you
                                    would be available to have your car booked in for a service or
                                    repair. Each workshop will select one of these dates when
                                    submitting their responses.
                                </span>
                            </v-tooltip>
                        </v-card-title>

                        <v-card class="mb-12">
                            <v-card-text class="pa-0">
                                <v-row>
                                    <v-col class="py-0 grow" style="min-width: 330px;">
                                        <v-date-picker
                                            v-model="quoteRequest.datesRequested"
                                            :style="$vuetify.breakpoint.smAndUp ? 'border-right: 2px solid #9e9e9e' : ''"
                                            :min="minDate"
                                            :max="twoWeeks"
                                            full-width
                                            multiple
                                            color="secondary"
                                            header-color="primary"
                                            :landscape="$vuetify.breakpoint.mdAndUp"
                                            :allowed-dates="weekDays"
                                        />
                                    </v-col>
                                    <v-col class="text-right shrink mx-auto">
                                        <v-btn
                                            outlined
                                            color="primary"
                                            rounded
                                            class="mr-4 mb-2"
                                            width="130"
                                            @click="selectAllDate"
                                        >
                                            I'm Flexible
                                        </v-btn>
                                        <br />
                                        <v-btn
                                            outlined
                                            color="primary"
                                            rounded
                                            class="mr-4"
                                            width="130"
                                            @click="quoteRequest.datesRequested = []"
                                        >
                                            Clear
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pb-0">
                                        <v-expand-transition>
                                            <v-alert v-if="noDatesError" class="mb-0" border="left" type="error">
                                                Please select a date
                                            </v-alert>
                                        </v-expand-transition>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-textarea
                            id="createQuote-notes"
                            label="Notes"
                            outlined
                            rounded
                            v-model="quoteRequest.notes"
                            rows="3"
                            auto-grow
                            hint="Any additional notes you want to leave for the workshop."
                            prepend-inner-icon="mdi-pencil-box-outline"
                            maxLength="500"
                            counter="500"
                        />

                        <v-card flat>
                            <v-card-title
                                class="smc-card-reduced-heading primary white--text mb-4"
                            >
                                Attachments
                                <v-btn 
                                    style="position: absolute; right: 5px;"
                                    color="secondary"
                                    @click="addAttachment"
                                    small 
                                    rounded 
                                >
                                    Add attachment
                                </v-btn>
                            </v-card-title>
                            <v-card-subtitle class="pt-3">
                                You can attach files such as photos or PDFs to send to the workshop.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-sheet v-for="(attachment, index) in quoteRequest.attachments" :key="index">
                                    <v-row>
                                        <v-col>
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Attachment"
                                                id="noteAttachment"
                                                rules="required|ext:jpeg,jpg,gif,png,svg,pdf|size:8000"
                                            >
                                                <v-file-input
                                                    :id="`createQuote-attachment-${index}`"
                                                    v-model="attachment.file"
                                                    :error-messages="errors"
                                                    label="Attachment"
                                                    prepend-inner-icon="mdi-paperclip"
                                                    prepend-icon
                                                    outlined
                                                    rounded
                                                    counter
                                                    show-size
                                                    accept="image/*,application/pdf"
                                                >
                                                    <!-- TODO fix lint error -->
                                                    <!-- eslint-disable vue/no-template-shadow -->
                                                    <template #selection="{ index, text }">
                                                        <v-chip
                                                            v-if="index < 2"
                                                            color="primary accent-4"
                                                            dark
                                                            label
                                                            small
                                                        >
                                                            {{ text }}
                                                        </v-chip>
                                                        <span
                                                            v-else-if="index === 2"
                                                            class="overline grey--text text--darken-3 mx-2"
                                                        >
                                                            +{{ files.length - 2 }} File(s)
                                                        </span>
                                                    </template>
                                                </v-file-input>
                                            </validation-provider>
                                        </v-col>
                                        <v-col class="shrink pt-5">
                                            <v-btn fab small color="red" @click="deleteAttachment(attachment)">
                                                <v-icon class="white--text">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-card-text>
                        </v-card>

                        <v-alert
                            v-if="showWorkshopList && (workshopsSelected === null || workshopsSelected === undefined || workshopsSelected.length === 0)"
                            border="left"
                            colored-border
                            type="error"
                            elevation="2"
                        >
                            Please add at least 1 workshop.
                        </v-alert>

                        <v-row>
                            <v-col>
                                <v-btn
                                    id="createQuote-step3Back"
                                    rounded
                                    color="secondary"
                                    class="smc-text-button smc-medium-button mx-1 my-1"
                                    @click="goStep(2)"
                                >
                                    Back
                                </v-btn>
                                <v-btn
                                    id="createQuote-step3Next"
                                    rounded
                                    color="primary"
                                    class="smc-text-button smc-medium-button my-1"
                                    @click="stepForward(4)"
                                    :disabled="!step3Valid"
                                >
                                    Next
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-form>
        </validation-observer>

        <v-dialog v-model="mapNoWorkshopsShow" width="550" persistent>
            <v-card>
                <v-toolbar color="primary" dark height="48" class="mb-6">
                    Please note
                </v-toolbar>
                <v-card-text v-if="maxWorkshopMappingsCount === 0 && quoteType === quoteTypes.igsPolicyLabel" class="text-left">
                    It seems we do not have any workshops around the area you have
                    selected, please try a different address or click here for a list of
                    Innovation Group approved repairers:
                    <br />
                    <br />
                    <v-btn rounded outlined color="primary" :href="innovationWorkshopList" target="_blank">
                        Innovation Workshops
                    </v-btn>
                </v-card-text>
                <v-card-text v-else-if="maxWorkshopMappingsCount === 0" class="text-left">
                    <p>
                        It seems we don't have any workshops around the area you selected
                        for your address. Please try a different address.
                    </p>
                </v-card-text>
                <v-card-text v-else class="text-left">
                    <p>
                        Please extend your radius to enable us to increase the number of
                        participating workshops. You will still have the freedom of choice
                        upon receipt of the workshop responses.
                    </p>
                    <div v-if="quoteType === quoteTypes.igsPolicyLabel">
                        <p>
                            You can also view a list of Innovation Group approved workshops
                            here:
                        </p>
                        <v-btn rounded outlined color="primary" :href="innovationWorkshopList" target="_blank">
                            Innovation Workshops
                        </v-btn>
                    </div>
                </v-card-text>
                <v-divider />
                <v-card-actions v-if="maxWorkshopMappingsCount === 0">
                    <v-spacer />
                    <v-btn class="px-4" rounded color="primary" @click="mapNoWorkshopsShow = false">
                        Close
                    </v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                    <v-spacer />
                    <v-btn
                        rounded
                        class="px-3"
                        color="primary"
                        @click="
                            quoteRequest.travelDistance = maxTravelDistance;
                            generateBounds();
                            calculateWorkshopMappings();
                            mapNoWorkshopsShow = false;
                        "
                    >
                        Expand Radius
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-stepper-content>
</template>

<script>
import workshopAddressInfo from '@/components/widgets/workshop-address-info';
import textHighlight from 'vue-text-highlight';
import quoteRequestCreate from '@/mixins/quote-request-create';
import validate from '@/mixins/validator';
import { dateHelper, quoteTypes } from '@/helpers';

export default {
    name: 'create-step-3',
    mixins: [quoteRequestCreate, validate],
    components: {
        textHighlight,
        workshopAddressInfo,
    },
    data: () => ({
        quoteTypes,
    }),
    computed: {
        innovationWorkshopList() {
            return process.env.VUE_APP_INNOVATION_WORKSHOP_LIST;
        },
    },
    watch: {
        'quoteRequest.datesRequested'() {
            this.noDatesError = false;
        },
    },
    methods: {
        isInspectionType() {
            return this.quoteType.includes(quoteTypes.wbcInspectionLabel);
        },
        getValue(val) {
            if (typeof val === 'function') {
                return val();
            }
            else {
                return val;
            }
        },
        initialize() {
            if (!this.quoteRequest.travelDistance) {
                setTimeout(() => {
                    this.quoteRequest.travelDistance = 10;
                    this.generateBounds();
                    this.calculateWorkshopMappings();
                }, 1000);
            }
        },
        selectAllDate() {
            this.quoteRequest.datesRequested = [];
            let datesArray = dateHelper.getDaysBetweenDates(
                this.minDate,
                this.twoWeeks,
            );

            datesArray.forEach(date => {
                let formattedDate = dateHelper.formatDateCustom(date, 'YYYY-MM-DD');
                if (this.weekDays(formattedDate)) {
                    this.quoteRequest.datesRequested.push(formattedDate);
                }
            });
        },
    },
};
</script>

<style>
@media only screen and (max-width: 420px) {
  .step-3-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.gm-style .gm-style-iw-c {
  padding: 0;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-ui-hover-effect {
  display: none !important;
}
</style>
