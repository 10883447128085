export default class QuoteRequestEstimate {
    constructor(
        quoteRequestItemName,
        estimateFromPriceIncl,
        estimateToPriceIncl,
        estimateAveragePriceIncl,
    ) {
        this.quoteRequestItemName = quoteRequestItemName;
        this.estimateFromPriceIncl = estimateFromPriceIncl;
        this.estimateToPriceIncl = estimateToPriceIncl;
        this.estimateAveragePriceIncl = estimateAveragePriceIncl;
    }
}
