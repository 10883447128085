import Vue from 'vue';
import store from '../store';
import VueRouter from 'vue-router';
import { routes } from './routes';
import goTo from 'vuetify/es5/services/goto';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        let scrollTo = 0;
        if (to.hash) {
            scrollTo = to.hash;
        }
        else if (savedPosition) {
            scrollTo = savedPosition.y;
        }
        return goTo(scrollTo);
    },
});

router.beforeEach((to, from, next) => {
    store.dispatch('contactSpeedDial/clear');
    let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    let requiresOrg = to.matched.some(record => record.meta.requiresOrg);

    if (requiresAuth) {
        let isAuthenticated = store.getters['user/isAuthenticated'];
        if (isAuthenticated) {
            if (requiresOrg && !store.getters['user/isOrganization']) {
                next({
                    path: `/account/unauthorized/${encodeURIComponent(to.fullPath)}`,
                });
            }
      
            next();
        }
        else {
            next({
                path: '/account/login',
                query: { returnUrl: to.fullPath },
            });
        }
    }
    next();
});

export default router;
