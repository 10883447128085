import api from './api';

const submitContact = async contact => {
    try {
        let result = await api.post('/api/contact/post', contact);

        return result;
    }
    catch (error) {
        return error;
    }
};

export const contactService = { submitContact };
