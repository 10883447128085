import { 
    required,
    email,
    numeric,
    min,
    max,
    min_value,
    max_value,
    ext,
    image,
    size,
} from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
import { validation } from '@/helpers';

/*
Aggressive: Triggered when the user presses a key (on input).
Passive: Triggered when the form is submitted.
Lazy: Triggered when the user leaves the input (on blur or change).
Eager: Is a combination of aggressive and lazy, as it first validates when the user leaves the input (on blur or change) then if the input is invalid it will behave aggressively until the input is valid again and it will go back to being lazy.
*/
setInteractionMode('eager');

extend('required', {
    ...required,
    message: 'Cannot be empty.',
});

extend('anyRequired', {
    params: ['target'],
    validate(value, { target }) {
        if (value || target) {
            return true;
        }
        else {
            return false;
        }
    },
    message: 'Please enter a vin number or provide a photo of your license disk.',
    computesRequired: true,
});

extend('arrayRequired', {
    validate(value) {
        return validation.validation_rules.arrayRequired(value);
    },
    computesRequired: true,
});

extend('email', {
    ...email,
    message: '{_field_} must be a valid email address.',
});

extend('phone', value => {
    return validation.validation_rules.phone(value);
});

extend('password', value => {
    return validation.validation_rules.password(value);
});

extend('confirmedBy', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: '{_field_} must match {target}.',
});

extend('numeric', {
    ...numeric,
    message: '{_field_} can only contain numeric characters.',
});

extend('min', {
    ...min,
    params: ['length'],
    message: '{_field_} must be at least {length} characters.',
});

extend('max', {
    ...max,
    params: ['length'],
    message: '{_field_} cannot exceed {length} characters.',
});

extend('length', {
    params: ['length'],
    validate(value, { length }) {
        return value.length === length;
    },
    message: '{_field_} must be {length} characters.',
});

extend('min_value', {
    ...min_value,
    params: ['min'],
    message: '{_field_} must be greater than {min}.',
});

extend('max_value', {
    ...max_value,
    params: ['max'],
    message: '{_field_} must be less than {max}.',
});

extend('image', {
    ...image,
    message: '{_field_} must be an image.',
});

extend('ext', {
    ...ext,
    message: '{_field_} must be a PDF.',
});

extend('size', {
    ...size,
    params: ['size'],
    message: (fieldName, placeholders) => {
        return `${fieldName} must be less than ${placeholders.size / 1000} MB.`;
    },
});

extend('vehicle_required', {
    validate(value) {
        return value && value.uniqueId && value.uniqueId.length === 36;
    },
    message: '{_field_} is required.',
});

extend('force_value', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
});

extend('vin', value => {
    return validation.validation_rules.vin(value);
});

export default {
    name: 'validator',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    methods: {
        validateVin(vin) {
            return validation.validate_vin(vin);
        },
    },
};
