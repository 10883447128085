<template>
    <span>
        <v-tooltip v-model="show" bottom>
            <template #activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon color="grey lighten-1">{{ toopltipIcon }}</v-icon>
                </v-btn>
            </template>
            <v-img
                :src="tooltipImage"
                :lazy-src="tooltipImage"
                max-height="600px"
                max-width="600px"
                contain
            />
        </v-tooltip>
    </span>
</template>

<script>
export default {
    name: 'image-tooltip',
    props: {
        imageNameProp: String,
        tooltipIconProp: String,
    },
    data: function () {
        return {
            show: false,
        };
    },
    computed: {
        tooltipImage() {
            if (this.isExternalImageProp) {
                return this.imageNameProp;
            }
            return require(`@/assets/images/${this.imageNameProp}`);
        },
        toopltipIcon() {
            return this.tooltipIconProp ? this.tooltipIconProp : 'mdi-information';
        },
    },
};
</script>
