import home from '../views/home.vue';
import newQuote from '../views/new-quote.vue';
import pageNotFound from '../views/page-not-found';

import store from '../store';

export const routes = [
    //{
    //  path: "/",
    //  name: "home",
    //  component: home
    //},
    {
        path: '/',
        name: 'home',
        component: () =>
            import(/* webpackChunkName: "quote" */ '../views/active-requests.vue'),
        props: true,
        meta: {
            isOrganization: true,
        },
        beforeEnter: (to, from, next) => {
            const isOrganization = store.getters['user/isOrganization'];
            if (isOrganization) {
                next();
            }
            else {
                next({ name: 'homePrecise' });
            }
        },
    },
    {
        path: '/home',
        name: 'homePrecise',
        component: home,
    },
    {
        path: '/new-quote/:vehicleMappingIdProp?',
        name: 'new-quote',
        component: newQuote,
        props: true,
    },
    {
        path: '/quote-estimate',
        name: 'quote-estimate',
        component: () => import(/* webpackChunkName: "quote" */ '../views/quote-estimate.vue'),
    },
    {
        path: '/workshops/:workshopName?',
        name: 'workshops',
        component: () => import(/* webpackChunkName: "about" */ '../views/workshops.vue'),
        props: true,
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '../views/account/index.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: { 
            requiresAuth: true,  
            requiresOrg: true,
        },
        component: () => import(/* webpackChunkName: "quote" */ '../views/dashboard.vue'),
    },
    {
        path: '/quote-requests/:vehicleId?',
        name: 'quote-requests',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "quote" */ '../views/quote-requests.vue'),
        props: true,
    },
    {
        path: '/active-requests',
        name: 'active-requests',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "quote" */ '../views/active-requests.vue'),
    },
    {
        path: '/quotes/:quoteRequestIdProp',
        name: 'quotes',
        component: () => import(/* webpackChunkName: "quote" */ '../views/quotes.vue'),
        props: true,
    },
    {
        path: '/my-garage/:customerVehicleMappingIdProp?',
        name: 'my-garage',
        meta: { requiresAuth: true },
        component: () => import(/*webpackChunkName: "account" */ '../views/my-garage.vue'),
        props: true,
    },
    {
        path: '/payment/success',
        name: 'status',
        component: () =>
            import(/* webpackChunkName: "quote" */ '../views/payment/success.vue'),
    },
    {
        path: '/account/signup',
        alias: '/account/register',
        name: 'signup',
        component: () => import(/* webpackChunkName: "account" */ '../views/account/signup.vue'),
    },
    {
        path: '/account/invite/:inviteIdProp?',
        alias: '/invite/:inviteIdProp?',
        name: 'invite',
        component: () => import(/* webpackChunkName: "account" */ '../views/account/invite.vue'),
        props: true,
    },
    {
        path: '/account/confirm-email/:paramsProp?',
        name: 'confirm-email',
        component: () => import(/* webpackChunkName: "account" */ '../views/account/confirm-email.vue'),
        props: true,
    },
    {
        path: '/account/login/:returnUrlProp?',
        name: 'login',
        props: route => ({ returnUrlProp: route.query.returnUrl }),
        component: () => import(/* webpackChunkName: "account" */ '../views/account/login.vue'),
    },
    {
        path: '/account/forgot-password/:returnUrlProp?',
        name: 'forgot-password',
        props: route => ({ returnUrlProp: route.query.returnUrl }),
        component: () => import(/* webpackChunkName: "account" */ '../views/account/forgot-password.vue'),
    },
    {
        path: '/account/forgot-password-confirm/:emailAddressProp?/:codeProp?',
        name: 'forgot-password-confirm',
        component: () => import(/* webpackChunkName: "account" */ '../views/account/forgot-password-confirm.vue'),
        props: true,
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
    },
    //{
    //  path: "/innovation-group",
    //  name: "innovation-group",
    //  // route level code-splitting
    //  // this generates a separate chunk (about.[hash].js) for this route
    //  // which is lazy-loaded when the route is visited.
    //  component: () => import(/* webpackChunkName: "about" */ "../views/innovation-group.vue")
    //},
    {
        path: '/faq',
        name: 'faq',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/faq.vue'),
    },
    {
        path: '/terms',
        name: 'terms',
        // route level code-splitting
        // this generates a separate chunk (terms.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/terms.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue'),
    },
    //{
    //  path: "/under-maintenance",
    //  name: "under-maintenance",
    //  component: () => import(/* webpackChunkName: "about" */ "../views/under-maintenance.vue")
    //},
    // defualt route to handle when nothing above matches
    {
        path: '*',
        name: 'page-not-found',
        component: pageNotFound,
    },
];
