import api from './api';
import { formFunctions } from '@/helpers';
import fileDownload from 'js-file-download';

const acceptQuote = async (quoteAccept, attachments) => {
    try {
        let formData = formFunctions.toFormData(quoteAccept);

        if (attachments && attachments.length > 0) {
            attachments.forEach(att => {
                if (att) {
                    formData.append('attachmentFiles', att);
                }
            });
        }

        let result = await api.post(
            '/api/quotes/AcceptQuote',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
        return result;
    }
    catch (error) {
        return error;
    }
};

const messageWorkshop = async (quoteAccept, attachments) => {
    try {
        let formData = formFunctions.toFormData(quoteAccept);

        if (attachments && attachments.length > 0) {
            attachments.forEach(att => {
                formData.append('attachmentFiles', att);
            });
        }

        let result = await api.post(
            '/api/quotes/MessageWorkshop',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
        return result;
    }
    catch (error) {
        return error;
    }
};

const cancelBooking = async quoteAccept => {
    try {
        let result = await api.post('/api/quotes/CancelQuoteBooking', quoteAccept);
        return result;
    }
    catch (error) {
        return error;
    }
};

const accpetQuoteAmendment = async (quoteAmendment, attachments) => {
    try {
        let formData = formFunctions.toFormData(quoteAmendment);

        if (attachments && attachments.length > 0) {
            attachments.forEach(att => {
                if (att) {
                    formData.append('attachmentFiles', att);
                }
            });
        }

        let result = await api.post(
            '/api/quotes/AcceptQuoteAmendment',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
        return result;
    }
    catch (error) {
        return error;
    }
};

const getCustomerVehicleActiveQuotes = async vehicleId => {
    try {
        let result = await api.get(`/api/quotes/GetCustomerVehicleActiveQuotes/${vehicleId}`);
        return result.data;
    }
    catch (error) {
        return error;
    }
};

const downloadQuoteMessageAttachment = async (uniqueId, attachmentName) => {
    try {
        let result = await api.get(`/api/Quotes/DownloadQuoteMessageAttachment/${uniqueId}?attachmentName=${attachmentName}`, {
            headers: {
                'Content-Disposition': 'attachment',
            },
            responseType: 'blob',
        });

        fileDownload(result.data, attachmentName, result.headers['content-type']);
    }
    catch (error) {
        return error;
    }
};

export const quoteService = {
    acceptQuote,
    messageWorkshop,
    cancelBooking,
    accpetQuoteAmendment,
    getCustomerVehicleActiveQuotes,
    downloadQuoteMessageAttachment,
};
