export default ({
    computed: {
        isProduction: function () {
            return process.env.NODE_ENV === 'production';
        },
    },
    methods: {
        trackEvent(action, category, label) {
            if (this.isProduction) {
                this.$gtag.event(action, {
                    event_category: category,
                    event_label: label,
                });
            }
        },
        trackPageView(path) {
            if (this.isProduction) {
                this.$gtag.pageview({
                    page_path: path,
                });
            }
        },
        reportConversion(reference) {
            if (this.isProduction) {
                this.$gtag.event('conversion', {
                    send_to: 'AW-622218389/YPSlCOqykfkBEJWZ2agC',
                    transaction_id: reference,
                });
                this.$gtag.event('conversion', {
                    send_to: 'AW-622218389/ZvKKCJ_wu9QBEJWZ2agC',
                    transaction_id: reference,
                });
            }
        },
    },
});
