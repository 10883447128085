export default class QuoteRequestOrganizationBranchShare {
    constructor(
        quoteId,
        workshopId,
        workshopName,
        workshopLogoUrl,
        dateCreatedUtc,
        dateCreated,
        username,
        isSuccess,
    ) {
        this.quoteId = quoteId;
        this.workshopId = workshopId;
        this.workshopName = workshopName;
        this.workshopLogoUrl = workshopLogoUrl;
        this.dateCreatedUtc = dateCreatedUtc;
        this.dateCreated = dateCreated;
        this.username = username;
        this.isSuccess = isSuccess;
    };
};
