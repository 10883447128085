<template>
    <v-sheet class="smc-sheet-transparent what-we-do-sheet">
        <v-row>
            <v-col
                cols="6"
                offset="3"
                md="3"
                offset-md="0"
                sm="4"
                offset-sm="2"
            >
                <v-card
                    shaped
                    class="what-we-do-card smc-card-transparent smc-card-rounded"
                >
                    <v-card-actions>
                        <v-img
                            :src="require('@/assets/images/what-we-do/01-Customer-Process.svg')"
                            :lazy-src="require('@/assets/images/what-we-do/01-Customer-Process.svg')"
                            :height="imageSize"
                            contain
                            eager
                        />
                    </v-card-actions>
                    <v-card-text>
                        Tell us what your vehicle needs, be it a service or repair.
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="6"
                offset="3"
                md="3"
                offset-md="0"
                sm="4"
                offset-sm="0"
            >
                <v-card
                    shaped
                    class="what-we-do-card smc-card-transparent smc-card-rounded"
                >
                    <v-card-actions>
                        <v-img
                            :src="require('@/assets/images/what-we-do/02-Customer-Process.svg')"
                            :lazy-src="require('@/assets/images/what-we-do/02-Customer-Process.svg')"
                            :height="imageSize"
                            contain
                            eager
                        />
                    </v-card-actions>
                    <v-card-text>
                        We will give you a list of quotations from our capable Workshop
                        Partners in your area.
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="6"
                offset="3"
                md="3"
                offset-md="0"
                sm="4"
                offset-sm="2"
            >
                <v-card
                    shaped
                    class="what-we-do-card smc-card-transparent smc-card-rounded"
                >
                    <v-card-actions>
                        <v-img
                            :src="require('@/assets/images/what-we-do/03-Customer-Process.svg')"
                            :lazy-src="require('@/assets/images/what-we-do/03-Customer-Process.svg')"
                            :height="imageSize"
                            contain
                            eager
                        />
                    </v-card-actions>
                    <v-card-text>
                        Choose the quotation that best suites your vehicle’s needs and we
                        will make the booking for you!
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="6"
                offset="3"
                md="3"
                offset-md="0"
                sm="4"
                offset-sm="0"
            >
                <v-card
                    shaped
                    class="what-we-do-card smc-card-transparent smc-card-rounded"
                >
                    <v-card-actions>
                        <v-img
                            :src="require('@/assets/images/what-we-do/04-Customer-Process.svg')"
                            :lazy-src="require('@/assets/images/what-we-do/04-Customer-Process.svg')"
                            :height="imageSize"
                            contain
                            eager
                        />
                    </v-card-actions>
                    <v-card-text>
                        When the work on your vehicle is done, pay securely online before
                        you collect your vehicle.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'what-we-do',
    computed: {
        imageSize: function () {
            if (this.$vuetify.breakpoint.lgAndUp) {
                return 255;
            }
            else if (this.$vuetify.breakpoint.mdOnly) {
                return 200;
            }
            else if (this.$vuetify.breakpoint.smAndDown) {
                return 180;
            }

            return 255;
        },
    },
};
</script>

<style scoped>

.what-we-do-sheet {
  max-width: 1185px;
  margin-left: auto;
  margin-right: auto;
}

.what-we-do-sheet .what-we-do-card {
  box-shadow: none;
}

.what-we-do-card > .v-card__text {
  display: flex;
  align-items: top;
}

.disclaimer {
  font-size: 0.68rem;
  font-weight: 400;
  width: 100%;
  text-align: right;
  padding-right: 16px;
  color: rgb(0, 0, 0, 0.6);
}

.what-we-do-card .v-image {
  transition: transform 1s ease;
}

.what-we-do-card:hover .v-image {
  transform: translateX( -1px ) rotateY( 180deg );
}
</style>
