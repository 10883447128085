export default {
    base: '#ededed',
    primary: '#ed3237',
    smcRed: {
        base: '#ed3237',
    },
    secondary: '#575757',
    tan: '#E8C2A3',
    lightGrey: '#B4B4B4',
    midGrey: '#818181',
    darkGrey: {
        base: '#575757',
        lighten1: '#ababab',
        lighten2: '#818181',
    },
};
