import _ from 'lodash';
import {
    repairItemService,
    regionService,
    workshopService,
} from '@/services';

export const quoteRequestCreate = {
    namespaced: true,
    state: {
        currentStep: 0,
        quoteType: '',
        repairItemsTreeModel: [],
        regions: [],
        workshopAddresses: [],
        quoteRequestWorkshopMappings: [],
        extraQuoteRequestWorkshopMappings: [],
        selectedWorkshopMappings: [],
        showWorkshopList: undefined,
        quoteRequest: undefined,
        //serviceInterval: undefined,
        place: undefined,
        garageVehicle: undefined,
    },
    mutations: {
        setCurrentStep(state, currentStep) {
            state.currentStep = currentStep;
        },
        setQuoteType(state, quoteType) {
            state.quoteType = quoteType;
        },
        setIsBusiness(state, isBusiness) {
            state.quoteRequest.isForBusiness = isBusiness;
        },
        setRepairItemsTreeModel(state, repairItemsTreeModel) {
            state.repairItemsTreeModel = repairItemsTreeModel;
        },
        setRegions(state, regions) {
            state.regions = regions;
        },
        setWorkshopAddresses(state, workshopAddresses) {
            state.workshopAddresses = workshopAddresses;
        },
        //setQuoteServiceInterval(state, serviceInterval) {
        //    state.serviceInterval = serviceInterval;
        //},
        setQuoteRequestWorkshopMappings(state, workshopMappings) {
            state.quoteRequestWorkshopMappings = workshopMappings;
        },
        addQuoteRequestWorkshopMapping(state, workshopMapping) {
            state.quoteRequestWorkshopMappings.push(workshopMapping);
        },
        removeQuoteRequestWorkshopMapping(state, workshopMapping) {
            let index = _.findIndex(state.quoteRequestWorkshopMappings, ['workshopId', workshopMapping.workshopId]);
            if (index !== -1) {
                state.quoteRequestWorkshopMappings.splice(index, 1);
                state.quoteRequestWorkshopMappings = [...state.quoteRequestWorkshopMappings];
            }
        },
        setSelectedWorkshops(state, selectedWorkshops) {
            state.selectedWorkshopMappings = selectedWorkshops;
        },
        setShowWorkshopList(state, showWorkshopList) {
            state.showWorkshopList = showWorkshopList;
        },
        //removeServiceIntervalTemplateItem(state, templateItem) {
        //    var index = _.findIndex(state.serviceInterval.quoteRequestItemTemplateItems, ["description", templateItem.description]);
        //    if (index !== -1) {
        //        state.serviceInterval.quoteRequestItemTemplateItems.splice(index, 1);
        //        state.serviceInterval.quoteRequestItemTemplateItems = [...state.serviceInterval.quoteRequestItemTemplateItems];
        //        state.serviceInterval.isUpdated = true;
        //    }
        //},
        //clearServiceInterval(state) {
        //    state.serviceInterval = undefined;
        //},
        setExtraQuoteRequestWorkshopMappings(state, workshopMappings) {
            state.extraQuoteRequestWorkshopMappings = workshopMappings;
        },
        toggleQuoteRequestWorkshopMapping(state, workshopMapping) {
            let index = _.findIndex(state.quoteRequestWorkshopMappings, ['workshopId', workshopMapping.workshopId]);
            if (index === -1) {
                state.quoteRequestWorkshopMappings.push(workshopMapping);
            }
            else {
                state.quoteRequestWorkshopMappings.splice(index, 1);
                state.quoteRequestWorkshopMappings = [...state.quoteRequestWorkshopMappings];
            }
        },
        toggleExtraQuoteRequestWorkshopMapping(state, workshopMapping) {
            let index = _.findIndex(state.extraQuoteRequestWorkshopMappings, ['workshopId', workshopMapping.workshopId]);
            if (index === -1) {
                state.extraQuoteRequestWorkshopMappings.push(workshopMapping);
            }
            else {
                state.extraQuoteRequestWorkshopMappings.splice(index, 1);
                state.extraQuoteRequestWorkshopMappings = [...state.extraQuoteRequestWorkshopMappings];
            }
        },
        setQuoteRequest(state, quoteRequest) {
            state.quoteRequest = quoteRequest;
        },
        setQuoteRequestVehicle(state, vehicle) {
            state.quoteRequest.vehicle = vehicle;
        },
        setGarageVehicle(state, garageVehicle) {
            state.garageVehicle = garageVehicle;
        },
        setQuoteRequestUser(state, user) {
            state.quoteRequest.applicationUser = user;
        },
        resetQuoteRequest(state) {
            state.currentStep = 0;
            state.quoteType = '';
            state.repairItemsTreeModel = [];
            state.regions = [];
            state.workshopAddresses = [];
            state.quoteRequestWorkshopMappings = [];
            state.extraQuoteRequestWorkshopMappings = [];
            state.selectedWorkshopMappings = [];
            state.showWorkshopList = undefined;
            state.quoteRequest = undefined;
            state.place = undefined;
            //state.serviceInterval = undefined;
            state.garageVehicle = undefined;
        },
        setPlace(state, place) {
            state.place = place;
        },
    },
    actions: {
        setCurrentStep({ commit }, currentStep) {
            commit('setCurrentStep', currentStep);
        },
        setQuoteType({ commit }, quoteType) {
            commit('setQuoteType', quoteType);
        },
        setIsBusiness({ commit }, isBussiness) {
            commit('setIsBusiness', isBussiness);
        },
        async loadRepairItemsTreeModel({ commit, state }, quoteType) {
            if (state.repairItemsTreeModel.length === 0) {
                let result = await repairItemService.getRepairItems(quoteType);
                commit('setRepairItemsTreeModel', result);
            }
        },
        async loadRegions({ commit, state }) {
            if (state.regions.length === 0) {
                let result = await regionService.getRegions();
                commit('setRegions', result);
            }
        },
        async loadWorkshopAddresses({ commit, state }) {
            if (state.workshopAddresses.length === 0) {
                let result = await workshopService.getWorkshopAddresses(state.quoteType);
                commit('setWorkshopAddresses', result);
            }
        },
        //async loadVehicleServiceInterval({ commit, state }, override) {
        //    var serviceInterval = state.serviceInterval;
        //    var reference = state.quoteRequest.vehicle.diskDriveReference;
        //    var mileage = state.quoteRequest.vehicleMileage;

        //    if (override
        //        || !serviceInterval
        //        || serviceInterval.diskDriveReference !== reference
        //        || mileage < serviceInterval.mileageIntervalFrom
        //        || mileage > serviceInterval.mileageIntervalTo) {

        //        var newServiceInterval = await vehicleService.queryVehicleServiceInterval(
        //            reference,
        //            mileage
        //        );

        //        if (newServiceInterval) {
        //            newServiceInterval.vehicleMileage = state.quoteRequest.vehicleMileage;
        //            newServiceInterval.diskDriveReference = state.quoteRequest.vehicle.diskDriveReference;
        //            newServiceInterval.isUpdated = false;

        //            commit("setQuoteServiceInterval", newServiceInterval);
        //        }
        //        else {
        //            commit("setQuoteServiceInterval", undefined);
        //        }
        //    }
        //},
        setRepairItemsTreeModel({ commit }, tree) {
            commit('setRepairItemsTreeModel', tree);
        },
        //removeServiceIntervalTemplateItem({ commit }, templateItem) {
        //    commit("removeServiceIntervalTemplateItem", templateItem);
        //},
        //clearServiceInterval({ commit }) {
        //    commit("clearServiceInterval");
        //},
        setQuoteRequestWorkshopMappings({ commit }, workswhopMappings) {
            commit('setQuoteRequestWorkshopMappings', workswhopMappings);
        },
        setExtraQuoteRequestWorkshopMappings({ commit }, extraMappings) {
            commit('setExtraQuoteRequestWorkshopMappings', extraMappings);
        },
        addQuoteRequestWorkshopMapping({ commit }, address) {
            commit('addQuoteRequestWorkshopMapping', address);
        },
        toggleQuoteRequestWorkshopMapping({ commit }, address) {
            commit('toggleQuoteRequestWorkshopMapping', address);
        },
        toggleExtraQuoteRequestWorkshopMapping({ commit }, address) {
            commit('toggleExtraQuoteRequestWorkshopMapping', address);
        },
        removeQuoteRequestWorkshopMapping({ commit }, address) {
            commit('removeQuoteRequestWorkshopMapping', address);
        },
        setSelectedWorkshops({ commit }, selectedWorkshops) {
            commit('setSelectedWorkshops', selectedWorkshops);
        },
        setShowWorkshopList({ commit }, showWorkshopList) {
            commit('setShowWorkshopList', showWorkshopList);
        },
        setQuoteRequest({ commit }, quoteRequest) {
            commit('setQuoteRequest', quoteRequest);
        },
        setQuoteRequestVehicle({ commit }, vehicle) {
            commit('setQuoteRequestVehicle', vehicle);
        },
        setGarageVehicle({ commit }, garageVehicle) {
            commit('setGarageVehicle', garageVehicle);
        },
        setQuoteRequestUser({ commit }, user) {
            commit('setQuoteRequestUser', user);
        },
        resetQuoteRequest({ commit }) {
            commit('resetQuoteRequest');
        },
        setPlace({ commit }, place) {
            commit('setPlace', place);
        },
    },
    getters: {
        getCurrentStep: state => {
            return state.currentStep;
        },
        getQuoteType: state => {
            return state.quoteType;
        },
        getIsBusiness: state => {
            return state.quoteRequest.isForBusiness;
        },
        getRepairItemsTreeModel: state => {
            return state.repairItemsTreeModel;
        },
        getRegions: state => {
            return state.regions;
        },
        getWorkshopAddresses: state => {
            return state.workshopAddresses;
        },
        getQuoteRequestWorkshopMappings: state => {
            return state.quoteRequestWorkshopMappings;
        },
        getExtraQuoteRequestWorkshopMappings: state => {
            return state.extraQuoteRequestWorkshopMappings;
        },
        getSelectedWorkshopMappings: state => {
            return state.selectedWorkshopMappings;
        },
        getShowWorkshopList: state => {
            return state.showWorkshopList;
        },
        getQuoteRequest: state => {
            return state.quoteRequest;
        },
        getQuoteRequestVehicle: state => {
            return state.quoteRequest?.vehicle;
        },
        getGarageVehicle: state => {
            return state.garageVehicle;
        },
        getQuoteRequestUser: state => {
            return state.quoteRequest?.user;
        },
        getPlace: state => {
            return state.place;
        },
        //getServiceInterval: state => {
        //    return state.serviceInterval;
        //}
    },
};
