export default class Vehicle {
    constructor(
        id,
        brand,
        brandCode,
        range,
        rangeCode,
        model,
        modelCode,
        year,
        introductionDate,
        discontinuedDate,
        galPic,
        imageUrl,
        diskDriveReference,
        classType,
        description,
        capacity,
        transmissionType,
        fuelType,
        driveType,
        vehicleSummary,
    ) {
        this.id = id;
        this.brand = brand;
        this.brandCode = brandCode;
        this.range = range;
        this.rangeCode = rangeCode;
        this.model = model;
        this.modelCode = modelCode;
        this.year = year;
        this.introductionDate = introductionDate;
        this.discontinuedDate = discontinuedDate;
        this.galPic = galPic;
        this.imageUrl = imageUrl;
        this.diskDriveReference = diskDriveReference;
        this.classType = classType;
        this.description = description;
        this.capacity = capacity;
        this.transmissionType = transmissionType;
        this.fuelType = fuelType;
        this.driveType = driveType;
        this.vehicleSummary = vehicleSummary;
    }
}
