<template>
    <v-row>
        <v-col :cols="searchColWidth">
            <v-autocomplete
                id="addressCapture-searchAddress"
                v-model="autocompleteLocationModel"
                label="Search address"
                class="smc-address-search"
                ref="addressCaptureSearchAddress"
                :disabled="disabledProp"
                rounded
                @change.self="setPlace()"
                :items="locationEntries"
                :search-input.sync="locationSearchText"
                hint="Start typing to search for an address"
                persistent-hint
                outlined
                :loading="mapIsLoading"
                prepend-inner-icon="mdi-google-maps"
                append-icon="mdi-magnify"
                item-text="value"
                item-value="id"
                no-filter
                hide-no-data
                return-object
            >
                <template #item="data">
                    <v-list-item-avatar>
                        <v-icon>mdi-map-marker</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title style="text-align: left">
                            {{ data.item.value }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </v-col>
        <v-scroll-x-reverse-transition>
            <v-col cols="12" md="4" v-if="addressFound">
                <v-btn rounded color="primary" @click="resetAddress()" class="mt-2">
                    Reset address
                </v-btn>
            </v-col>
        </v-scroll-x-reverse-transition>
        <v-col cols="12" md="5">
            <ValidationProvider
                v-slot="{ errors }"
                name="Street number"
                :rules="completeAddressRequiredProp ? 'required' : ''"
            >
                <v-text-field
                    id="addressCapture-addressStreetNumber"
                    v-model="address.streetNumber"
                    label="Street number"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-home-floor-1"
                    :readonly="!allowManualCaptureProp || addressFound"
                    :disabled="disabledProp"
                    :hint="
                        !allowManualCaptureProp ? 'Please search for an address above' : ''
                    "
                    :error-messages="errors"
                />
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                name="Street name"
                :rules="completeAddressRequiredProp ? 'required' : ''"
            >
                <v-text-field
                    id="addressCapture-addressStreetName"
                    v-model="address.streetName"
                    label="Street name"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-home"
                    :readonly="!allowManualCaptureProp || addressFound"
                    :disabled="disabledProp"
                    :hint="
                        !allowManualCaptureProp ? 'Please search for an address above' : ''
                    "
                    :error-messages="errors"
                />
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                name="Suburb"
                :rules="completeAddressRequiredProp ? 'required' : ''"
            >
                <v-text-field
                    id="addressCapture-addressSuburb"
                    v-model="address.suburb"
                    label="Suburb"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-home-group"
                    :readonly="!allowManualCaptureProp || addressFound"
                    :disabled="disabledProp"
                    :hint="
                        !allowManualCaptureProp ? 'Please search for an address above' : ''
                    "
                    :error-messages="errors"
                />
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                name="City"
                :rules="completeAddressRequiredProp ? 'required' : ''"
            >
                <v-text-field
                    id="addressCapture-addressCity"
                    v-model="address.city"
                    label="City"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-city"
                    :readonly="!allowManualCaptureProp || addressFound"
                    :disabled="disabledProp"
                    :hint="
                        !allowManualCaptureProp ? 'Please search for an address above' : ''
                    "
                    :error-messages="errors"
                />
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                name="Province"
                :rules="completeAddressRequiredProp ? 'required' : ''"
            >
                <v-text-field
                    id="addressCapture-addressProvince"
                    v-model="address.province"
                    label="Province"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-map-search"
                    :readonly="!allowManualCaptureProp || addressFound"
                    :disabled="disabledProp"
                    :hint="
                        !allowManualCaptureProp ? 'Please search for an address above' : ''
                    "
                    :error-messages="errors"
                />
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                name="Country"
                :rules="completeAddressRequiredProp ? 'required' : ''"
            >
                <v-text-field
                    id="addressCapture-addressCountry"
                    v-model="address.country"
                    label="Country"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-flag"
                    :readonly="!allowManualCaptureProp || addressFound"
                    :disabled="disabledProp"
                    :hint="
                        !allowManualCaptureProp ? 'Please search for an address above' : ''
                    "
                    :error-messages="errors"
                />
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                name="Postal code"
                :rules="completeAddressRequiredProp ? 'required' : ''"
            >
                <v-text-field
                    id="addressCapture-addressPostalCode"
                    v-model="address.postalCode"
                    label="Postal code"
                    v-mask="'####'"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-numeric-1-box-multiple-outline"
                    :readonly="!allowManualCaptureProp || addressFound"
                    :disabled="disabledProp"
                    :hint="1 === 0 ? 'Please search for an address above' : ''"
                    :error-messages="errors"
                />
            </ValidationProvider>
        </v-col>
        <v-col>
            <gmap-map
                style="width: 100%"
                :style="$vuetify.breakpoint.smAndUp ? 'height: 575px' : 'height: 250px'"
                ref="gmap"
                :zoom="zoom"
                :center="centerPosition"
            >
                <gmap-marker
                    v-if="this.place"
                    label,
                    :animation="4"
                    :position="{
                        lat: this.place.geometry.location.lat(),
                        lng: this.place.geometry.location.lng(),
                    }"
                />
                <!-- inserting an empty polygon, since the geometry api is not loaded on the page if there is none -->
                <gmap-polygon :editable="false" :visible="false" />
            </gmap-map>
        </v-col>
    </v-row>
</template>

<script>
import Address from '@/models/address';
import { placeService } from '@/services';
import { mapHelper } from '@/helpers';
import { mask } from 'vue-the-mask';

export default {
    name: 'address-capture',
    directives: {
        mask,
    },
    props: {
        addressProp: Address,
        allowManualCaptureProp: Boolean,
        completeAddressRequiredProp: Boolean,
        disabledProp: Boolean,
    },
    data: () => ({
        searchColWidth: 12,
        place: null,
        autocompleteLocationModel: null,
        locationSearchText: null,
        locationEntries: [],
        autocompleteSessionToken: undefined,
        centerPosition: mapHelper.defaultCenterPosition(),
        zoom: mapHelper.defaultZoomLevel(),
        mapIsLoading: false,
        addressFound: false,
        address: {
            id: 0,
        },
    }),
    created() {
        if (this.addressProp) {
            this.address = this.addressProp;
        }
    },
    watch: {
        locationSearchText: async function (newVal) {
            if (!this.autocompleteSessionToken) {
                this.autocompleteSessionToken = placeService.getAutocompleteSessionToken();
            }

            let vm = this;
            // If less than 3 chars typed, do not search
            if (!newVal || newVal.length <= 3) return;

            await placeService
                .getSuggestions(newVal, this.autocompleteSessionToken)
                .then(function (res) {
                    vm.locationEntries = res;
                })
                .catch(function (err) {
                    console.error(err);
                });
        },
        address: function (newVal) {
            this.$emit('addressChange', newVal);
        },
    },
    methods: {
        async setPlace() {
            this.mapIsLoading = true;
            let placeDetails = await placeService.getPlace(
                this.autocompleteLocationModel.id,
                this.autocompleteSessionToken,
            );

            this.address = placeDetails.address;
            this.place = placeDetails;
            this.centerPosition.lat = placeDetails.geometry.location.lat();
            this.centerPosition.lng = placeDetails.geometry.location.lng();
            this.zoom = 18;
            this.mapIsLoading = false;
            this.addressFound = true;
            this.searchColWidth = 8;
            this.$refs.addressCaptureSearchAddress.blur();
        },
        resetAddress() {
            this.addressFound = false;
            this.locationSearchText = null;
            this.locationEntries = [];
            this.centerPosition = mapHelper.defaultCenterPosition();
            this.zoom = mapHelper.defaultZoomLevel();
            this.place = null;

            this.address.id = 0;
            this.address.streetNumber = '';
            this.address.streetName = '';
            this.address.suburb = '';
            this.address.town = '';
            this.address.city = '';
            this.address.province = '';
            this.address.country = '';
            this.address.postalCode = '';
            this.address.lat = 0;
            this.address.lng = 0;

            let vm = this;
            setTimeout(() => {
                vm.searchColWidth = 12;
            }, 500);
        },
    },
};
</script>
