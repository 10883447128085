<template>
    <div id="quote-request-stepper-wrapper">
        <v-card class="mx-4" v-if="isOrganization">
            <v-card-title class="text-center">
                Current branch: {{ organizationBranch.branchName }}
            </v-card-title>
        </v-card>
    
        <div v-if="!submitted">
            <v-stepper
                v-model="currentStep"
                @change.self="stepChanged($event)"
                :alt-labels="this.$vuetify.breakpoint.mdAndUp"
            >
                <template v-if="this.$vuetify.breakpoint.mdAndUp">
                    <v-stepper-header id="stepper-header">
                        <v-stepper-step :complete="currentStep > 1" step="1" :editable="currentStep > 1">
                            Vehicle Details
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep === 1"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'Step 1'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep < 1"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'1'"
                                    :flagTextColourProp="'darkGrey--text'"
                                    :flagColourProp="'lightGrey'"
                                    :flagTailsColourProp="'#B4B4B4'"
                                    :carColourProp="'lightGrey'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep > 1"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'1'"
                                    :flagColourProp="'primary'"
                                    :flagTailsColourProp="'#ed3237'"
                                    :carColourProp="'midGrey'"
                                />
                            </v-slide-x-transition>
                        </v-stepper-step>
                        <v-divider />
                        <v-stepper-step :complete="currentStep > 2" step="2" :editable="currentStep > 2">
                            <span> What to expect </span>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep === 2"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'Step 2'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep < 2"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'2'"
                                    :flagTextColourProp="'darkGrey--text'"
                                    :flagColourProp="'lightGrey'"
                                    :flagTailsColourProp="'#B4B4B4'"
                                    :carColourProp="'lightGrey'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep > 2"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'2'"
                                    :flagColourProp="'primary'"
                                    :flagTailsColourProp="'#ed3237'"
                                    :carColourProp="'midGrey'"
                                />
                            </v-slide-x-transition>
                        </v-stepper-step>
                        <v-divider />
                        <v-stepper-step :complete="currentStep > 3" step="3" :editable="currentStep > 3">
                            Booking Details
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep === 3"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'Step 3'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep < 3"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'3'"
                                    :flagTextColourProp="'darkGrey--text'"
                                    :flagColourProp="'lightGrey'"
                                    :flagTailsColourProp="'#B4B4B4'"
                                    :carColourProp="'lightGrey'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep > 3"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'3'"
                                    :flagColourProp="'primary'"
                                    :flagTailsColourProp="'#ed3237'"
                                    :carColourProp="'midGrey'"
                                />
                            </v-slide-x-transition>
                        </v-stepper-step>
                        <v-divider />
                        <v-stepper-step :complete="currentStep > 4" step="4" :editable="currentStep > 4">
                            Personal Details
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep === 4"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'Step 4'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep !== 4"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'4'"
                                    :flagTextColourProp="'darkGrey--text'"
                                    :flagColourProp="'lightGrey'"
                                    :flagTailsColourProp="'#B4B4B4'"
                                    :carColourProp="'lightGrey'"
                                />
                            </v-slide-x-transition>
                        </v-stepper-step>
                    </v-stepper-header>
                </template>
                <template v-else>
                    <v-stepper-header id="stepper-header-mobile">
                        <v-stepper-step :complete="currentStep > 1" step="1" :editable="currentStep > 1" edit-icon="1">
                            <v-slide-x-transition>
                                <car-flag v-if="currentStep === 1" class="smc-stepper-car-flag" :flagTextProp="'1'" />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep < 1"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'1'"
                                    :flagTextColourProp="'darkGrey--text'"
                                    :flagColourProp="'lightGrey'"
                                    :flagTailsColourProp="'#B4B4B4'"
                                    :carColourProp="'lightGrey'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep > 1"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'1'"
                                    :flagColourProp="'primary'"
                                    :flagTailsColourProp="'#ed3237'"
                                    :carColourProp="'midGrey'"
                                />
                            </v-slide-x-transition>
                            <span v-if="currentStep === 1"> Vehicle Details </span>
                        </v-stepper-step>
                        <v-stepper-step :complete="currentStep > 2" step="2" :editable="currentStep > 2" edit-icon="2">
                            <v-slide-x-transition>
                                <car-flag v-if="currentStep === 2" class="smc-stepper-car-flag" :flagTextProp="'2'" />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep < 2"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'2'"
                                    :flagTextColourProp="'darkGrey--text'"
                                    :flagColourProp="'lightGrey'"
                                    :flagTailsColourProp="'#B4B4B4'"
                                    :carColourProp="'lightGrey'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep > 2"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'2'"
                                    :flagColourProp="'primary'"
                                    :flagTailsColourProp="'#ed3237'"
                                    :carColourProp="'midGrey'"
                                />
                            </v-slide-x-transition>
                            <span v-if="currentStep === 2">
                                <span> What to Expect </span>
                            </span>
                        </v-stepper-step>
                        <v-stepper-step :complete="currentStep > 3" step="3" :editable="currentStep > 3" edit-icon="3">
                            <v-slide-x-transition>
                                <car-flag v-if="currentStep === 3" class="smc-stepper-car-flag" :flagTextProp="'3'" />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep < 3"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'3'"
                                    :flagTextColourProp="'darkGrey--text'"
                                    :flagColourProp="'lightGrey'"
                                    :flagTailsColourProp="'#B4B4B4'"
                                    :carColourProp="'lightGrey'"
                                />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep > 3"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'2'"
                                    :flagColourProp="'primary'"
                                    :flagTailsColourProp="'#ed3237'"
                                    :carColourProp="'midGrey'"
                                />
                            </v-slide-x-transition>
                            <span v-if="currentStep === 3"> Booking Details </span>
                        </v-stepper-step>
                        <v-stepper-step :complete="currentStep > 4" step="4" :editable="currentStep > 4" edit-icon="4">
                            <v-slide-x-transition>
                                <car-flag v-if="currentStep === 4" class="smc-stepper-car-flag" :flagTextProp="'4'" />
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <car-flag
                                    v-if="currentStep !== 4"
                                    class="smc-stepper-car-flag"
                                    :flagTextProp="'4'"
                                    :flagTextColourProp="'darkGrey--text'"
                                    :flagColourProp="'lightGrey'"
                                    :flagTailsColourProp="'#B4B4B4'"
                                    :carColourProp="'lightGrey'"
                                />
                            </v-slide-x-transition>
                            <span v-if="currentStep === 4"> Personal Details </span>
                        </v-stepper-step>
                    </v-stepper-header>
                </template>

                <v-stepper-items>
                    <create-step-1
                        ref="createStep1D"
                        :duplicateValidationProp="duplicateValidationShow"
                        :validationMessagesProp="validationMessages"
                        @resetForm="resetForm($event)"
                    />
                    <create-step-2
                        ref="createStep2D"
                        :duplicateValidationProp="duplicateValidationShow"
                        :validationMessagesProp="validationMessages"
                    />
                    <create-step-3
                        ref="createStep3D"
                        :duplicateValidationProp="duplicateValidationShow"
                        :validationMessagesProp="validationMessages"
                    />
                    <create-step-4
                        ref="createStep4D"
                        :duplicateValidationProp="duplicateValidationShow"
                        :submittingProp="submitting"
                        :validationMessagesProp="validationMessages"
                        @validateRecaptcha="validateRecaptcha()"
                    />
                </v-stepper-items>
            </v-stepper>
        </div>

        <vue-recaptcha
            v-if="!isAuthenticated"
            ref="createQuoteRecaptcha"
            @verify="recaptchaOnVerify"
            size="invisible"
            badge="bottomleft"
            :loadRecaptchaScript="true"
            :sitekey="recaptchaSiteKey"
        />

        <v-dialog v-model="submitDialogShow" content-class="smc-dialog" persistent>
            <v-card class="smc-card-dialog">
                <v-card-title class="primary white--text smc-card-dialog-title">
                    <span class="mx-auto"> Submit </span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-slide-x-transition>
                        <car-flag class="smc-dialog-car-flag" :flagTextProp="'Hold on'" />
                    </v-slide-x-transition>
                </div>
                <v-card-subtitle class="title" v-if="isOrganization">
                    Current Branch - {{ organizationBranch.branchName }}
                </v-card-subtitle>
                <v-card-subtitle class="title">
                    Get a Quote or an Estimate for Your Car Maintenance Needs
                </v-card-subtitle>
                <v-card-text>
                    <v-card outlined>
                        <v-card-text>
                            This will initiate the process to receive personalized quotes 
                            for the requested service or repairs from our workshop network.
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-btn rounded color="primary" class="smc-text-button mx-auto my-1" @click="submit" :loading="submitting">
                                Get Quotes for booking
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined v-if="!isOrganization">
                        <v-card-text>
                            This will provide an estimate based on the average cost of
                            previous similar services or repairs.
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-btn
                                rounded
                                color="primary"
                                class="smc-text-button smc-medium-button mx-auto my-1"
                                to="quote-estimate"
                                :loading="submitting"
                            >
                                Get Estimate
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="submitting" content-class="smc-dialog" persistent>
            <v-card class="smc-card-dialog">
                <v-card-title class="primary white--text smc-card-dialog-title">
                    <v-fade-transition>
                        <span v-if="!submitted || !fiveSecondsPassed" class="mx-auto">
                            HOLD ON
                        </span>
                    </v-fade-transition>
                    <v-fade-transition>
                        <span v-if="submitted && fiveSecondsPassed" class="mx-auto">
                            SUBMITTED
                        </span>
                    </v-fade-transition>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-slide-x-transition>
                        <car-flag
                            v-if="!submitted || !fiveSecondsPassed"
                            class="smc-dialog-car-flag"
                            :flagTextProp="'Hold on'"
                        />
                    </v-slide-x-transition>
                    <v-slide-x-transition>
                        <car-flag
                            v-if="submitted && fiveSecondsPassed"
                            class="smc-dialog-car-flag"
                            :flagTextProp="'SUCCESS'"
                        />
                    </v-slide-x-transition>
                </div>
                <!-- Dialog body waiting -->
                <v-expand-transition>
                    <v-card-subtitle v-if="!submitted || !fiveSecondsPassed" class="title">
                        Please hold on while we work on your request.
                    </v-card-subtitle>
                </v-expand-transition>
                <v-expand-transition>
                    <v-card-text v-if="!submitted || !fiveSecondsPassed">
                        <car-driving :canvasHeightProp="200" />
                    </v-card-text>
                </v-expand-transition>
                <v-card-subtitle class="title" v-if="isOrganization">
                    Current Branch - {{ organizationBranch.branchName }}
                </v-card-subtitle>
                <!-- Dialog body completre -->
                <v-expand-transition>
                    <v-card-subtitle v-if="submitted && fiveSecondsPassed" class="mt-4 title">
                        You have successfully submitted your request!
                        <br />
                        Responses will be sent to
                        <span class="primary--text">{{ quoteRequest.applicationUser.email }}.</span>
                    </v-card-subtitle>
                </v-expand-transition>
                <v-expand-transition>
                    <v-card-subtitle v-if="submitted && fiveSecondsPassed">
                        Your reference number for this request is
                        <strong> {{ quoteRequestReference }}. </strong>
                    </v-card-subtitle>
                </v-expand-transition>
                <v-card-text>
                    <v-btn rounded color="primary" width="200" @click="closeDialog()" :loading="!submitted || !fiveSecondsPassed">
                        Done
                    </v-btn>
                    <p class="mt-6 font-weight-bold">
                        Connect with us on our socials
                        <br />
                        <!-- <v-btn
              icon
              class="mx-2"
              :href="socialLinks.facebook"
              target="_blank"
            >
              <v-icon x-large color="primary">mdi-facebook</v-icon>
            </v-btn> -->
                        <v-btn icon class="mx-2" :href="socialLinks.instagram" target="_blank">
                            <v-icon x-large color="primary">mdi-instagram</v-icon>
                        </v-btn>
                        <v-btn icon class="mx-2" :href="socialLinks.twitter" target="_blank">
                            <v-icon x-large color="primary">mdi-twitter</v-icon>
                        </v-btn>
                        <v-btn icon class="mx-2" :href="socialLinks.linkedin" target="_blank">
                            <v-icon x-large color="primary">mdi-linkedin</v-icon>
                        </v-btn>
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="duplicateValidationShow" content-class="smc-dialog" persistent>
            <v-card class="smc-card-dialog">
                <v-card-title class="primary white--text smc-card-dialog-title">
                    <span class="mx-auto"> Confirm </span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-slide-x-transition>
                        <car-flag class="smc-dialog-car-flag" :flagTextProp="'Hold on'" />
                    </v-slide-x-transition>
                </div>
                <v-card-subtitle class="title">
                    Please take note of the following
                </v-card-subtitle>
                <v-card-text>
                    <v-simple-table class="mb-2" style="max-height: 140px; overflow-y: scroll;">
                        <template #default>
                            <tbody>
                                <tr v-for="(item, index) in validationMessages" :key="index">
                                    <td class="text-left">
                                        {{ item }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-divider />
                    <h3 class="text-left mt-2">What do you want to do?</h3>
                    <v-radio-group v-model="duplicateChoice">
                        <v-radio label="View previous requests." value="2" />
                        <v-radio
                            v-if="isAuthenticated && !duplicateQuoteValidation"
                            label="Cancel previous requests and replace with this one."
                            value="0"
                        />
                        <v-radio label="Keep previous requests and submit this one as well." value="1" />
                    </v-radio-group>

                    <v-card outlined v-if="duplicateQuoteValidation">
                        <v-card-text class="text-left">
                            If you want to make changes to the booking, please contact support.
                        </v-card-text>
                    </v-card>

                    <v-divider />
                    <v-row class="mt-2">
                        <v-col class="text-right">
                            <v-btn
                                rounded
                                outlined
                                color="secondary"
                                width="150"
                                class="mr-3"
                                @click="duplicateValidationShow = false;"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                rounded
                                color="primary"
                                width="150"
                                @click="submitDuplicate"
                                :disabled="duplicateChoice === undefined"
                            >
                                Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    <!-- <naked-insurance-banner
      v-if="!submitted"
      v-bind:floatSideProp="true"
      v-bind:analyticsLabelProp="'quoteRequestAdvert'"
      v-on:queueRedirect="nakedRedirectQueued = true"
    /> -->
    </div>
</template>

<script>
import analytics from '@/mixins/analytics';
import quoteRequestCreate from '@/mixins/quote-request-create';
import createStep1 from '@/components/quote-request-steps/create-step-1';
import createStep2 from '@/components/quote-request-steps/create-step-2';
import createStep3 from '@/components/quote-request-steps/create-step-3';
import createStep4 from '@/components/quote-request-steps/create-step-4';
import carDriving from '@/components/widgets/car-driving';
// import nakedInsuranceBanner from "@/components/widgets/naked-insurance-banner";

export default {
    name: 'quote-request-create',
    components: {
        createStep1,
        createStep2,
        createStep3,
        createStep4,
        carDriving,
    // nakedInsuranceBanner,
    },
    mixins: [quoteRequestCreate, analytics],
    data: function () {
        return {
            contactTimeout: undefined,
            submitDialogShow: false,
            duplicateChoice: undefined,

            dekraTriggered: false,
        };
    },
    computed: {
        organization() {
            return this.$store.getters['user/getOrganization'];
        },
        organizationBranch() {
            return this.organization.organizationBranches[0];
        },
    },
    created: async function () {
        await this.initialize();
    },
    mounted: function () {
        this.trackEvent('StartRequest', 'QuoteRequest', this.quoteTypeProp);
        this.trackEvent('LoadStep', 'QuoteRequest', this.currentStep);
    },
    watch: {
        currentStep(newVal) {
            this.trackEvent('LoadStep', 'QuoteRequest', newVal);

            //if (this.$vuetify.breakpoint.mdAndUp) {
            //  if (newVal === 2) {
            //    this.contactTimeout = setTimeout(() => {
            //      this.$store.dispatch("contactSpeedDial/setShow", true);
            //    }, 4000);
            //  } else {
            //    clearTimeout(this.contactTimeout);
            //  }
            //}

            if (newVal === 2) {
                if (!this.dekraTriggered) {
                    this.$emit('openDekra');
                    this.dekraTriggered = true;
                }
            }

            if (newVal === 3) {
                if (this.$refs['createStep3D']) {
                    this.$refs['createStep3D'].initialize();
                }
                if (this.$refs['createStep3M']) {
                    this.$refs['createStep3M'].initialize();
                }
            }
        },
    },
    methods: {
        async validateRecaptcha() {
            if (this.isAuthenticated) {
                this.submitDialogShow = true;
            }
            else if (this.recaptchaValid) {
                this.submitDialogShow = true;
            }
            else {
                await this.$refs.createQuoteRecaptcha.execute();
            }
        },
        async recaptchaOnVerify() {
            this.recaptchaValid = true;
            this.submitDialogShow = true;
        },
        submitDuplicate() {
            switch (this.duplicateChoice) {
                case '0':
                    this.submit(true, false);
                    break;
                case '1':
                    this.submit(false, true);
                    break;
                case '2':
                    this.$router.push({
                        name: 'quote-requests',
                    });
                    break;
            }
        },
    },
};
</script>

<style scoped>
@import "../assets/scss/vuetify-elements/card.scss";
@import "../assets/scss/vuetify-elements/sheet.scss";
@import "../assets/scss/vuetify-elements/dialog.scss";

.inline-flex {
  display: inline-flex;
}
</style>

<style>
@import "../assets/scss/components/create-quote-request.scss";

@media only screen and (max-width: 600px) {
  .grecaptcha-badge {
    opacity: 0;
  }

  .v-stepper {
    overflow: visible;
  }
}

#stepper-header-mobile {
  display: flex;
  flex-wrap: nowrap;

  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

#stepper-header-mobile .v-stepper__step {
  min-width: 65px;
}

#stepper-header-mobile .v-stepper__step--active {
  min-width: 130px;
  background-color: #cfcfcf;
}

#stepper-header-mobile .v-stepper__step .v-stepper__step__step {
  opacity: 0;
}

#stepper-header-mobile .v-stepper__label {
  display: flex;
  width: 10px;
}

#stepper-header-mobile .v-stepper__label .smc-stepper-car-flag {
  left: 25px;
  bottom: 15px;
}

#stepper-header-mobile .v-stepper__step--active .v-stepper__label {
  padding-left: 20px;
}
</style>
