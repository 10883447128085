import { render, staticRenderFns } from "./app.vue?vue&type=template&id=defa484e&"
import script from "./app.vue?vue&type=script&lang=js&"
export * from "./app.vue?vue&type=script&lang=js&"
import style0 from "./app.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VAlert,VApp,VAppBar,VCard,VCardActions,VCardText,VCardTitle,VContainer,VIcon,VImg,VMain})
