export default class RepairItem {
    constructor(
        id,
        name,
        displayName,
        query,
        queryAnswer,
        icon,
        hasQuery,
        trunk,
        leaf,
        children,
        quoteRequestItemTemplateItems,
        baseQuoteRequestItemTemplateItems,
    ) {
        this.id = id;
        this.name = name;
        this.displayName = displayName;
        this.query = query;
        this.queryAnswer = queryAnswer;
        this.icon = icon;
        this.hasQuery = hasQuery;
        this.trunk = trunk;
        this.leaf = leaf;
        this.children = children;
        this.quoteRequestItemTemplateItems = quoteRequestItemTemplateItems;
        this.baseQuoteRequestItemTemplateItems = baseQuoteRequestItemTemplateItems;
    }
}
