<template>
    <v-sheet class="smc-sheet-transparent workshop-slider">
        <h1>Our workshop partners</h1>
        <div class="py-2 my-2"></div>
        <carousel
            autoplay
            loop
            :perPageCustom="[
                [1000, 5],
                [768, 3],
                [480, 2],
            ]"
            :autoplayTimeout="10000"
            paginationColor="transparent"
            paginationActiveColor="transparent"
            paginationPosition="bottom"
        >
            <slide v-for="(item, i) in workshopLogos" :key="i">
                <div class="pill">
                    <img :src="item.src" />
                </div>
            </slide>
        </carousel>
    </v-sheet>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
    data: function () {
        return {
            workshopLogos: [
                {
                    src: require('@/assets/images/partner-logos/slider-1.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-2.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-3.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-4.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-5.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-6.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-7.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-8.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-9.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-10.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-11.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-12.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-13.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-14.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-15.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-16.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-17.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-18.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-19.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-20.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-21.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-22.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-blank.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-blank.jpg'),
                },
                {
                    src: require('@/assets/images/partner-logos/slider-blank.jpg'),
                },
            ],
        };
    },
    components: {
        Carousel,
        Slide,
    },
};
</script>

<style scoped>
@import "../../assets/scss/vuetify-elements/sheet.scss";

.pill {
  margin: 0 20px;
  height: 60px;
  position: relative;
  overflow: hidden;
}

.pill img {
  margin: auto;
  height: 100%;
  left: 0;
}
</style>
