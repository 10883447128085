export default class Quote {
    constructor(
        id,
        uniqueId,
        quoteRequestId,
        workshopId,
        reference,
        externalReference,
        invoiceNumber,
        quoteMessages,
        quoteStatus,
        dateCreatedUtc,
        dateCreated,
        dateModifiedUtc,
        dateModified,
        expiryDateUtc,
        expiryDate,
        serviceDateUtc,
        serviceDate,
        quoteItems,
        quoteRequestItems,
        quoteTotalIncl,
        workshop,
        quoteStatuses,
        isExpired,
        isVatRegistered,
        vehicleMileage,
        workshopCustomer,
        organizationOrderAttached,
        jobTurnaround,
    ) {
        this.id = id;
        this.uniqueId = uniqueId;
        this.quoteRequestId = quoteRequestId;
        this.workshopId = workshopId;
        this.reference = reference;
        this.externalReference = externalReference;
        this.invoiceNumber = invoiceNumber;
        this.quoteMessages = quoteMessages;
        this.quoteStatus = quoteStatus;
        this.dateCreatedUtc = dateCreatedUtc;
        this.dateCreated = dateCreated;
        this.dateModifiedUtc = dateModifiedUtc,
        this.dateModified = dateModified,
        this.expiryDateUtc = expiryDateUtc;
        this.expiryDate = expiryDate;
        this.serviceDateUtc = serviceDateUtc;
        this.serviceDate = serviceDate;
        this.quoteItems = quoteItems;
        this.quoteRequestItems = quoteRequestItems;
        this.quoteTotalIncl = quoteTotalIncl;
        this.workshop = workshop;
        this.quoteStatuses = quoteStatuses;
        this.isExpired = isExpired;
        this.isVatRegistered = isVatRegistered;
        this.vehicleMileage = vehicleMileage;
        this.workshopCustomer = workshopCustomer;
        this.organizationOrderAttached = organizationOrderAttached;
        this.jobTurnaround = jobTurnaround;
    }
}
