const vatPercent = parseInt(process.env.VUE_APP_VAT_PERCENT);

const addVat = exlusiveAmount => {
    let parsedExlusiveAmount = parseFloat(exlusiveAmount);
    let inclusiveAmount = (parsedExlusiveAmount + ((parsedExlusiveAmount * vatPercent) / 100));
    return parseFloat(inclusiveAmount.toFixed(2));
};

const removeVat = inclusiveAmount => {
    let parsedInclusiveAmount = parseFloat(inclusiveAmount);
    let exlusiveAmount = (parsedInclusiveAmount * 100) / (100 + vatPercent);
    return parseFloat(exlusiveAmount.toFixed(2));
};

const determineVat = inclusiveAmount => {
    let parsedInclusiveAmount = parseFloat(inclusiveAmount);
    let exlusiveAmount = (parsedInclusiveAmount * 100) / (100 + vatPercent);
    return parseFloat((parsedInclusiveAmount - exlusiveAmount).toFixed(2));
};

const formatNumberAsCurrency = number => {
    return number.toLocaleString('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
    });
};

export const financeHelper = {
    vatPercent,
    addVat,
    removeVat,
    determineVat,
    formatNumberAsCurrency,
};
