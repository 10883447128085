import QuoteRequest from '../models/quote-request';
import { userHelper } from './user-helper';
import { vehicleHelper } from './vehicle-helper';
import { addressHelper } from './address-helper';

const getEmptyQuoteRequest = () => {
    return new QuoteRequest(
        '', // Id
        '', // uniuqueId
        '', // UserId
        '', // mileage
        '', // existingMileage
        '', // vin number
        '', // engine number
        '', // license plate
        0, // vehicleMappingId
        0, // vehicleId
        '', // vehicleName
        '', // date created
        '', // date created utc
        [], // dates requested
        '', // quote request status
        '', // quoteRequestStatusUsername
        '', // notes
        '', // reference
        '', // license disk photo
        '', // license disk photo url
        userHelper.getEmptyUser(),
        vehicleHelper.getEmptyVehicle(),
        addressHelper.getEmptyAddress(),
        '', // travelDistance
        [], // region ids
        '', // parts preference
        [], // items,
        [], // quotes,
        false, // is expired
        true, // service reminder
        false, // isPolicyQuote
        '', // quoteType
        false, // hidePrice
        false, // isForBusiness
        [], // attachments
        [], // quoteCustomerReferences
        [], // quoteRequestWorkshopMappings
        null, // quoteRequestOrganizationBranchShare
    );
};

export const quoteRequestHelper = { getEmptyQuoteRequest };
