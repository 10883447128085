import Vue from 'vue';
import App from './app.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import _ from 'lodash';
import VueCookies from 'vue-cookies';
import { ValidationProvider } from 'vee-validate';
import browserDetect from 'vue-browser-detect-plugin';

// API
import interceptorsSetup from './helpers/interceptor';

// Google
import * as VueGoogleMaps from 'vue2-google-maps';
import VueGtag from 'vue-gtag';
import VueYoutube from 'vue-youtube';
import VueGtm from 'vue-gtm';
import GAuth from 'vue-google-oauth2';

// UI components
import vuetify from './plugins/vuetify';
import Vuebar from 'vuebar';
import VueHtmlToPaper from 'vue-html-to-paper';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import OtpInput from '@bachdgvn/vue-otp-input';
import Particles from 'particles.vue';
import VideoBackground from 'vue-responsive-video-background-player';
import VueApexCharts from 'vue-apexcharts';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import TextHighlight from 'vue-text-highlight';
import VueClipboard from 'vue-clipboard2';
import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.use(VueCookies);

/*
  WARNING
  Do not store any secrets (such as private API keys) in your .env!
  Environment variables are embedded into the build, meaning anyone can view them by inspecting your app's files.

  TODO: implement some appropriate vue structure for secrets/keys
*/
let googleApiKey = 'AIzaSyABggAY2xhm44b44hJ2l5DAFomSBdJi6NU';
if (process.env.NODE_ENV === 'production') {
    googleApiKey = 'AIzaSyDBX9H-E3-rh6gVWEn6yAeUu9Yfbi1-vCM';
}

Vue.use(VueGoogleMaps, {
    load: {
        key: googleApiKey,
        libraries: 'places,drawing,visualization', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
    },

    // If you intend to programmatically custom event listener code
    // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    // you might need to turn this on.
    autobindAllEvents: false,

    // If you want to manually install components, e.g.
    // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    // Vue.component('GmapMarker', GmapMarker)
    // then disable the following:
    installComponents: true,
});

if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtag, {
        config: { id: 'UA-166821553-3' },
        includes: [
            { id: 'AW-622218389' },
            { id: 'G-HWZMN5JXEW' },
        ],
    }, router);

    Vue.use(VueGtm, {
        id: 'GTM-K7GPT7H', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
        defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        //ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });
}

Vue.use(GAuth, {
    clientId: process.env.NODE_ENV === 'production' 
        ? '101890035416-sni6eads9vjo06bcjejhi67dier49ed1.apps.googleusercontent.com' 
        : '605847006954-hdf2ij5j0uib07mdkguols79i7sjonb3.apps.googleusercontent.com',
    scope: 'email',
    prompt: 'select_account',
    //redirectUri: `${window.location.origin}/account/google-response`,
    //uxMode: "redirect"
});

Vue.component('validation-provider', ValidationProvider);

Vue.use(Vuebar);

Vue.use(VueHtmlToPaper, {
    name: '_blank',
    specs: [
        'fullscreen=no',
        'titlebar=no',
        'scrollbars=no',
    ],
});

Vue.use(vueNumeralFilterInstaller, { locale: 'en-za' });

Vue.use(VueYoutube);

Vue.component('v-otp-input', OtpInput);

Vue.use(Particles);

Vue.component('video-background', VideoBackground);

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.use(FloatingVue);

Vue.component('text-highlight', TextHighlight);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.use(VueViewer);

Vue.use(browserDetect);
// API
interceptorsSetup();

Vue.config.productionTip = true;

// Globally registered prototypes
Object.defineProperty(Vue.prototype, '_', { value: _ });

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
