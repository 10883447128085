import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import { user } from './modules/user.module';
import { contactSpeedDial } from './modules/contact-speed-dial.module';
import { quoteRequestCreate } from './modules/quote-request-create.module';
import { pwaInstall } from './modules/pwa-install.module';
import { notifications } from './modules/notifications.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        contactSpeedDial,
        quoteRequestCreate,
        pwaInstall,
        notifications,
    },
    plugins: [createPersistedState()],
});
