<template>
    <v-sheet
        class="d-flex flex-row-reverse float-right"
        height="100%"
        width="100%"
    >
        <nav class="d-flex flex-row-reverse float-right" v-if="isOrganization">
            <recent-actions />
            <v-btn
                v-if="isAuthenticated && this.$vuetify.breakpoint.mdAndUp"
                class="my-auto mx-2 px-2 secondary--text text-none"
                to="/account"
                tile
                text
            >
                Account
                <v-icon small>mdi-settings-outline</v-icon>
            </v-btn>
            <v-btn
                v-if="isAuthenticated && this.$vuetify.breakpoint.mdAndUp"
                @click="signOutUser()"
                class="my-auto mx-2 text-none"
                color="primary"
                rounded
            >
                <span class="mr-1"> Logout </span>
                <v-icon small>mdi-account-circle-outline</v-icon>
            </v-btn>
            <v-btn
                v-if="this.$vuetify.breakpoint.mdAndUp"
                class="my-auto mx-2 px-2 secondary--text text-none"
                to="/dashboard"
                tile
                text
            >
                {{ isOrganizationAdmin ? 'Admin Dashboard' : 'Dashboard' }}
            </v-btn>
            <v-btn
                v-if="this.$vuetify.breakpoint.mdAndUp"
                class="my-auto mx-2 px-2 secondary--text text-none"
                to="/active-requests"
                tile
                text
            >
                Active Requests
            </v-btn>
        </nav>
        <nav class="d-flex flex-row-reverse float-right" v-else>
            <v-btn
                v-if="!isAuthenticated && this.$vuetify.breakpoint.mdAndUp"
                class="my-auto mx-2 text-none"
                text
                tile
                to="/account/signup"
            >
                Register
                <v-icon class="ml-1" small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
                v-if="isAuthenticated && this.$vuetify.breakpoint.mdAndUp"
                class="my-auto mx-2 px-2 secondary--text text-none"
                to="/account"
                tile
                text
            >
                Account
                <v-icon small>mdi-settings-outline</v-icon>
            </v-btn>
            <v-btn
                v-if="isAuthenticated && this.$vuetify.breakpoint.mdAndUp"
                @click="signOutUser()"
                class="my-auto mx-2 text-none"
                color="primary"
                rounded
            >
                Logout
                <v-icon small>mdi-account-circle-outline</v-icon>
            </v-btn>
            <v-btn
                v-if="!isAuthenticated && this.$vuetify.breakpoint.mdAndUp"
                class="my-auto mx-2 text-none"
                to="/account/login"
                color="secondary"
                rounded
            >
                Login
                <v-icon small class="ml-1">mdi-account-circle-outline</v-icon>
            </v-btn>
            <v-btn
                v-if="this.$vuetify.breakpoint.mdAndUp"
                class="my-auto mx-2 px-2 secondary--text text-none"
                to="/faq"
                tile
                text
            >
                FAQs
            </v-btn>
            <v-btn
                v-if="this.$vuetify.breakpoint.mdAndUp"
                class="my-auto mx-2 px-2 secondary--text text-none"
                to="/contact"
                tile
                text
            >
                Contact
            </v-btn>
            <v-btn
                v-if="this.$vuetify.breakpoint.mdAndUp"
                class="top-menu-link mx-2 px-2 secondary--text text-none"
                to="/about"
                tile
                text
            >
                About
            </v-btn>
            <span
                v-if="isAuthenticated && this.$vuetify.breakpoint.mdAndUp"
                class="logged-in-menu top-menu-link mx-2 pa-2"
            >
                <v-btn class="secondary--text text-none" to="/my-garage" tile text>
                    My Garage
                </v-btn>
                <v-btn
                    class="secondary--text text-none"
                    to="/active-requests"
                    tile
                    text
                >
                    Active Requests
                </v-btn>
            </span>
            <v-btn
                v-if="this.$vuetify.breakpoint.mdAndUp"
                to="/"
                class="my-auto mx-2"
                color="primary"
                width="120"
                outlined
                rounded
            >
                QUOTE
            </v-btn>
        </nav>

        <organization-branch-picker
            v-if="isAuthenticated && isOrganization"
            class="mt-5"
        />
        <global-search
            v-if="isAuthenticated && isOrganization"
            class="mx-auto mt-5"
        />
        <messages-inbox
            v-if="isAuthenticated && isOrganization"
            class="mr-auto"
        />

        <Slide
            right
            class="sidebar-menu"
            v-if="this.$vuetify.breakpoint.smAndDown"
            closeOnNavigation
        >
            <router-link to="/home" v-if="!isAuthenticated || !isOrganization">
                <v-icon color="white">mdi-home</v-icon>
                <span>Home</span>
            </router-link>
            <router-link to="/my-garage" v-if="isAuthenticated && !isOrganization">
                <v-icon color="white">mdi-home-variant</v-icon>
                <span>My Garage</span>
            </router-link>
            <router-link to="/active-requests" v-if="isAuthenticated">
                <v-icon color="white">mdi-calendar-clock-outline</v-icon>
                <span>Active Requests</span>
            </router-link>
            <router-link to="/dashboard" v-if="isAuthenticated && isOrganization">
                <v-icon color="white">mdi-view-dashboard-variant</v-icon>
                <span>Dashboard</span>
            </router-link>
            <router-link to="/about" v-if="!isAuthenticated || !isOrganization">
                <v-icon color="white">mdi-information-variant</v-icon>
                <span>About</span>
            </router-link>
            <router-link to="/contact" v-if="!isAuthenticated || !isOrganization">
                <v-icon color="white">mdi-phone</v-icon>
                <span>Contact</span>
            </router-link>
            <router-link to="/faq" v-if="!isAuthenticated || !isOrganization">
                <v-icon color="white">mdi-frequently-asked-questions</v-icon>
                <span>FAQs</span>
            </router-link>
            <router-link to="/account/login" v-if="!isAuthenticated">
                <v-icon color="white">mdi-login</v-icon>
                <span>Login</span>
            </router-link>
            <a @click="signOutUser()" v-if="isAuthenticated">
                <v-icon color="white">mdi-logout</v-icon>
                <span>Logout</span>
            </a>
            <router-link to="/account" v-if="isAuthenticated">
                <v-icon color="white">mdi-account</v-icon>
                <span>Account</span>
            </router-link>
            <router-link to="/account/register" v-if="!isAuthenticated">
                <v-icon color="white">mdi-pencil</v-icon>
                <span>Register</span>
            </router-link>
        </Slide>
    </v-sheet>
</template>

<script>
import { Slide } from 'vue-burger-menu';
import recentActions from '@/components/recent-actions/index';
import messagesInbox from '@/components/messages-inbox/index';
import globalSearch from '@/components/global-search';
import organizationBranchPicker from '@/components/organization-branch-picker';

export default {
    name: 'app-bar-navigation',
    components: {
        Slide,
        recentActions,
        messagesInbox,
        globalSearch,
        organizationBranchPicker,
    },
    computed: {
        user() {
            return this.$store.state.user.user;
        },
        isAuthenticated() {
            if (this.user) {
                return true;
            }
            return false;
        },
        isOrganization() {
            return this.$store.getters['user/isOrganization'];
        },
        organization() {
            return this.$store.getters['user/getOrganization'];
        },
        organizationBranch() {
            return this.organization.organizationBranches[0];
        },
        isOrganizationAdmin() {
            return this.$store.getters['user/isOrganizationAdmin'];
        },
    },
    methods: {
        onItemClick(event, item) {
            switch (item.action) {
                case 'Logout':
                    this.signOutUser();
                    break;
            }
        },
        signOutUser() {
            this.$store.dispatch('user/removeUser');
            this.$store.dispatch('quoteRequestCreate/resetQuoteRequest');
            this.$store.dispatch('notifications/clearNotifications');
            window.location = '/';
        },
    },
};
</script>

<style>
@import "../assets/scss/components/sidebar-menu.scss";

.top-menu-link {
  margin-top: 22px;
  margin-bottom: 22px;
}

.top-menu-link.logged-in-menu {
  margin-top: 13px;
  margin-bottom: auto;
}

.logged-in-menu {
  border: 1px solid #575757;
  border-radius: 25px;
  background-color: #fff;
}

.smc-quote-menu {
  background: transparent;
  padding: 15px;
  box-shadow: none;
}

.smc-quote-menu.v-menu__content {
  box-shadow: none;
}

.smc-quote-menu .smc-quote-menu-wrapper {
  background-color: #ffffff;
  padding: 20px 40px;
  width: 400px;
  box-shadow: 5px 0px 10px 5px rgba(0, 0, 0, 0.3),
    0px 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.smc-quote-menu .smc-quote-menu-wrapper:after {
  content: " ";
  position: absolute;
  right: 207px;
  top: 0;
  border-top: none;
  border-right: 14px solid transparent;
  border-left: 14px solid transparent;
  border-bottom: 15px solid #ffffff;
}
</style>
