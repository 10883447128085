import api from './api';
import { formFunctions } from '@/helpers';

const getUser = async () => {
    try {
        let result = await api.get('/api/user/GetApplicationUser');
        return result;
    }
    catch (error) {
        return error;
    }
};

const upsertUser = async user => {
    try {
        let result = await api.post('/api/User/PostApplicationUser', user);
        return result;
    }
    catch (error) {
        return error;
    }
};

const getVehicles = async payload => {
    try {
        let result = await api.post('/api/user/getVehicles', payload);
        if (result && result.data && result.data.items && result.data.items.length > 0) {
            result.data.items.forEach(v => {
                if (v.vehicle.galPic === null) {
                    v.vehicle.imageUrl = require('@/assets/images/no-entry-for-motor-vehicle.png');
                }
            });
        }
        return result;
    }
    catch (error) {
        return error;
    }
};

const upsertVehicle = async (vehicleMapping, vehicleReportAttachment) => {
    try {
        let formData = formFunctions.toFormData(vehicleMapping);

        if (vehicleReportAttachment) {
            formData.append('vehicleReportAttachment', vehicleReportAttachment);
        }

        let result = await api.post(
            '/api/user/upsertVehicle',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
        return result;
    }
    catch (error) {
        return error;
    }
};

const deleteVehicleMileage = async vehicleMappingMileageId => {
    try {
        let result = await api.delete(`/api/user/deleteVehicleMileage/${vehicleMappingMileageId}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const queryVehicleServiceSchedule = async vehicleMappingId => {
    try {
        let result = await api.get(`/api/user/queryVehicleServiceSchedule/${vehicleMappingId}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const queryUserRecentActions = async (page, searchTerm) => {
    try {
        let result = await api.get(`/api/user/queryUserRecentActions/${page}?searchTerm=${searchTerm}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const queryUserRecentMessages = async (page, isOpen, isSent) => {
    try {
        let result = await api.get(`/api/user/queryUserRecentMessages/${page}?isOpen=${isOpen}&isSent=${isSent}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const setMessageRead = async payload => {
    try {
        let result = await api.post('/api/user/setMessageRead', payload);
        return result;
    }
    catch (error) {
        return error;
    }
};

const loadNotifications = async () => {
    let notifications = [];
    try {
        let result = await api.get('/api/user/loadNotifications');
        result.data.forEach(notification => {
            notifications.push(mapNotification(notification));
        });
        return notifications;
    }
    catch (error) {
        return error;
    }
};

const upsertUserOrganizationBranch = async payload => {
    try {
        let result = await api.post('/api/user/upsertOrganizationBranch', payload);
        return result;
    }
    catch (error) {
        return error;
    }
};

const queryVehicleHistory = async payload => {
    try {
        let result = await api.post('/api/user/queryVehicleHistory', payload);
        return result;
    }
    catch (error) {
        return error;
    }
};

const loadUserOrganization = async () => {
    try {
        let result = await api.get('/api/user/loadUserOrganization');
        return result;
    }
    catch (error) {
        return error;
    }
};

const mapNotification = notification => {
    return {
        ...notification,
        isRead: false,
    };
};

export const userService = {
    getUser,
    upsertUser,
    getVehicles,
    upsertVehicle,
    deleteVehicleMileage,
    queryVehicleServiceSchedule,
    queryUserRecentActions,
    queryUserRecentMessages,
    setMessageRead,
    loadNotifications,
    upsertUserOrganizationBranch,
    queryVehicleHistory,
    loadUserOrganization,
};
