<template>
    <div>
        <div
            class="home container"
            :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''"
        >
            <!--<background-parallax />-->
            <!--<particles />-->
            <background-video ref="backgroundVideo" />
            <div class="mt-12 pt-12" style="position: relative; min-height: 615px">
                <vehicle-search 
                    @vehicleSelected="handleVehicleSelected"
                    @searchCleared="handleSSearchCleared"  
                />
            </div>

            <!--<div style="height: 300px"></div>-->
        </div>

        <in-the-media />

        <div class="mx-3 pa-3"></div>

        <google-reviews />

        <div class="mx-4 pa-4"></div>

        <what-we-do />

        <div class="mx-6 pa-6"></div>

        <!-- <naked-insurance-banner v-bind:analyticsLabelProp="'banner'" /> -->

        <!--
    <div class="mx-12 pa-12" />
    <how-it-works-video />
    -->

        <div class="mx-12 pa-12"></div>

        <workshops-slider />
    </div>
</template>

<script>
// @ is an alias to /src
import vehicleSearch from '@/components/vehicle-search';
import workshopsSlider from '@/components/widgets/workshops-slider';
import whatWeDo from '@/components/widgets/what-we-do';
//import howItWorksVideo from "@/components/widgets/how-it-works-video";
import inTheMedia from '@/components/widgets/in-the-media';
import googleReviews from '@/components/widgets/google-reviews';
//import backgroundParallax from "@/components/widgets/background-parallax";
//import particles from "@/components/widgets/particles";
import backgroundVideo from '@/components/widgets/background-video';
//import nakedInsuranceBanner from "@/components/widgets/naked-insurance-banner";

export default {
    name: 'home',
    components: {
        vehicleSearch,
        workshopsSlider,
        whatWeDo,
        //howItWorksVideo,
        inTheMedia,
        googleReviews,
        //backgroundParallax,
        //particles,
        backgroundVideo,
    //nakedInsuranceBanner,
    },
    //created() {
    //  if (this.$vuetify.breakpoint.mdAndUp) {
    //    var isCookie = this.$cookies.isKey("contact_cookie");
    //    if (isCookie !== true) {
    //      setTimeout(() => {
    //        this.$cookies.set("contact_cookie", true, "1d");
    //        this.$store.dispatch("contactSpeedDial/setShow", true);
    //      }, 5000);
    //    }
    //  }
    //},
    methods: {
        handleVehicleSelected() {
            this.$refs.backgroundVideo.pauseVideo();
        },
        handleSSearchCleared() {
            this.$refs.backgroundVideo.playVideo();
        },
    },
};
</script>

<style>
main.v-main {
  background-color: #fff;
}
</style>
