<template>
    <v-dialog v-model="pdfShow" width="1200" content-class="pdf-modal">
        <v-toolbar color="primary" dark height="48">
            {{ pdfFilenameProp }}
        </v-toolbar>
        <template #activator="{ on, attrs }">
            <v-btn
                small
                rounded
                class="mb-1"
                v-bind="attrs"
                v-on="on"
                color="#008b4e"
            >
                <v-img
                    :src="require('@/assets/images/dekra_logo.jpg')"
                    :lazy-src="require('@/assets/images/dekra_logo.jpg')"
                    max-height="22px"
                    max-width="100px"
                    contain
                />
            </v-btn>
        </template>
        <v-sheet class="top-container py-3">
            <v-row>
                <v-col class="text-left pl-6">
                    <span v-if="pdfAllPages"> {{ pdfPageCount }} page(s) </span>
                    <span v-else>
                        <v-btn
                            color="dark"
                            icon
                            small
                            outlined
                            :disabled="pdfPage <= 1"
                            @click="pdfPage--"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        {{ pdfPage }} / {{ pdfPageCount }}
                        <v-btn
                            color="dark"
                            icon
                            small
                            outlined
                            :disabled="pdfPage >= pdfPageCount"
                            @click="pdfPage++"
                        >
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </span>
                </v-col>
                <v-spacer />
                <v-col class="pr-6 shrink" style="min-width: 180px">
                    <v-checkbox
                        v-model="pdfAllPages"
                        hide-details
                        class="mt-0 ml-auto"
                        label="Show all pages"
                    />
                </v-col>
            </v-row>
        </v-sheet>
        <v-card class="pdf-container" :loading="pdfLoading">
            <v-skeleton-loader
                v-if="pdfLoading"
                class="mx-12"
                type="card-avatar, article, article, actions"
            />
            <vue-pdf-embed
                :source="pdfUrlProp"
                :textLayer="true"
                :page="currentPdfPage"
                @loaded="handleDocumentLoad"
            />
            <v-divider />
        </v-card>
        <v-sheet class="button-container py-3">
            <v-btn color="primary" rounded class="px-4" @click="pdfShow = false">
                Close
            </v-btn>
        </v-sheet>
    </v-dialog>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
    name: 'pdf-viewer',
    components: {
        VuePdfEmbed,
    },
    props: {
        pdfUrlProp: String,
        pdfFilenameProp: String,
        pdfAllPagesProp: Boolean,
    },
    data: () => ({
        isInitialized: false,
        pdfLoading: true,
        pdfShow: false,
        pdfPage: 1,
        pdfAllPages: false,
        pdfPageCount: undefined,
    }),
    computed: {
        currentPdfPage() {
            if (this.pdfAllPages) {
                return undefined;
            }
            return this.pdfPage;
        },
    },
    created() {
        this.pdfAllPages = this.pdfAllPagesProp === true;
    },
    methods: {
        handleDocumentLoad({ numPages }) {
            this.pdfPageCount = numPages;
            this.pdfLoading = false;
            this.isInitialized = false;

            if (this.pdfPage > numPages) {
                this.pdfPage = 1;
            }
        },
        getIsInitialized() {
            return this.isInitialized;
        },
        openPage(pageNum) {
            if (pageNum <= this.pdfPageCount) {
                this.pdfPage = pageNum;
                this.pdfAllPages = false;
            }
            this.pdfShow = true;
        },
    },
};
</script>

<style lang="scss">
.pdf-container {
  margin: 0;
  padding: 0;
  background-color: #bdbdbd !important;
}

.pdf-modal {
  position: relative;
}

.pdf-modal .top-container {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  border: 1px solid #bdbdbd;
}

.pdf-modal .button-container {
  background-color: #fff;
  position: sticky;
  bottom: 0;
  border: 1px solid #bdbdbd;
}

.vue-pdf-embed > div {
  margin-bottom: 22px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.3);
}
</style>
