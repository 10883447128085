<template>
    <v-card flat :loading="loading">
        <v-card
            rounded
            elevation="2"
            outlined
            class="ma-4"
            v-for="(item, index) in messages"
            :key="index"
            @click="openAction(item)"
            :loading="item.loading"
        >
            <v-card-title>
                <v-chip-group column>
                    <v-chip v-if="item.actionDescription" :color="themeColorProp" class="white--text">
                        {{ item.actionDescription }}
                    </v-chip>
                    <v-chip v-if="item.vehicleName" outlined :color="themeColorProp">
                        {{ item.vehicleName }}
                    </v-chip>
                    <v-chip outlined color="secondary">
                        {{ dateHelper.formatDateLongHours(item.actionDate) }}
                    </v-chip>
                </v-chip-group>
            </v-card-title>
            <v-card-text class="text-left">
                <v-row>
                    <v-col class="mt-n2">
                        <v-simple-table fixed-header>
                            <template #default>
                                <thead>
                                    <tr>
                                        <th>{{ item.workshopName }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="py-2">{{ item.actionNote }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col class="shrink pr-1">
                        <img
                            class="smc-search-image mr-0"
                            :src="item.vehicleImageUrl"
                            style="width: 180px; margin-right: 15px; border-radius: 15px;"
                        />
                        <p>
                            {{ item.vehicleSummary }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-row>
                    <v-col class="text-right">
                        <v-btn class="mr-2" color="primary">Open</v-btn>
                        <v-btn @click.stop="markRead(item)" v-if="!isOpenProp && !isSentProp" color="secondary" outlined :loading="item.loading">
                            Mark as Read
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card
            rounded
            elevation="2"
            outlined
            class="ma-4"
            flat
            v-if="!loading && messages.length === 0"
        >
            <v-card-text>
                <p>No messages.</p>
            </v-card-text>
        </v-card>
        <scroll-pagination
            ref="recentActionsScrollPagination"
            :loader-method-prop="loadMoreMessages"
            :loader-disable-prop="scrollDisabled"
        />
    </v-card>
</template>

<script>
import scrollPagination from '@/components/scroll-pagination';
import { dateHelper } from '@/helpers';
import { userService } from '@/services';

export default {
    name: 'messages-list',
    components: {
        scrollPagination,
    },
    props: {
        isOpenProp: Boolean,
        isSentProp: Boolean,
        themeColorProp: String,
    },
    data: () => ({
        dateHelper,

        loading: false,
        page: 1,
        messages: [],
        scrollDisabled: false,
    }),
    methods: {
        async loadMoreMessages() {
            if (!this.loading) {
                this.loading = true;
                let result = await userService.queryUserRecentMessages(
                    this.page,
                    this.isOpenProp,
                    this.isSentProp,
                );
                if (result.data && result.data.length > 0) {
                    const messagesWithLoading = result.data.map(message => ({
                        ...message,
                        loading: false,
                    }));
                    this.messages.push(...messagesWithLoading);
                    
                    if (result.data.length < 15) {
                        this.scrollDisabled = true;
                    }
                }
                else {
                    this.scrollDisabled = true;
                }
                this.loading = false;
                this.page++;
                this.$emit('messagesLoaded', {
                    count: this.messages.length,
                    scrollDisabled: this.scrollDisabled,
                });
            }
        },
        resetMessages() {
            this.messages = [];
            this.scrollDisabled = false;
            this.page = 1;
        },
        openAction(item) {
            this.$emit('openAction', item);
        },
        async markRead(message) {
            message.loading = true;
            let result = await userService.setMessageRead(message);
            if (result.status === 200) {
                this.resetMessages();
                await this.loadMoreMessages();
            }
            this.$emit('resetMessages');
        },
    },
};
</script>
