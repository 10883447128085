import api from './api';
import RepairItem from '@/models/repair-item';

const getRepairItems = async quoteType => {
    let repairItems = [];

    try {
        let result = await api.get(`/api/repairItems?quoteType=${quoteType}`);
        for (let repairItem of result.data) {
            repairItems.push(
                new RepairItem(
                    repairItem.id,
                    repairItem.name,
                    repairItem.displayName,
                    repairItem.query,
                    repairItem.queryAnswer,
                    repairItem.icon,
                    repairItem.hasQuery,
                    repairItem.trunk,
                    repairItem.leaf,
                    repairItem.children,
                    repairItem.quoteRequestItemTemplateItems,
                    repairItem.baseQuoteRequestItemTemplateItems,
                ),
            );
        }

        return repairItems;
    }
    catch {
        return repairItems;
    }
};

export const repairItemService = { getRepairItems };
