import _ from 'lodash';
import api from './api';
import { formFunctions, userHelper } from '@/helpers';
import QuoteRequest from '@/models/quote-request';
import QuoteRequestItem from '@/models/quote-request-item';
import User from '@/models/user';
import Vehicle from '@/models/vehicle';
import Address from '@/models/address';
import Quote from '@/models/quote';
import QuoteItem from '@/models/quote-item';
import Workshop from '@/models/workshop';
import DistanceMatrix from '@/models/distance-matrix';
import WorkshopReview from '@/models/workshop-review';
import QuoteRequestEstimate from '@/models/quote-request-estimate';
import QuoteRequestOrganizationBranchShare from '@/models/quote-request-organization-branch-share';

const createQuoteRequest = async quoteRequest => {
    try {
        let formData = formFunctions.toFormData(quoteRequest);

        if (quoteRequest.attachments && quoteRequest.attachments.length > 0) {
            quoteRequest.attachments.forEach(att => {
                formData.append('attachmentFiles', att.file);
            });
        }

        let result = await api.post(
            '/api/QuoteRequests/PostQuoteRequest',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );

        return result;
    }
    catch (error) {
        return error;
    }
};

const getQuoteRequests = async vehicleId => {
    try {
        let quoteRequests = [];
        let vehicleMapping = {};
        let result = await api.get(`/api/QuoteRequests/GetQuoteRequests?vehicleId=${vehicleId}`);

        _.forEach(result.data.quoteRequests, function (item) {
            let qr = mapQuoteRequestList(item);
            quoteRequests.push(qr);
        });
        vehicleMapping = result.data.customerVehicleMapping;

        return {
            vehicleMapping,
            quoteRequests,
        };
    }
    catch (error) {
        return error;
    }
};

const getQuoteRequest = async (uniqueId, email) => {
    try {
        let quoteRequest = {};

        let result = await api.get(`/api/QuoteRequests/GetQuoteRequest/${uniqueId}?emailAddress=${email}`);
        quoteRequest = mapQuoteRequest(result.data);
        return quoteRequest;
    }
    catch (error) {
        return error;
    }
};

const getCustomersCount = async () => {
    try {
        let result = await api.get('/api/QuoteRequests/GetCustomersCount');
        return result;
    }
    catch (error) {
        return error;
    }
};

const getQuoteRequestEstimate = async quoteRequest => {
    try {
        let estimates = [];

        let result = await api.post(
            '/api/QuoteRequests/GetQuoteEstimate',
            quoteRequest,
        );

        //if (result.status === 200) {
        if (result.data) {
            result.data.forEach(estimate => {
                estimates.push(mapEstimate(estimate));
            });
        }

        return {
            status: 200,
            estimates,
        };
    }
    catch (error) {
        return error;
    }
};

const queryActiveRequests = async (stage, payload) => {
    try {
        let requests = [];
        let result = await api.post(`/api/QuoteRequests/ActiveRequests/${stage}`, payload);

        _.forEach(result.data.items, function (item) {
            let qr = mapQuoteRequestList(item);
            requests.push(qr);
        });

        return {
            items: requests,
            totalDisplayItems: result.data.totalDisplayItems,
        };
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

const queryActiveRequestsWorkshops = async stage => {
    try {
        let result = await api.get(`/api/QuoteRequests/ActiveRequestsWorkshops/${stage}`);
        return result;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

const completeQuoteRequest = async quoteRequestId => {
    try {
        let result = await api.post(`/api/QuoteRequests/completeQuoteRequest/${quoteRequestId}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const mapQuoteRequestList = item => {
    return new QuoteRequest(
        item.id,
        item.uniqueId,
        item.userId,
        item.vehicleMileage,
        '', // existingVehicleMileage
        item.vehicleVinNumber,
        item.vehicleEngineNumber,
        item.vehicleLicensePlate,
        item.vehicleMappingId,
        item.vehicleId,
        item.vehicleName,
        item.dateCreated,
        item.dateCreatedUtc,
        item.datesRequested,
        item.quoteRequestStatus,
        item.quoteRequestStatusUsername,
        item.notes,
        item.reference,
        null, // license disk photo
        item.licenseDiskPhotoUrl,
        new User(
            item.applicationUser.id,
            item.applicationUser.firstName,
            item.applicationUser.lastName,
            item.applicationUser.email,
            item.applicationUser.phone,
            _.startsWith(item.applicationUser.phone, '+27') ? userHelper.removePhonePrefix(item.applicationUser.phone) : item.applicationUser.phone,
            item.applicationUser.businessName,
            item.applicationUser.businessRegistrationNumber,
            item.applicationUser.businessTaxNumber,
            item.applicationUser.businessAddress 
                ? new Address(
                    item.applicationUser.businessAddress.id,
                    item.applicationUser.businessAddress.name,
                    item.applicationUser.businessAddress.isActive,
                    item.applicationUser.businessAddress.streetNumber,
                    item.applicationUser.businessAddress.streetName,
                    item.applicationUser.businessAddress.suburb,
                    item.applicationUser.businessAddress.city,
                    item.applicationUser.businessAddress.province,
                    item.applicationUser.businessAddress.country,
                    item.applicationUser.businessAddress.postalCode,
                    item.applicationUser.businessAddress.lat,
                    item.applicationUser.businessAddress.lng,
                    item.applicationUser.businessAddress.dateCreated,
                    false, // isOrganization
                ) 
                : {},
            {}, // organization
        ),
        new Vehicle(
            item.vehicle.id,
            item.vehicle.brand,
            item.vehicle.brandCode,
            item.vehicle.range,
            item.vehicle.rangeCode,
            item.vehicle.model,
            item.vehicle.modelCode,
            item.vehicle.year,
            item.vehicle.introductionDate,
            item.vehicle.discontinuedDate,
            item.vehicle.galPic,
            item.vehicle.galPic !== null ? item.vehicle.imageUrl : require('@/assets/images/no-entry-for-motor-vehicle.png'),
            item.vehicle.diskDriveReverence,
            item.vehicle.classType,
            item.vehicle.description,
            item.vehicle.capacity,
            item.vehicle.transmissionType,
            item.vehicle.fuelType,
            item.vehicle.driveType,
            item.vehicle.vehicleSummary,
        ),
        item.address
            ? new Address(
                item.address.id,
                item.address.name,
                item.address.isActive,
                item.address.streetNumber,
                item.address.streetName,
                item.address.suburb,
                item.address.city,
                item.address.province,
                item.address.country,
                item.address.postalCode,
                item.address.lat,
                item.address.lng,
                item.address.dateCreated,
                false, // isOrganization
            ) 
            : undefined,
        item.travelDistance,
        item.regionIds,
        item.partsPreference,
        null, // quote request items
        _.map(item.quotes, function (quote) {
            return new Quote(
                quote.id,
                quote.uniqueId,
                quote.quoteRequestId,
                quote.workshopId,
                quote.reference,
                quote.externalReference,
                quote.invoiceNumber,
                quote.quoteMessages,
                quote.quoteStatus,
                quote.dateCreatedUtc,
                quote.dateCreated,
                quote.dateModifiedUtc,
                quote.dateModified,
                quote.expiryDateUtc,
                quote.expiryDate,
                quote.serviceDateUtc,
                quote.serviceDate,
                null, // quote items
                null, // quote request items
                quote.totalIncl ? quote.totalIncl : quote.verifiedTotalIncl, // quote total
                quote.workshop, // workshops
                quote.quoteStatuses,
                quote.isExpired,
                quote.isVatRegistered,
                quote.vehicleMileage,
                quote.workshopCustomer,
                quote.organizationOrderAttached,
                quote.jobTurnaround,
            );
        }),
        item.isExpired,
        false, // service reminder
        item.isPolicyQuote,
        item.quoteType,
        item.hidePrice,
        item.isForBusiness,
        [], // attachments
        item.quoteCustomerReferences,
        item.quoteRequestWorkshopMappings,
        item.quoteRequestOrganizationBranchShare !== null 
            ? new QuoteRequestOrganizationBranchShare(
                item.quoteRequestOrganizationBranchShare.quoteId,
                item.quoteRequestOrganizationBranchShare.workshopId,
                item.quoteRequestOrganizationBranchShare.workshopName,
                item.quoteRequestOrganizationBranchShare.workshopLogoUrl,
                item.quoteRequestOrganizationBranchShare.dateCreatedUtc,
                item.quoteRequestOrganizationBranchShare.dateCreated,
                item.quoteRequestOrganizationBranchShare.username,
                item.quoteRequestOrganizationBranchShare.isSuccess,
            )
            : null,
    );
};

const shareOrganizationBranchQuote = async (quoteRequestId, quoteId) => {
    try {
        let result = await api.post(`/api/QuoteRequests/shareOrganizationBranchQuote/${quoteRequestId}?quoteId=${quoteId}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const queryQuotesPriceLookup = async payload => {
    try {
        let result = await api.post('/api/QuoteRequests/queryQuotesPriceLookup', payload);
        return result.data;
    }
    catch (error) {
        return error;
    }
};

const mapQuoteRequest = item => {
    return new QuoteRequest(
        item.id,
        item.uniqueId,
        item.userId,
        item.vehicleMileage,
        '', // existingVehicleMileage
        item.vehicleVinNumber,
        item.vehicleEngineNumber,
        item.vehicleLicensePlate,
        item.vehicleMappingId,
        item.vehicleId,
        item.vehicleName,
        item.dateCreated,
        item.dateCreatedUtc,
        item.datesRequested,
        item.quoteRequestStatus,
        item.quoteRequestStatusUsername,
        item.notes,
        item.reference,
        null, // license disk photo
        item.licenseDiskPhotoUrl,
        new User(
            item.applicationUser.id,
            item.applicationUser.firstName,
            item.applicationUser.lastName,
            item.applicationUser.email,
            item.applicationUser.phone,
            _.startsWith(item.applicationUser.phone, '+27') ? userHelper.removePhonePrefix(item.applicationUser.phone) : item.applicationUser.phone,
            item.applicationUser.businessName,
            item.applicationUser.businessRegistrationNumber,
            item.applicationUser.businessTaxNumber,
            item.applicationUser.businessAddress 
                ? new Address(
                    item.applicationUser.businessAddress.id,
                    item.applicationUser.businessAddress.name,
                    item.applicationUser.businessAddress.isActive,
                    item.applicationUser.businessAddress.streetNumber,
                    item.applicationUser.businessAddress.streetName,
                    item.applicationUser.businessAddress.suburb,
                    item.applicationUser.businessAddress.city,
                    item.applicationUser.businessAddress.province,
                    item.applicationUser.businessAddress.country,
                    item.applicationUser.businessAddress.postalCode,
                    item.applicationUser.businessAddress.lat,
                    item.applicationUser.businessAddress.lng,
                    item.applicationUser.businessAddress.dateCreated,
                    false, // isOrganization
                ) 
                : {},
            {}, // organization
        ),
        new Vehicle(
            item.vehicle.id,
            item.vehicle.brand,
            item.vehicle.brandCode,
            item.vehicle.range,
            item.vehicle.rangeCode,
            item.vehicle.model,
            item.vehicle.modelCode,
            item.vehicle.year,
            item.vehicle.introductionDate,
            item.vehicle.discontinuedDate,
            item.vehicle.galPic,
            item.vehicle.galPic !== null ? item.vehicle.imageUrl : require('@/assets/images/no-entry-for-motor-vehicle.png'),
            item.vehicle.diskDriveReverence,
            item.vehicle.classType,
            item.vehicle.description,
            item.vehicle.capacity,
            item.vehicle.transmissionType,
            item.vehicle.fuelType,
            item.vehicle.driveType,
            item.vehicle.vehicleSummary,
        ),
        new Address(
            item.address.id,
            item.address.name,
            item.address.isActive,
            item.address.streetNumber,
            item.address.streetName,
            item.address.suburb,
            item.address.city,
            item.address.province,
            item.address.country,
            item.address.postalCode,
            item.address.lat,
            item.address.lng,
            item.address.dateCreated,
            false, // isOrganization
        ),
        item.travelDistance,
        item.regionIds,
        item.partsPreference,
        _.map(item.items, function (value) {
            return new QuoteRequestItem(
                value.id,
                value.name,
                value.hasQuery,
                value.query,
                value.queryAnswer,
                null, // quoteItems
                null, // labourItem
                value.quoteRequestItemNotes,
                false, // amendmentAccepted
                value.keyword,
            );
        }),
        _.map(item.quotes, function (quote) {
            return new Quote(
                quote.id,
                quote.uniqueId,
                quote.quoteRequestId,
                quote.workshopId,
                quote.reference,
                quote.externalReference,
                quote.invoiceNumber,
                quote.quoteMessages,
                quote.quoteStatus,
                quote.dateCreatedUtc,
                quote.dateCreated,
                quote.dateModifiedUtc,
                quote.dateModifiedUtc,
                quote.expiryDateUtc,
                quote.expiryDate,
                quote.serviceDateUtc,
                quote.serviceDate,
                _.map(quote.quoteItems, function (item) {
                    return new QuoteItem(
                        item.id,
                        item.description,
                        item.note,
                        item.priceExcl,
                        item.priceIncl,
                        item.previousPriceIncl,
                        item.quantity,
                        item.previousQuantity,
                        item.totalIncl,
                        item.verifiedTotalIncl,
                        item.quoteRequestItemId,
                        _.get(item, 'quoteRequestItem.name'),
                        _.get(item, 'quoteRequestItem.query'),
                        _.get(item, 'quoteRequestItem.queryAnswer'),
                        item.amendmentType,
                        item.amendmentStatus,
                    );
                }),
                _.map(quote.quoteRequestItems, function (value) {
                    return new QuoteRequestItem(
                        value.id,
                        value.name,
                        value.hasQuery,
                        value.query,
                        value.queryAnswer,
                        _.map(value.quoteItems, function (qi) {
                            return new QuoteItem(
                                qi.id,
                                qi.description,
                                qi.note,
                                qi.priceExcl,
                                qi.priceIncl,
                                qi.previousPriceIncl,
                                qi.quantity,
                                qi.previousQuantity,
                                qi.totalIncl,
                                qi.verifiedTotalIncl,
                                qi.quoteRequestItemId,
                                '',
                                '',
                                '',
                                qi.amendmentType,
                                qi.amendmentStatus,
                            );
                        }),
                        value.labourItem
                            ? new QuoteItem(
                                value.labourItem.id,
                                value.labourItem.description,
                                value.labourItem.note,
                                value.labourItem.priceExcl,
                                value.labourItem.priceIncl,
                                value.labourItem.previousPriceIncl,
                                value.labourItem.quantity,
                                value.labourItem.previousQuantity,
                                value.labourItem.totalIncl,
                                value.labourItem.verifiedTotalIncl,
                                value.labourItem.quoteRequestItemId,
                                '',
                                '',
                                '',
                                value.labourItem.amendmentType,
                                value.labourItem.amendmentStatus,
                            )
                            : null,
                        value.quoteRequestItemNotes,
                        false, // amendmentAccepted
                        value.keyword,
                    );
                }),
                quote.totalIncl ? quote.totalIncl : quote.verifiedTotalIncl,
                new Workshop(
                    quote.workshop.id,
                    quote.workshop.name,
                    quote.workshop.isPosh,
                    quote.workshop.rating,
                    quote.workshop.taxNumber,
                    quote.workshop.registrationNumber,
                    new Address(
                        quote.workshop.address.id,
                        quote.workshop.address.name,
                        quote.workshop.address.isActive,
                        quote.workshop.address.streetNumber,
                        quote.workshop.address.streetName,
                        quote.workshop.address.suburb,
                        quote.workshop.address.city,
                        quote.workshop.address.province,
                        quote.workshop.address.country,
                        quote.workshop.address.postalCode,
                        quote.workshop.address.lat,
                        quote.workshop.address.lng,
                        quote.workshop.address.dateCreated,
                        false, // isOrganization
                    ),
                    quote.workshop.logoUrl,
                    quote.workshop.hasPayfastAccount,
                    quote.workshop.paystackSubaccountId,
                    quote.workshop.dateCreated,
                    quote.workshop.dateCreatedUtc,
                    new DistanceMatrix(
                        _.get(quote.workshop, 'quoteRequestWorkshopMappings[0].distance'),
                        _.get(quote.workshop, 'quoteRequestWorkshopMappings[0].distanceText'),
                        _.get(quote.workshop, 'quoteRequestWorkshopMappings[0].duration'),
                        _.get(quote.workshop, 'quoteRequestWorkshopMappings[0].durationText'),
                        _.get(quote.workshop, 'quoteRequestWorkshopMappings[0].durationInTraffic'),
                        _.get(quote.workshop, 'quoteRequestWorkshopMappings[0].durationInTrafficText'),
                    ),
                    _.map(quote.workshop.workshopReviews, function (item) {
                        return new WorkshopReview(
                            item.id,
                            item.workshopId,
                            item.rating,
                            item.review,
                        );
                    }),
                    quote.workshop.isVatRegistered,
                    quote.workshop.workshopContact,
                    quote.workshop.workshopCertifications,
                    quote.workshop.bankAccount,
                ),
                quote.quoteStatuses,
                quote.isExpired,
                quote.isVatRegistered,
                quote.vehicleMileage,
                quote.workshopCustomer,
                quote.organizationOrderAttached,
                quote.jobTurnaround,
            );
        }),
        item.isExpired,
        false, // service reminder
        item.isPolicyQuote,
        item.quoteType,
        item.hidePrice,
        item.isForBusiness,
        [], // attachments
        item.quoteCustomerReferences,
        item.quoteRequestWorkshopMappings,
        item.quoteRequestOrganizationBranchShare !== null && item.quoteRequestOrganizationBranchShare.quoteId !== 0 
            ? new QuoteRequestOrganizationBranchShare(
                item.quoteRequestOrganizationBranchShare.quoteId,
                item.quoteRequestOrganizationBranchShare.workshopId,
                item.quoteRequestOrganizationBranchShare.workshopName,
                item.quoteRequestOrganizationBranchShare.workshopLogoUrl,
                item.quoteRequestOrganizationBranchShare.dateCreatedUtc,
                item.quoteRequestOrganizationBranchShare.dateCreated,
                item.quoteRequestOrganizationBranchShare.username,
                item.quoteRequestOrganizationBranchShare.isSuccess,
            )
            : null,
    );
};

const mapEstimate = item => {
    return new QuoteRequestEstimate(
        item.quoteRequestItemName,
        item.estimateFromPriceIncl,
        item.estimateToPriceIncl,
        item.estimateAveragePriceIncl);
};

export const quoteRequestService = {
    createQuoteRequest,
    getQuoteRequests,
    getQuoteRequest,
    getCustomersCount,
    getQuoteRequestEstimate,
    queryActiveRequests,
    queryActiveRequestsWorkshops,
    completeQuoteRequest,
    shareOrganizationBranchQuote,
    queryQuotesPriceLookup,
};
